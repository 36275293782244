import { IJSONSchema, IPathMeta, Schemas } from '@cp/base-types';
import classNames from 'classnames';
import { getPathTitle } from '@cpa/base-core/helpers';
import { DynamicDataUrlFunction } from '@cpa/base-core/types';
import { IFilter, getFilterValuesMap, getSchemaPathsMeta } from '@cp/base-utils';
import * as _ from 'lodash';
import React, { useCallback, useMemo } from 'react';

import SearchSuggestions from '../SearchSuggestions/SearchSuggestions';
import { SuggestionValue } from '../FilterLayer/utils';

import styles from './PromotedFilters.module.scss';

interface IFilterProps {
  schema: IJSONSchema;
  promotedFilters: Schemas.CpaPage['promotedFilters'];
  dataUrl: string | DynamicDataUrlFunction;
  parsedFilter: IFilter;
  isSticky?: boolean;
  onChange?: (propertyJsonPath: string, selectedValues: SuggestionValue[]) => unknown;
}

const PromotedFilters: React.FC<IFilterProps> = ({ schema, promotedFilters, parsedFilter, dataUrl, isSticky, onChange }) => {
  const pathToNameMap: Record<string, string> = useMemo(() => {
    return (
      promotedFilters?.reduce((acc, { propertyJsonPath, name }) => {
        return {
          ...acc,
          [propertyJsonPath]: name,
        };
      }, {}) || {}
    );
  }, [promotedFilters]);

  const matchedPaths: IPathMeta[] = useMemo(
    () => getSchemaPathsMeta(schema, promotedFilters?.map(({ propertyJsonPath }) => propertyJsonPath) || []),
    [promotedFilters, schema]
  );

  const valuesMap = useMemo(() => getFilterValuesMap(parsedFilter), [parsedFilter]);

  const handleFilterChange = useCallback(
    (path: IPathMeta) =>
      (newValues: [string, string][]): void => {
        onChange?.(
          path.propertyJsonPath,
          newValues.map(([value]) => value)
        );
      },
    [onChange]
  );

  if (!matchedPaths.length) {
    return null;
  }

  return (
    <>
      {matchedPaths.map((matchedPath) => (
        <div
          key={matchedPath.propertyJsonPath}
          className={classNames(styles.promotedFilter, {
            [styles.active]: !!valuesMap[matchedPath.propertyJsonPath]?.length,
          })}
        >
          <SearchSuggestions
            value={valuesMap[matchedPath.propertyJsonPath]}
            onChange={handleFilterChange(matchedPath)}
            dataUrl={dataUrl}
            placeholder={pathToNameMap[matchedPath.propertyJsonPath] || getPathTitle(matchedPath, false)}
            dataPath={matchedPath.propertyJsonPath}
            pathMetaItems={matchedPath.items}
            enumMapping={matchedPath.enumMapping}
            multiselect={true}
            lowercase={true}
            noBorder={true}
            isSticky={isSticky}
            parsedFilter={parsedFilter}
            disableLocalization={schema.cp_disableLocalization}
          />
        </div>
      ))}
    </>
  );
};

export default PromotedFilters;
