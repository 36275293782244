const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/UserStory',
    'type': 'object',
    'links': [
        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUserStory' },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FVoting?$filter=about%2F_type%20eq%20'{type}'%20and%20about%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUserStory?$filter=predecessors%2Fidentifier%20eq%20'{identifier}'",
        },
        {
            'rel': 'related',
            'href': "data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUserStory?$filter=parentUserStory%2Fidentifier%20eq%20'{identifier}'",
        },
    ],
    'cp_parentPropertyJsonPath': 'parentUserStory.identifier',
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': true,
    'cp_disableVersions': false,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': true,
    'title': 'User Story',
    'properties': {
        'status': {
            'title': 'Status',
            'description': 'The status of a thing',
            'type': 'string',
            'cp_ui': { 'hiddenInForm': true, 'sortOrderForm': 51 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/status', 'rel': 'canonical' }],
        },
        'referringTo': {
            'title': 'Referring To',
            'description': '<div>Either an existing solution or a specific DevOps reference.</div>',
            'anyOf': [
                {
                    '$id': 'http://platform.cosmoconsult.com/ontology/Solution',
                    'type': 'object',
                    'links': [
                        {
                            'rel': 'collection',
                            'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FSolution',
                            'endpoint': '923ef7a8-6224-414e-9a74-3bedbed9d546',
                        },
                    ],
                    'cp_parentPropertyJsonPath': 'parentSolution.identifier',
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'title': 'Solution',
                    'properties': {
                        'identifier': { 'type': 'string', 'title': 'Solution' },
                        '_type': {
                            'type': 'string',
                            'const': 'http://platform.cosmoconsult.com/ontology/Solution',
                            'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                        },
                    },
                },
                {
                    '$id': 'http://platform.cosmoconsult.com/ontology/UserStoryDevOpsReference',
                    'type': 'object',
                    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUserStoryDevOpsReference' }],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': true,
                    'cp_disableVersions': true,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'title': 'User Story Dev Ops Reference',
                    'properties': {
                        'identifier': { 'type': 'string', 'title': 'User Story Dev Ops Reference' },
                        '_type': {
                            'type': 'string',
                            'const': 'http://platform.cosmoconsult.com/ontology/UserStoryDevOpsReference',
                            'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                        },
                    },
                },
            ],
            'cp_ui': { 'sortOrderForm': 42 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/referringTo', 'rel': 'canonical' }],
        },
        'area': {
            'title': 'Area',
            'description': '<div>The area this item belongs to. Currently this can be either the core area or the business area.</div>',
            'type': 'string',
            'cp_ui': { 'hiddenInForm': true, 'sortOrderForm': 52 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/area', 'rel': 'canonical' }],
        },
        'predecessors': {
            'title': 'Predecessors',
            'description': '<div>The predecessors that must be processed so that this user story can be processed.</div>\n<div>Note: DevOps does not recognize predecessor/successor relationships, but they are important for understanding dependencies.</div>',
            'type': 'array',
            'items': {
                '$id': 'http://platform.cosmoconsult.com/ontology/UserStory',
                'type': 'object',
                'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUserStory' }],
                'cp_parentPropertyJsonPath': 'parentUserStory.identifier',
                'cp_handledByApiGateway': false,
                'cp_disableTrash': false,
                'cp_disableLocalization': false,
                'cp_disableVersions': false,
                'cp_disableClone': false,
                'cp_disableLazyLoading': false,
                'patternProperties': { '^[_@].*$': {} },
                'additionalProperties': false,
                'title': 'User Story',
                'properties': { 'identifier': { 'type': 'string', 'title': 'User Story' } },
            },
            'cp_ui': { 'sortOrderForm': 47 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/predecessor', 'rel': 'canonical' }],
        },
        'parentUserStory': {
            'title': 'Parent User Story',
            'description': '<div>User stories can be structured, but the structure is only used for data organization and is not transferred. If you know a parent, select it here, otherwise leave the field empty.</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/UserStory',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUserStory' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/parentUserStory', 'rel': 'canonical' },
            ],
            'cp_parentPropertyJsonPath': 'parentUserStory.identifier',
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': { 'identifier': { 'type': 'string', 'title': 'User Story' } },
            'cp_ui': { 'sortOrderForm': 41 },
        },
        'acceptanceCriteria': {
            'title': 'Acceptance Criteria',
            'description': '<div>The acceptance criteria for acceptance of the delivery.</div>',
            'type': 'string',
            'format': 'cp:html',
            'cp_ui': { 'sortOrderForm': 45 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/acceptanceCriteria', 'rel': 'canonical' }],
        },
        'userStoryDevOpsInfo': {
            'title': 'User Story Dev Ops Info',
            '$id': 'http://platform.cosmoconsult.com/ontology/UserStoryDevOpsInfo',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FUserStoryDevOpsInfo' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/userStoryDevOpsInfo', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'status': {
                    'title': 'Status',
                    'description': 'The status of a thing',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 37 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/status', 'rel': 'canonical' }],
                },
                'responsible': {
                    'title': 'Responsible',
                    '$id': 'http://platform.cosmoconsult.com/ontology/User',
                    'type': 'object',
                    'links': [
                        { 'rel': 'collection', 'href': 'users', 'endpoint': 'd72a94c6-bcf1-46f4-bccd-329d3029dbe4' },
                        { 'href': 'http://platform.cosmoconsult.com/ontology/responsible', 'rel': 'canonical' },
                    ],
                    'cp_handledByApiGateway': true,
                    'cp_disableTrash': true,
                    'cp_disableLocalization': true,
                    'cp_disableVersions': true,
                    'cp_disableClone': true,
                    'cp_disableLazyLoading': true,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'properties': {
                        'email': {
                            'title': 'Email',
                            'description': 'Email address.',
                            'type': 'string',
                            'links': [{ 'href': 'http://schema.org/email', 'rel': 'canonical' }],
                        },
                    },
                    'cp_ui': { 'sortOrderForm': 38 },
                },
                'area': {
                    'title': 'Area',
                    'description': '<div>The area this item belongs to. Currently this can be either the core area or the business area.</div>',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 40 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/area', 'rel': 'canonical' }],
                },
                'valueArea': {
                    'title': 'Value Area',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 41 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/valueArea', 'rel': 'canonical' }],
                },
                'workItemType': {
                    'title': 'Work Item Type',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 35 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/workItemType', 'rel': 'canonical' }],
                },
                'iteration': {
                    'title': 'Iteration',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 42 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/iteration', 'rel': 'canonical' }],
                },
                'tags': {
                    'title': 'Tags',
                    'type': 'array',
                    'items': { 'type': 'string', 'format': 'cp:tags' },
                    'cp_ui': { 'sortOrderForm': 39 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/tag', 'rel': 'canonical' }],
                },
                'workItemId': {
                    'title': 'Work Item Id',
                    'type': 'number',
                    'cp_ui': { 'sortOrderForm': 36 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/workItemId', 'rel': 'canonical' }],
                },
            },
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'sortOrderForm': 54 },
        },
        'iteration': {
            'title': 'Iteration',
            'type': 'string',
            'cp_ui': { 'hiddenInForm': true, 'sortOrderForm': 53 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/iteration', 'rel': 'canonical' }],
        },
        'earliestStart': {
            'title': 'Earliest Start',
            'type': 'string',
            'format': 'date-time',
            'cp_ui': { 'sortOrderForm': 48 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/earliestStart', 'rel': 'canonical' }],
        },
        'latestFinish': {
            'title': 'Latest Finish',
            'type': 'string',
            'format': 'date-time',
            'cp_ui': { 'sortOrderForm': 49 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/latestFinish', 'rel': 'canonical' }],
        },
        'owners': {
            'title': 'Owners',
            'description': '<div>Indicates who is currently considered the owner of the thing and/or the data record. The information pertains to a individual or a group who bear the primary responsibility and/or accountability for the success or failure of the matter. It refers to "ownership" in a business context and is comparable to terms such as "current responsible party", "administrator", or "manager."</div>',
            'type': 'array',
            'items': { 'type': 'string', 'format': 'email', 'transform': ['toLowerCase', 'trim'] },
            'cp_ui': { 'sortOrderForm': 50 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/owner', 'rel': 'canonical' }],
        },
        'createdAt': {
            'title': 'Created at',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'hiddenInForm': true, 'hiddenInTable': true, 'sortOrderForm': 55 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdAt', 'rel': 'canonical' }],
        },
        'createdByUser': {
            'title': 'Created By User',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'hiddenInForm': true, 'hiddenInTable': true, 'sortOrderForm': 56 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/createdByUser', 'rel': 'canonical' }],
        },
        'modifiedAt': {
            'title': 'Modified at',
            'type': 'string',
            'format': 'date-time',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'hiddenInForm': true, 'hiddenInTable': true, 'sortOrderForm': 57 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/modifiedAt', 'rel': 'canonical' }],
        },
        'modifiedByUser': {
            'title': 'Modified By User',
            'type': 'string',
            'readOnly': true,
            'cp_ui': { 'hiddenIfCreate': true, 'hiddenInForm': true, 'hiddenInTable': true, 'sortOrderForm': 58 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/modifiedByUser', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'hiddenInForm': true, 'sortOrderForm': 40 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'description': {
            'title': 'Description',
            'description': '<div>Description of the user story. <strong>Please note any changes if the status changes. User stories are requests that can either be confirmed or changed. A user story is only processed when the status changes to planned.</strong></div>',
            'type': 'string',
            'format': 'cp:html',
            'cp_ui': { 'sortOrderForm': 44 },
            'links': [{ 'href': 'http://schema.org/description', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': '<div>Title, designation or name of the user story.</div>',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 43 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
};
export const UserStorySchema = Schema;
