import { DataItemProperties, FeatureFlag, IJSONSchema, Schemas } from '@cp/base-types';
import { getAllSchemaPaths, IFilter, Operator, parseFilterMessage } from '@cp/base-utils';
import { copyToClipboard, KeyValidationOptions, validateKeyFactory } from '@cpa/base-core/helpers';
import notification from '@cpa/base-core/helpers/toast';
import { useIsCached, usePowerUser } from '@cpa/base-core/hooks';
import { IGlobalState } from '@cpa/base-core/store';
import { DynamicDataUrlFunction } from '@cpa/base-core/types';
import { Icon, IconButton, Link, TextField } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import * as _ from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import urlJoin from 'url-join';

import { getSchemaUIOptions } from '../../../../screens/GenericScreen/utils';
import Drawer from '../../../Drawer/Drawer';
import HoverTooltip from '../../../HoverTooltip/HoverTooltip';

import FilterLayer from './components/FilterLayer/FilterLayer';
import { IFilterExpression, SuggestionValue, toggleFilterProperty, trimSerializedFilter } from './components/FilterLayer/utils';
import Notifications from './components/Notifications/Notifications';
import PromotedFilters from './components/PromotedFilters/PromotedFilters';
import SavedFilters from './components/SavedFilters/SavedFilters';
import styles from './Filter.module.scss';

const fullWidthInputStyles = {
  root: { width: '100%' },
  field: {
    maxHeight: '63px',
    overflow: 'auto',
  },
  fieldGroup: {
    minHeight: '32px',
  },
} as const;

interface IFilterProps {
  onChange: (value: string) => void;
  value?: string;
  dataUrl: string | DynamicDataUrlFunction;
  schema: IJSONSchema;
  page?: Schemas.CpaPage & { dynamicDataUrl?: DynamicDataUrlFunction };
  isSticky?: boolean;
  disableAdvancedFilter?: boolean;
  disableSavedFilters?: boolean;
  disableFullTextFilter?: boolean;
  disableNotifications?: boolean;
  defaultExpanded?: boolean;
}

function getGlobalFilterFromParsedFilter(parsedFilter: IFilter): string {
  if (parsedFilter.global) {
    return parsedFilter.global;
  }

  if (parsedFilter.entries?.[Operator.CONTAINS]) {
    return (parsedFilter.entries[Operator.CONTAINS]![DataItemProperties.SEARCH_TEXT_KEY] as string | undefined) || '';
  }

  if (parsedFilter.entries?.[Operator.AND] || parsedFilter.entries?.[Operator.OR]) {
    for (const entry of (parsedFilter.entries?.[Operator.AND] || parsedFilter.entries?.[Operator.OR])!) {
      if (entry.entries?.[Operator.CONTAINS] && DataItemProperties.SEARCH_TEXT_KEY in entry.entries[Operator.CONTAINS]!) {
        return (entry.entries[Operator.CONTAINS]![DataItemProperties.SEARCH_TEXT_KEY] as string | undefined) || '';
      }
    }

    return '';
  }

  return '';
}

const Filter: React.FC<IFilterProps> = ({
  onChange,
  value = '',
  dataUrl,
  page,
  disableFullTextFilter = false,
  disableAdvancedFilter,
  disableNotifications,
  disableSavedFilters = disableAdvancedFilter,
  schema,
  isSticky,
  defaultExpanded = false,
}) => {
  const [t] = useTranslation();
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const powerUser = usePowerUser();
  const isCached = useIsCached();

  const semanticSearchEnabled = !!schema.cp_vectorizable && !!schema.cp_featureFlags?.includes(FeatureFlag.SemanticSearch);

  const schemaPaths = useMemo(() => {
    if (disableAdvancedFilter) {
      // No need to calculate schema paths if advanced filter is disabled
      return [];
    }

    const uiOptions = getSchemaUIOptions(schema);

    const validator = validateKeyFactory(schema ?? {}, uiOptions.hiddenInFilter ?? [], [
      KeyValidationOptions.isHiddenFullPath,
      KeyValidationOptions.isMatchValidationPattern,
    ]);

    const descriptorsAll = getAllSchemaPaths(schema ?? {}, { validator, primitivesOnly: false });
    return _.uniqBy(descriptorsAll, (d) => d.items.map((p) => p.title).join(' - '));
  }, [disableAdvancedFilter, schema]);

  const [isDrawerOpened, { setTrue: openDrawer, setFalse: closeDrawer }] = useBoolean(false);

  const onFilterChange = useCallback((_: unknown, v: string = '') => onChange(v), [onChange]);

  const parsedFilter: IFilter = useMemo(() => parseFilterMessage(value), [value]);
  const globalFilter: string = useMemo(() => getGlobalFilterFromParsedFilter(parsedFilter), [parsedFilter]);

  const handleShareClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      if (!page || !page.path) return;

      const location = window.location;
      const sharedFilterValue = parsedFilter.global ? `?filter="${encodeURIComponent(value)}"` : `?filter={${encodeURIComponent(value)}}`;
      copyToClipboard(urlJoin(location.origin, page.path.replace('/:id', ''), sharedFilterValue));
      notification.success(t('common.copiedToClipboard'));
    },
    [page, t, value, parsedFilter]
  );

  const handleFilterChange = useCallback(
    (value: Record<string, IFilterExpression> | string) => {
      let serializedFilter;
      if (typeof value === 'string') {
        // If value is string, keep it
        serializedFilter = value;
      } else {
        // If value is complex, stringify and trim
        serializedFilter = trimSerializedFilter(JSON.stringify(value));
      }
      onChange(serializedFilter);
    },
    [onChange]
  );

  const handlePropertyFilterChange = useCallback(
    (propertyJsonPath: string, selectedValues: SuggestionValue | SuggestionValue[]) => {
      handleFilterChange(toggleFilterProperty(parsedFilter.raw, propertyJsonPath, selectedValues, 'contains'));
    },
    [parsedFilter.raw, handleFilterChange]
  );

  const reversedPromotedFilters = useMemo(() => page?.promotedFilters?.slice().reverse() || [], [page?.promotedFilters]);

  const [selectedSavedFilter, setSelectedSavedFilter] = React.useState<Schemas.CpaSavedFilter | undefined>(undefined);
  const handleSavedFilterChange = useCallback(
    (savedFilter: Schemas.CpaSavedFilter | undefined) => {
      setSelectedSavedFilter(savedFilter);
      if (savedFilter) {
        onChange(savedFilter.filter);
      }
    },
    [onChange]
  );

  const user = useSelector((state: IGlobalState) => state.auth.user);
  const savedFilters = useMemo(
    () =>
      !!user && page?.cpTypeUrl && !disableSavedFilters ? (
        <SavedFilters value={selectedSavedFilter} onChange={handleSavedFilterChange} filterValue={value} cpTypeUrl={page.cpTypeUrl} />
      ) : undefined,
    [disableSavedFilters, handleSavedFilterChange, page?.cpTypeUrl, selectedSavedFilter, user, value]
  );

  const areFiltersExpandable = useMemo(
    () => !!page?.promotedFilters?.length || (!!user && !!page?.cpTypeUrl && !disableSavedFilters && powerUser),
    [powerUser, page?.promotedFilters, user, page?.cpTypeUrl, disableSavedFilters]
  );
  const [arePromotedFiltersShown, { setTrue: showPromotedFilters, setFalse: hidePromotedFilters }] = useBoolean(
    (areFiltersExpandable && defaultExpanded) || (!powerUser && !!page?.promotedFilters?.length)
  );

  useEffect(() => {
    if ((parsedFilter?.entries || selectedSavedFilter) && areFiltersExpandable) {
      showPromotedFilters();
    }
  }, [parsedFilter.entries, selectedSavedFilter, showPromotedFilters, areFiltersExpandable]);

  const clearAdvancedFilter = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      onChange(globalFilter);
    },
    [onChange, globalFilter]
  );

  const clearGlobalFilter = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      handlePropertyFilterChange(DataItemProperties.SEARCH_TEXT_KEY, '');
    },
    [handlePropertyFilterChange]
  );

  const handleAdvancedFilterClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      openDrawer();
    },
    [openDrawer]
  );

  const showNotifications: boolean = !!user && !!page?.cpTypeUrl && !schema.cp_handledByApiGateway && !disableNotifications;

  const filterActionsWrapper = useMemo(() => {
    return (
      <div
        className={classNames({
          [styles.filterActionsWrapper]: true,
          [styles.filterActionsWrapperOnCollapsed]: !arePromotedFiltersShown,
          [styles.filterActionsWrapperWithFilterSet]: !!parsedFilter.entries && !disableAdvancedFilter,
        })}
      >
        {showNotifications && powerUser ? <Notifications cpType={page?.cpTypeUrl} filter={value} /> : null}
        {!!savedFilters && !selectedSavedFilter && powerUser && (
          <div
            className={classNames({
              [styles.savedArea]: true,
              [styles.savedAreaDark]: darkMode,
              [styles.savedAreaOnCollapsed]: !arePromotedFiltersShown,
            })}
          >
            {savedFilters}
          </div>
        )}
        {!disableAdvancedFilter && powerUser && (
          <HoverTooltip content={t('search.helper.advancedFilter')}>
            <IconButton
              onClick={handleAdvancedFilterClick}
              // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
              iconProps={{
                iconName: 'QueryList',
              }}
            />
          </HoverTooltip>
        )}
        {!!page?.path && powerUser && (
          <HoverTooltip content={t('common.copyFilterURLToClipboard')} key="copy">
            <div>
              <IconButton
                // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
                styles={{ rootDisabled: { background: 'transparent', pointerEvents: 'none' } }}
                onClick={handleShareClick}
                disabled={!value}
                // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
                iconProps={{ iconName: 'Share' }}
              />
            </div>
          </HoverTooltip>
        )}
      </div>
    );
  }, [
    powerUser,
    arePromotedFiltersShown,
    parsedFilter.entries,
    disableAdvancedFilter,
    savedFilters,
    selectedSavedFilter,
    darkMode,
    t,
    handleAdvancedFilterClick,
    page?.path,
    handleShareClick,
    value,
    page?.cpTypeUrl,
    showNotifications,
  ]);

  const renderSuffix = useCallback(() => {
    return (
      <>
        {!!globalFilter && (
          <IconButton
            // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
            iconProps={{
              iconName: 'Clear',
              className: classNames(styles.clearGlobalFilterIcon, { [styles.dark]: darkMode }),
            }}
            onClick={clearGlobalFilter}
          />
        )}

        {!arePromotedFiltersShown && filterActionsWrapper}

        {!!parsedFilter.entries && !disableAdvancedFilter && (
          <>
            <Link onClick={handleAdvancedFilterClick} className={classNames(styles.advancedFiltersLink, { [styles.dark]: darkMode })}>
              + {t('search.helper.advancedFilter')}
            </Link>
            <IconButton
              className={classNames(styles.clearAdvancedFilterIconWrapper, { [styles.dark]: darkMode })}
              // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
              iconProps={{
                iconName: 'Clear',
                className: classNames(styles.clearAdvancedFilterIcon, { [styles.dark]: darkMode }),
              }}
              onClick={clearAdvancedFilter}
            />
          </>
        )}
      </>
    );
  }, [
    arePromotedFiltersShown,
    filterActionsWrapper,
    globalFilter,
    darkMode,
    clearGlobalFilter,
    parsedFilter.entries,
    disableAdvancedFilter,
    handleAdvancedFilterClick,
    t,
    clearAdvancedFilter,
  ]);

  const searchBoxRender = useCallback(
    (advanced?: boolean) => {
      return (
        <TextField
          className={classNames(
            styles.searchTextField,
            { [styles.withGlobalFilter]: arePromotedFiltersShown && areFiltersExpandable },
            { [styles.borderless]: advanced ?? !disableFullTextFilter }
          )}
          autoComplete={'off'}
          autoAdjustHeight
          rows={1}
          multiline={semanticSearchEnabled}
          resizable={false}
          spellCheck={false}
          // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
          onRenderPrefix={() => <Icon iconName="Search" />}
          onRenderSuffix={advanced ?? !disableFullTextFilter ? renderSuffix : undefined}
          // there is a bug on Firefox that cutts of to long placeholders but didn't resize the field - also its not possible to scroll
          // https://bugzilla.mozilla.org/show_bug.cgi?id=27771
          placeholder={semanticSearchEnabled ? t('common.semanticSearch') : t('common.filter')}
          styles={fullWidthInputStyles}
          value={advanced ?? !disableFullTextFilter ? globalFilter : value}
          // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
          onChange={
            advanced ?? !disableFullTextFilter
              ? // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
                (_event, newValue) => {
                  handlePropertyFilterChange(DataItemProperties.SEARCH_TEXT_KEY, newValue || '');
                }
              : onFilterChange
          }
        />
      );
    },
    [
      arePromotedFiltersShown,
      areFiltersExpandable,
      disableFullTextFilter,
      semanticSearchEnabled,
      renderSuffix,
      t,
      value,
      globalFilter,
      onFilterChange,
      handlePropertyFilterChange,
    ]
  );

  // Disabled icon buttons do not fire onMouseLeave, so we need to wrap when with div
  // https://github.com/microsoft/fluent-ui-react/issues/2021
  return (
    <div
      className={classNames({
        [styles.container]: true,
        [styles.containerWithButtons]: disableFullTextFilter,
      })}
    >
      <div
        className={classNames({
          [styles.searchBoxWrapper]: true,
          [styles.searchBoxWrapperCollapsed]: !arePromotedFiltersShown,
        })}
        onClick={areFiltersExpandable ? showPromotedFilters : undefined}
        {...(semanticSearchEnabled
          ? {
              id: 'semantic-search',
            }
          : {})}
      >
        {searchBoxRender()}
      </div>

      {arePromotedFiltersShown && areFiltersExpandable && (
        <IconButton
          className={styles.collapseFilters}
          onClick={hidePromotedFilters}
          // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
          iconProps={{
            iconName: 'ChevronUp',
          }}
        />
      )}

      <div
        className={classNames({
          [styles.filtersWrapper]: true,
          [styles.filtersWrapperWithNoFullTextFilter]: disableFullTextFilter,
          [styles.filtersWrapperHidden]: !arePromotedFiltersShown && !disableFullTextFilter,
        })}
      >
        {filterActionsWrapper}

        {!!savedFilters && !!selectedSavedFilter && <div className={styles.selectedSavedFilterHost}>{savedFilters}</div>}

        <PromotedFilters
          schema={schema}
          promotedFilters={reversedPromotedFilters}
          dataUrl={page?.dynamicDataUrl ? page.dynamicDataUrl : page?.dataUrl || '/'}
          parsedFilter={parsedFilter}
          onChange={handlePropertyFilterChange}
          isSticky={isSticky}
        />
      </div>
      <Drawer
        isOpen={isDrawerOpened && !isCached && !disableAdvancedFilter}
        onClose={closeDrawer}
        isBlocking={false}
        className={classNames(styles.drawer, { [styles.dark]: darkMode })}
      >
        <div className={styles.drawerContainer}>
          <div className={styles.header}>
            <div className={styles.searchBoxWrapper}>{searchBoxRender(false)}</div>
            {showNotifications ? <Notifications cpType={page?.cpTypeUrl} filter={value} showQuickAction /> : null}
            {savedFilters}
          </div>
          <FilterLayer onChange={handleFilterChange} schemaPaths={schemaPaths} parsedFilter={parsedFilter} dataUrl={dataUrl} disableLocalization={schema.cp_disableLocalization} />
        </div>
      </Drawer>
    </div>
  );
};

export default Filter;
