import { IJSONSchema } from '@cp/base-types';
import { IDataItem } from '@cpa/base-core/types';
import { IDropdownOption } from '@fluentui/react';
import {FieldProps, Registry, UiSchema, utils, WidgetProps} from '@rjsf/core';
import * as _ from 'lodash';
import React, { Component } from 'react';
import { isDefined, isValid } from '@cpa/base-core/helpers';
import { isDefinedAndNotEmpty } from '@cp/base-utils';
import classNames from 'classnames';
import { store } from '@cpa/base-core/store';

import ExpandButton from '../../../ExpandButton/ExpandButton';
import DescriptionField from '../DescriptionField/DescriptionField';
import FieldTitleWithPreview from '../FieldTitleWithPreview/FieldTitleWithPreview';
import { FieldTemplateContext } from '../FieldTemplate/FieldTemplate';

import styles from './AnyOfField.module.scss';

const { getUiOptions, getWidget, guessType, retrieveSchema, getDefaultFormState } = utils;

function compareToSchema(
  item: object,
  requiredFields: string[],
  notRequiredFields: string[]
): {
  existsInRequired: number;
  existsInOther: number;
} {
  let existsInRequired = 0;
  let existsInOther = 0;
  for (const key of Object.keys(item)) {
    if (requiredFields.includes(key)) {
      existsInRequired += 1;
    } else if (notRequiredFields.includes(key)) {
      existsInOther += 1;
    }
  }

  return { existsInRequired, existsInOther };
}

const getMatchingIndex = (formData: IDataItem, options: IJSONSchema[], firstTimeMatching: boolean = true): number | null => {
  const optionsInfo = [];
  for (let i = 0; i < options.length; i++) {
    const option = options[i];
    // @all: new logic to find option with _type field
    if (formData && formData._type && option.$id && formData._type === option.$id) {
      return i;
    }

    const properties = option.properties || option.items?.properties;
    const requiredProperties = option.required || option.items?.required;

    if (properties) {
      const requiredFields = requiredProperties || [];

      if (!isDefined(formData) || (firstTimeMatching && typeof formData === 'object' && Object.keys(formData).length === 0)) {
        const nullIndex = options.findIndex((op) => op.type === 'null');
        return nullIndex !== -1 ? nullIndex : null;
      }

      const notRequiredFields = Object.keys(properties).filter((prop) => !requiredFields.includes(prop));

      const info = {
        index: i,
        existsInRequired: 0,
        existsInOther: 0,
        requiredFields: requiredFields.length,
        notRequiredFields: notRequiredFields.length,
        isValid: false,
      };

      if (Array.isArray(formData)) {
        for (const arrayItem of formData) {
          const { existsInRequired, existsInOther } = compareToSchema(arrayItem, requiredFields, notRequiredFields);
          info.existsInRequired += existsInRequired;
          info.existsInOther += existsInOther;
        }
      } else {
        const { existsInRequired, existsInOther } = compareToSchema(formData, requiredFields, notRequiredFields);
        info.existsInRequired += existsInRequired;
        info.existsInOther += existsInOther;
      }

      info.isValid = isValid(option, formData);
      optionsInfo[i] = info;
    } else if (isValid(option, formData)) {
      return i;
    }
  }

  if (optionsInfo.length > 0 && firstTimeMatching) {
    let validOptions = optionsInfo.filter((info) => info.isValid);

    if (validOptions.length === 0) {
      validOptions = optionsInfo;
    }

    validOptions = _.sortBy(validOptions, ['-existsInRequired', '-existsInOther', 'index']);
    return validOptions[0].index;
  }
  return null;
};

interface IAnyOfFieldProps extends FieldProps {
  registry: Registry & {
    rootSchema?: IJSONSchema;
  };
}

class AnyOfField extends Component<
  IAnyOfFieldProps,
  {
    selectedOption: number;
    anyOfSelectorVisible: boolean;
    isExpanded: boolean;
  }
> {
  static contextType = FieldTemplateContext;
  context!: React.ContextType<typeof FieldTemplateContext>;

  constructor(props: IAnyOfFieldProps) {
    super(props);

    const { formData, options, uiSchema, schema } = this.props;

    const calculatedOption = this.getMatchingOption(schema as IJSONSchema, formData, options, uiSchema);

    this.state = {
      selectedOption: calculatedOption,
      anyOfSelectorVisible: AnyOfField.getAnyOfSelectorVisible(formData),
      isExpanded: (uiSchema.defaultExpanded || (schema as IJSONSchema).cp_rjsfUiSchema?.defaultExpanded) ?? false,
    };
  }

  private toggleExpanded = (): void => {
    this.props.uiSchema.onExpand?.(!this.state.isExpanded);
    this.setState((state) => ({ ...state, isExpanded: !state.isExpanded }));
  };

  private static getOptionMeta(option: IJSONSchema): { _type: string | undefined } {
    return {
      _type: option.$id,
    };
  }

  public componentDidUpdate(): void {
    const { formData, options, schema, uiSchema } = this.props;

    const calculatedOption = this.getMatchingOption(schema as IJSONSchema, formData, options, uiSchema, false);
    const anyOfSelectorVisible = AnyOfField.getAnyOfSelectorVisible(formData);

    if (formData && guessType(formData) === 'object' && !formData._type) {
      // Initial _type set
      this.onOptionChange(calculatedOption);
    }

    if (this.state.selectedOption !== calculatedOption || this.state.anyOfSelectorVisible !== anyOfSelectorVisible) {
      this.setState({
        selectedOption: calculatedOption,
        anyOfSelectorVisible: anyOfSelectorVisible,
      });
    }
  }

  private getMatchingOption(schema: IJSONSchema, formData: IDataItem, options: IJSONSchema[], uiSchema: UiSchema, firstTimeMatching: boolean = true): number {
    const index = getMatchingIndex(formData, options, firstTimeMatching);

    const hiddenOptions = (schema as IJSONSchema)?.cp_rjsfUiSchema?.hiddenOptions || [];
    const enabledOptions = options.map((option: IDropdownOption & { format?: string }, index: number) => {
      if (((hiddenOptions as number[]) || []).indexOf(index) === -1) {
        return index;
      }
      return null;
    }).filter(Boolean) as number[];

    if (hiddenOptions.length && enabledOptions.length === 1) {
      return enabledOptions[0];
    }

    if (index === null) {
      return this && this.state ? this.state.selectedOption : 0;
    }

    return index;
  }

  public onOptionChange = (option: string | number): void => {
    const selectedOption = parseInt(option.toString(), 10);
    const { formData, onChange, options, registry } = this.props;

    const { rootSchema } = registry as unknown as { rootSchema: IJSONSchema };

    const newOptionSchema = retrieveSchema(options[selectedOption], rootSchema, formData) as IJSONSchema;
    const optionMeta = AnyOfField.getOptionMeta(newOptionSchema);

    let newFormData = undefined;

    // Transform object, reset primitive
    if (formData && guessType(formData) === 'object' && (newOptionSchema.type === 'object' || newOptionSchema.properties)) {
      const patternRegexps = Array.from(
        new Set([...Object.keys(newOptionSchema.patternProperties ?? {}), ...Object.keys(rootSchema.patternProperties ?? {})]).values()
      ).map((p) => new RegExp(p));

      newFormData = {
        ...(newOptionSchema.properties ? _.pick(formData, Object.keys(newOptionSchema.properties)) : {}),
        ..._.pickBy(formData, (key) => patternRegexps.some((regexp) => regexp.test(key))),
      };
      _.merge(newFormData, optionMeta);
    }

    const anyOfSelectorVisible = AnyOfField.getAnyOfSelectorVisible(formData);

    if (
      this.state.selectedOption !== selectedOption ||
      this.state.anyOfSelectorVisible !== anyOfSelectorVisible ||
      (optionMeta._type && isDefinedAndNotEmpty(formData) && !formData._type)
    ) {
      const nextValue = getDefaultFormState(options[selectedOption], newFormData, rootSchema);
      onChange(nextValue);

      this.setState({
        selectedOption: selectedOption,
        anyOfSelectorVisible: anyOfSelectorVisible,
      });
    }
  };

  private static getAnyOfSelectorVisible(formData: Record<string, boolean>): boolean {
    if (
      formData &&
      typeof formData === 'object' &&
      formData._nestedTypesRestriction &&
      Array.isArray(formData._nestedTypesRestriction) &&
      formData._nestedTypesRestriction.length <= 1
    ) {
      return false;
    }
    return true;
  }

  public render(): JSX.Element {
    const { baseType, disabled, errorSchema, formData, idPrefix, idSchema, onBlur, onChange, onFocus, options, registry, uiSchema, schema } =
      this.props;

    const _SchemaField = registry.fields.SchemaField as React.FC<IAnyOfFieldProps['']>;
    const { widgets } = registry;
    const { selectedOption } = this.state;
    const { widget = 'select', ...uiOptions } = getUiOptions(uiSchema) as { widget: string };
    const Widget = getWidget({ type: 'number' }, widget as unknown as React.ComponentClass<WidgetProps>, widgets) as React.FC<IAnyOfFieldProps['']>;


    const option = options[selectedOption] || null;
    let optionSchema;

    if (option) {
      optionSchema = option.type ? option : Object.assign({}, option, { type: baseType });
    }

    const enumOptions = options.map((op: IDropdownOption & { format?: string }, index: number) => {
      let label = op.title || `Option ${index + 1}`;
      if (op.format) {
        const state = store.getState();
        const propertyFormatValue = state.app.propertyFormatMap?.[op.format];
        if (propertyFormatValue) {
          label = `${label} (${propertyFormatValue})`;
        }
      }
      return {
        label: label,
        value: index,
      };
    });

    // Disabled enums from _nestedTypesRestriction
    // _nestedTypesRestriction === undefined -> Means no restriction
    // _nestedTypesRestriction === [] -> Means no type is allowed
    // _nestedTypesRestriction === ['http://TypeA', 'http://TypeB'] -> Means only 'http://TypeA' and 'http://TypeB' are allowed
    let enumDisabled = [];
    if (formData && formData._nestedTypesRestriction && formData._nestedTypesRestriction?.length > 0) {
      enumDisabled = options.reduce((opsDisabled: number[], op: IDataItem, index: number) => {
        if (!formData._nestedTypesRestriction.includes(op.$id)) {
          opsDisabled.push(index);
        }
        return opsDisabled;
      }, []);
    }

    let baseLabel: string = schema.title || optionSchema?.title || '';
    const selectedOptionSchema: IJSONSchema = schema.anyOf?.[selectedOption] as IJSONSchema;
    const numberProperty = selectedOptionSchema.type === 'number' ? formData : undefined;
    const stringProperty = selectedOptionSchema.type === 'string' ? formData : undefined;

    if (selectedOptionSchema.type === 'number' || selectedOptionSchema.type === 'string') {
      const format = schema.format || optionSchema.format;
      if (format) {
        const state = store.getState();
        const propertyFormatValue = state.app.propertyFormatMap?.[format];
        if (propertyFormatValue) {
          baseLabel = `${baseLabel} (${propertyFormatValue})`;
        }
      }
    }

    const required = this.context?.required ?? this.props.required;

    return (
      <div className="panel panel-default panel-body">
        {this.state.anyOfSelectorVisible && (
          <div className={classNames(styles.formGroup)}>
            {this.state.isExpanded ? (
              <>
                <ExpandButton isExpanded={this.state.isExpanded} onClick={this.toggleExpanded} />
                <Widget
                  id={`${idSchema.$id}${schema.oneOf ? '__oneof_select' : '__anyof_select'}`}
                  schema={{
                    type: 'number',
                    default: 0,
                    title: uiSchema.isArrayItem ? undefined : baseLabel,
                    readOnly: schema.readOnly,
                    description: schema.description,
                  }}
                  onChange={this.onOptionChange}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  value={selectedOption}
                  options={{ enumOptions, enumDisabled: [...enumDisabled, ...((schema as IJSONSchema)?.cp_rjsfUiSchema?.hiddenOptions || [])], anyOfSelector: true }}
                  uiSchema={{ onLabelClick: this.toggleExpanded }}
                  required={required}
                  disabled={options?.length === 1}
                  {...uiOptions}
                />
              </>
            ) : (
              <div className={styles.wrapper}>
                <div className={styles.labelWrapper}>
                  <ExpandButton isExpanded={this.state.isExpanded} onClick={this.toggleExpanded} />
                  <FieldTitleWithPreview
                    baseLabel={baseLabel}
                    schema={optionSchema as IJSONSchema}
                    formData={formData}
                    registry={registry}
                    fallbackPreview={stringProperty || numberProperty}
                    isArrayItem={uiSchema.isArrayItem}
                    showExpandButton={this.state.anyOfSelectorVisible}
                    onClick={this.toggleExpanded}
                    required={required}
                    fieldId={idSchema.$id}
                  />
                </div>
                <DescriptionField description={schema.description || ''} detailed={false} hasDetails={this.state.anyOfSelectorVisible} />
              </div>
            )}
          </div>
        )}

        {(this.state.isExpanded || !this.state.anyOfSelectorVisible) && option !== null && (
          <div style={{ marginLeft: 32 }}>
            <_SchemaField
              schema={optionSchema}
              uiSchema={{ ...uiSchema, hideExpand: true, hideTitle: this.state.anyOfSelectorVisible }}
              errorSchema={errorSchema}
              idSchema={idSchema}
              idPrefix={idPrefix}
              formData={formData}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              registry={registry}
              disabled={disabled}
              required={required}
            />
          </div>
        )}
      </div>
    );
  }
}

export default AnyOfField;
