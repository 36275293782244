import { IJSONSchema, Schemas } from '@cp/base-types';
import { IGlobalState } from '@cpa/base-core/store';
import { IWidgetSettings } from '@cpa/base-core/types';
import {
  DocumentCard,
  DocumentCardDetails,
  DocumentCardTitle,
  IDocumentCardTitleProps,
  Icon,
  ThemeContext,
  ICommandBarItemProps,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import React, { CSSProperties, useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import Mustache from 'mustache';

import HtmlContent from '../HtmlContent/HtmlContent';

import NavigationBreadcrumbs from './components/NavigationBreadcrumbs/NavigationBreadcrumbs';
import Settings, { ISettingsItem, SettingsItemType } from './components/Settings/Settings';
import dark from './styles/dark.module.scss';
import light from './styles/light.module.scss';

export interface IWidgetProps {
  title?: string;
  identifier?: string;
  page?: Schemas.CpaPage;
  schema?: IJSONSchema | null;
  isWidget?: boolean;
  headerIconName?: string;
  headerIconContent?: JSX.Element;
  subtitle?: string;
  settingsItems?: ISettingsItem[];
  additionalSettingsItems?: ICommandBarItemProps[];
  style?: CSSProperties;
  className?: string;
  titleClassName?: string;
  hideSettings?: boolean;
  children?: React.ReactNode;
  widgetSettings?: IWidgetSettings;
}

const defaultSettingsItems: ISettingsItem[] = [];

const Widget: React.FC<IWidgetProps> = ({
  page,
  identifier,
  isWidget,
  title,
  subtitle,
  settingsItems,
  additionalSettingsItems,
  children,
  headerIconName,
  headerIconContent,
  style,
  className,
  titleClassName,
  hideSettings,
  schema,
  widgetSettings,
}) => {
  const theme = useContext(ThemeContext);
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const currentTourBubble = useSelector((state: IGlobalState) => state.app.currentTourBubble?.step);
  const isBubbleWidget = useSelector((state: IGlobalState) => state.app.currentTourBubble?.isWidget);
  const user = useSelector((state: IGlobalState) => state.auth.user?.account);
  const appName = useSelector((state: IGlobalState) => state.app.cpa?.name);

  const styles = useMemo(() => {
    return darkMode ? dark : light;
  }, [darkMode]);

  const [hovered, { setTrue: setHovered, setFalse: setUnhovered }] = useBoolean(false);

  const documentCardStyle: React.CSSProperties = useMemo(() => {
    return {
      background: page?.transparentWidget ? 'transparent' : darkMode ? theme?.palette.neutralSecondaryAlt : theme?.palette.white,
      height: '100%',
      borderRadius: '14px',
      borderColor: 'transparent',
      ...style,
    };
  }, [theme, darkMode, style, page]);

  const isTouchDevice = useMediaQuery({
    query: '(hover: none)',
  });

  const iconStyle = useMemo(
    () => ({
      color: hovered || isTouchDevice ? theme?.palette.themePrimary : theme?.palette.black,
      paddingLeft: '16px',
      transition: 'color 0.2s',
    }),
    [hovered, theme, isTouchDevice]
  );

  const navigationBreadcrumbs = useMemo(() => {
    if (isWidget || !page) {
      return null;
    }

    return (
      <NavigationBreadcrumbs
        page={page}
        currentItemTitle={title}
        currentItemIdentifier={identifier}
        schema={schema}
        customButtonClassName={styles.navigationButton}
      />
    );
  }, [isWidget, page, title, identifier, schema, styles.navigationButton]);

  const titleRender = useCallback(() => {
    const documentCardTitleProps: IDocumentCardTitleProps = {
      title: title || page?.name || '',
      className: classNames(styles.title, widgetSettings?.titleClassName),
      styles: {
        root: {
          color: hovered ? `${theme?.palette.themePrimary} !important` : darkMode ? undefined : `${theme?.palette.black} !important`,
          transition: 'color 0.2s',
        },
      },
    };

    if (isWidget) {
      const showMoreSettingsItem = settingsItems?.find((item) => item.type === SettingsItemType.MORE);

      if (showMoreSettingsItem) {
        return (
          <a href={showMoreSettingsItem.url} className={styles.linkWrapper} onClick={showMoreSettingsItem.onClick}>
            <DocumentCardTitle {...documentCardTitleProps} />
          </a>
        );
      }

      return <DocumentCardTitle {...documentCardTitleProps} />;
    }

    if (!page) {
      return <DocumentCardTitle {...documentCardTitleProps} />;
    }

    return navigationBreadcrumbs;
  }, [
    darkMode,
    hovered,
    widgetSettings?.titleClassName,
    title,
    page,
    styles.title,
    styles.linkWrapper,
    theme?.palette.themePrimary,
    theme?.palette.black,
    isWidget,
    navigationBreadcrumbs,
    settingsItems,
  ]);

  const processedSubtitle = useMemo(() => {
    if (!subtitle) return null;
    const view = {
      given_name: user?.given_mame,
      family_name: user?.family_name,
      name: user?.name || 'guest',
      email: user?.email,
      cpa_name: appName,
    };
    return Mustache.render(subtitle, view);
  }, [appName, subtitle, user?.email, user?.family_name, user?.given_mame, user?.name]);

  const numberOfSettingItems = useMemo(() => {
    const settingsCount = (settingsItems || defaultSettingsItems).length + 1;
    return additionalSettingsItems?.length ? settingsCount + 1 : settingsCount;
  }, [settingsItems?.length, additionalSettingsItems?.length]);

  return (
    <DocumentCard
      className={classNames(styles.container, className)}
      onMouseEnter={setHovered}
      onMouseLeave={setUnhovered}
      onMouseMove={setHovered}
      style={documentCardStyle}
    >
      {!widgetSettings?.hideTitle && (
        <div className={styles.widgetHeader}>
          <DocumentCardDetails className={classNames(styles.titleWrapper, titleClassName)}>
            <div style={{ paddingLeft: 4 }}>
              {headerIconName || headerIconContent ? (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  {(!widgetSettings?.hideIcon && headerIconContent) ?? <Icon iconName={headerIconName} style={iconStyle} />}
                  {titleRender()}
                </div>
              ) : (
                titleRender()
              )}
            </div>
          </DocumentCardDetails>
          {!hideSettings && (
            <div
              style={{
                flex: `0 1 ${40 * numberOfSettingItems}px`,
                minWidth: 'fit-content',
                borderRadius: '0 14px 0 0',
                overflow: 'hidden',
              }}
            >
              <Settings
                items={settingsItems || defaultSettingsItems}
                additionalItems={additionalSettingsItems}
                showButtons={!isWidget || currentTourBubble === page?.path || !!isBubbleWidget}
              />
            </div>
          )}
        </div>
      )}
      {processedSubtitle && !widgetSettings?.hideDescription && (
        <div style={{ paddingLeft: 4 }}>
          <HtmlContent className={classNames(styles.subtitle, widgetSettings?.subtitleClassName)} html={processedSubtitle} />
        </div>
      )}
      {children}
      {widgetSettings?.footerRenderer?.()}
    </DocumentCard>
  );
};

export default Widget;
