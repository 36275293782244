import { DataServiceModules } from '@cp/base-utils';

import { subscribeToEntityModification } from '../store/websocket/saga';

import { rootDataService } from './axios';
import { IDataStoreItemDto } from './entities';

class ItemCache {
  private cache: Map<string, Map<string, Promise<unknown>>> = new Map();

  private async fetchItem<T>(subjectUri: string, identifier: string): Promise<T> {
    const { value } = await rootDataService.get<IDataStoreItemDto<T>>(
      `${DataServiceModules.DATA_STORE}/${encodeURIComponent(subjectUri)}/${encodeURIComponent(identifier)}`
    );
    return value;
  }

  public async get<T>(subjectUri: string, identifier: string): Promise<T> {
    if (!this.cache.has(subjectUri)) {
      this.cache.set(subjectUri, new Map());
      await subscribeToEntityModification(subjectUri);
    }
    const subjectCache = this.cache.get(subjectUri)!;

    if (subjectCache.has(identifier)) {
      return (await subjectCache.get(identifier)) as T;
    }

    const itemPromise = this.fetchItem<T>(subjectUri, identifier);
    subjectCache.set(identifier, itemPromise);
    return itemPromise;
  }

  public clearAll() {
    this.cache.clear();
  }

  public clearSubject(subjectUri: string) {
    this.cache.delete(subjectUri);
  }
}

export const globalItemCache = new ItemCache();

const TOKENCACHE__TOKEN_KEY = 'tokenCache__token';
const TOKENCACHE__CACHE_EXPIRES_AT = 'tokenCache__cacheExpiresAt';

/**
 * Get token from cache if it is not expired. Otherwise return null.
 */
export const getTokenFromCache = (): string | null => {
  const token = localStorage.getItem(TOKENCACHE__TOKEN_KEY);
  const cacheExpiresAt = localStorage.getItem(TOKENCACHE__CACHE_EXPIRES_AT);

  if (!token || !cacheExpiresAt) {
    return null;
  }

  const now = Date.now();

  if (+cacheExpiresAt < now) {
    clearTokenCache();
    return null;
  }

  return token;
};

/**
 * Save token to cache.
 */
export const saveTokenToCache = (token: string, expiresOn: Date | null): void => {
  const now = Date.now();
  const cacheTtl = 15 * 1000; // 15 seconds
  const cacheExpiresAt = expiresOn ? Math.min(expiresOn.getTime(), now + cacheTtl) : now + cacheTtl;

  localStorage.setItem(TOKENCACHE__TOKEN_KEY, token);
  localStorage.setItem(TOKENCACHE__CACHE_EXPIRES_AT, cacheExpiresAt.toString());
};

/**
 * Clear token cache.
 */
export const clearTokenCache = (): void => {
  localStorage.removeItem(TOKENCACHE__TOKEN_KEY);
  localStorage.removeItem(TOKENCACHE__CACHE_EXPIRES_AT);
};
