import { CpaPageActionExecutionContext, FieldTrigger, IJSONSchema, Schemas, TypeConstants } from '@cp/base-types';
import { areDataItemsEqual, clearNulls, cloneDeepWithMetadata, makeSchemaPartial } from '@cp/base-utils';
import { axiosDictionary, getCpFunction, putEntityToEndpoint } from '@cpa/base-core/api';
import { executeUiTrigger, showDialog } from '@cpa/base-core/helpers';
import { IDataItem } from '@cpa/base-core/types';
import { DialogType, MessageBar, MessageBarType } from '@fluentui/react';
import { JSONSchema7TypeName } from 'json-schema';
import * as _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Drawer from '../../../../../../components/Drawer/Drawer';
import Form, { IFormChangeEvent } from '../../../../../../components/Form/Form';
import LoadingArea from '../../../../../../components/LoadingArea/LoadingArea';
import MessageBars from '../../../../../../components/MessageBars/MessageBars';
import { SingleItemContext } from '../../../../../../screens/GenericScreen/components/SingleItem/context';

type Unpacked<T> = T extends (infer U)[] ? U : T;
type SolutionTypeDetails = Unpacked<Schemas.Solution['solutionTypeDetails']>;
type Component = NonNullable<
  Extract<
    SolutionTypeDetails,
    {
      _type?: 'http://platform.cosmoconsult.com/ontology/Components';
    }
  >['components']
>[number];

type EnvironmentExceptions = Record<string, Record<string, string | number>>;
type ComponentExceptions = Record<string, Record<string, Partial<Component>>>;
type NodeExceptions = {
  environment: EnvironmentExceptions;
  components: ComponentExceptions;
};

interface ISolutionEnvironmentExceptionsDrawerProps {
  rootSolution: Schemas.Solution;
  solutionsSchema: IJSONSchema;
  solutionsPage: Schemas.CpaPage;
  title: string;
  solution: (Schemas.Solution & IDataItem<Schemas.Solution>) | null;
  onClose: (...args: unknown[]) => void;
}

interface IExceptionsFormData extends IDataItem {
  component?: Partial<Component> & { _componentId: string };
  exceptions?: Record<string, string>;
  otherExceptions?: Record<string, string>;
}

function buildSolutionComponentChain(solution: Schemas.Solution & IDataItem<Schemas.Solution>, stopOn?: string): string[] {
  if (stopOn && solution.identifier === stopOn) {
    return [];
  }

  const item = solution.__originalItem || solution;
  const result = [];
  if (item.__parentItem) {
    result.push(...buildSolutionComponentChain(item.__parentItem, stopOn));
  }
  if (item.__componentId) {
    result.push(item.__componentId);
  }

  return result;
}

interface IDiscoveryResult {
  variables: IDiscoveredVariable[];
  component: IDiscoveredComponent;
}

interface IDiscoveredComponent {
  value?: Partial<Component>;
  componentId: string;
}

interface IDiscoveredVariable {
  sourceNodePath: string[] | null;
  name?: string;
  envVarName: string;
  placeholder?: string | number;
  value?: string | number;
  isOnlyUsedInSubtree: boolean;
  description?: string;
  supportedValues?: Schemas.SolutionVariableKey['supportedValues'];
}

interface IProcessedDiscoveredVariable {
  name: string;
  placeholder?: string | number;
  value?: string | number;
  isOnlyUsedInSubtree: boolean;
  title?: string;
  description?: string | null;
  supportedValues?: Schemas.SolutionVariableKey['supportedValues'];
}

function generateVariableSchema(variable: IProcessedDiscoveredVariable): IJSONSchema {
  const fieldType =
    (
      {
        string: 'string',
        boolean: 'boolean',
        number: 'number',
      } as Record<string, JSONSchema7TypeName>
    )[typeof variable.placeholder] || 'string';

  return {
    title: variable.title || variable.name,
    description: variable.description ? variable.description : undefined,
    cp_rjsfUiSchema: { 'ui:placeholder': variable.placeholder },
    type: fieldType,
    ...(variable.supportedValues
      ? {
          links: [
            {
              rel: 'glossary',
              href: TypeConstants.Thing,
            },
          ],
          cp_ui: {
            disableLookupAdvancedFilter: true,
          },
          cp_fieldTriggers: {
            [FieldTrigger.OnLookup]: [
              `trigger.execute = (executionContext) => {
                executionContext.disableParenting();
                executionContext.disableRequest();
                executionContext.setVirtualData({
                  schema: {
                    type: 'object',
                    properties: {
                      identifier: {
                        type: 'string',
                        title: executionContext.fieldSchema.title
                      }
                    }
                  },
                  items: ${JSON.stringify(variable.supportedValues)}.map((suggestion) => ({ identifier: suggestion })),
                  totalItems: ${variable.supportedValues.length}
                });
              }`,
            ],
          },
        }
      : {}),
  };
}

const nodeExceptionsPath = '_nodeExceptions';

const SolutionEnvironmentExceptionsDrawer: React.FC<ISolutionEnvironmentExceptionsDrawerProps> = ({
  solutionsPage,
  solutionsSchema,
  rootSolution,
  title,
  solution,
  onClose,
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const singleItemContext = useContext(SingleItemContext);
  const [t] = useTranslation();

  const componentSchema = solutionsSchema.properties?.solutionTypeDetails?.items?.anyOf?.find(
    ({ $id }) => $id === 'http://platform.cosmoconsult.com/ontology/Components'
  )?.properties?.components?.items;

  const rootSolutionComponentChain = useMemo(() => {
    if (!solution) {
      return;
    }
    return buildSolutionComponentChain(solution);
  }, [solution]);

  const [generatedSchema, setGeneratedSchema] = useState<IJSONSchema>();
  const [formData, setFormData] = useState<IExceptionsFormData>();
  const trackedFormData = useRef<IDataItem | null | undefined>(formData);

  const generateSchema = useCallback(async () => {
    if (!rootSolutionComponentChain) {
      return;
    }

    setGeneratedSchema(undefined);

    setFormData(undefined);
    trackedFormData.current = undefined;

    setErrors([]);
    try {
      const cpFunction = await getCpFunction('solution-calculate-new');
      const result = (await executeUiTrigger<CpaPageActionExecutionContext<Schemas.Solution>>(
        {
          event: 'Action',
          schema: solutionsSchema,
          page: solutionsPage,
          items: [rootSolution],
          setInfoMessages: (): void => {},
          reloadCurrentItems: (): Promise<void> => {
            return Promise.resolve();
          },
          additionalProps: {
            function: 'parameterize-discovery',
            nodePath: rootSolutionComponentChain,
          },
        },
        { sourceCode: cpFunction.sourceCode }
      )) as IDiscoveryResult;

      const variables: IProcessedDiscoveredVariable[] =
        result.variables?.map(({ envVarName, name, placeholder, value, isOnlyUsedInSubtree, description, supportedValues }) => ({
          name: envVarName,
          placeholder: placeholder,
          value: value,
          isOnlyUsedInSubtree,
          title: name,
          description,
          supportedValues,
        })) || [];

      const mainVariables = variables.filter((v) => !v.isOnlyUsedInSubtree);
      const otherVariables = variables.filter((v) => v.isOnlyUsedInSubtree);

      const newFormData = clearNulls({
        component: {
          ...result.component.value,
          _componentId: result.component.componentId,
        },
        exceptions: mainVariables.length
          ? mainVariables.reduce((acc, variable) => {
              return {
                ...acc,
                [variable.name]: variable.value,
              };
            }, {})
          : undefined,
        otherExceptions: otherVariables.length
          ? otherVariables.reduce((acc, variable) => {
              return {
                ...acc,
                [variable.name]: variable.value,
              };
            }, {})
          : undefined,
      });
      setFormData(newFormData);
      trackedFormData.current = newFormData;

      setGeneratedSchema({
        type: 'object',
        additionalProperties: true,
        properties: {
          ...(componentSchema
            ? {
                component: {
                  type: 'object',
                  title: componentSchema?.title || '',
                  cp_rjsfUiSchema: {
                    defaultExpanded: true,
                  },
                  properties: _.omit(makeSchemaPartial(componentSchema as IJSONSchema).properties, 'shortDescription', 'solution') as IJSONSchema,
                },
              }
            : {}),
          ...(mainVariables.length
            ? {
                exceptions: {
                  type: 'object',
                  title: t('configurationCanvas.exceptions'),
                  cp_rjsfUiSchema: {
                    defaultExpanded: true,
                  },
                  properties: mainVariables.reduce((acc, variable) => {
                    return {
                      ...acc,
                      [variable.name]: generateVariableSchema(variable),
                    };
                  }, {}),
                },
              }
            : {}),
          ...(otherVariables.length
            ? {
                otherExceptions: {
                  type: 'object',
                  title: t('configurationCanvas.otherExceptions'),
                  cp_rjsfUiSchema: {
                    defaultExpanded: true,
                  },
                  properties: otherVariables.reduce((acc, variable) => {
                    return {
                      ...acc,
                      [variable.name]: generateVariableSchema(variable),
                    };
                  }, {}),
                },
              }
            : {}),
        },
      });
    } catch (e) {
      setErrors([e.message]);
    }
  }, [componentSchema, rootSolution, rootSolutionComponentChain, solutionsPage, solutionsSchema, t]);

  useEffect(() => {
    generateSchema();
  }, [generateSchema]);

  const handleSubmit = useCallback(
    async (obj: { formData: IExceptionsFormData }) => {
      const allDiscoveredKeys = [
        ...Object.keys(generatedSchema?.properties?.exceptions?.properties || {}),
        ...Object.keys(generatedSchema?.properties?.otherExceptions?.properties || {}),
      ];

      const { exceptions = {}, otherExceptions = {}, component } = obj.formData;
      const allExceptions = { ...exceptions, ...otherExceptions };

      const page = singleItemContext?.rootPage;
      if (!page || !page.dataUrl || !solution) {
        return;
      }

      let uploadedSolution: (Schemas.Solution & IDataItem<Schemas.Solution>) | null = null;

      enum ExceptionsStorageMode {
        Environment,
        Component,
      }

      async function tryToSaveExceptions(targetSolution: Schemas.Solution & IDataItem<Schemas.Solution>, mode: ExceptionsStorageMode): Promise<void> {
        // It's important to read from __originalItem
        // because targetSolution itself represents a stripped table item and is not suitable for data operation
        let solutionToUpdate = targetSolution.__originalItem || targetSolution;
        if (uploadedSolution && solutionToUpdate.identifier === uploadedSolution.identifier) {
          solutionToUpdate = uploadedSolution;
        }

        if (
          solutionToUpdate.standard
          // Maybe we will add back root context check later in future
          // || !checkIfSolutionCreatedInRootContext(targetSolution, rootSolution, solutionsSchema)
        ) {
          // We can't store it on standard solution level
          if (targetSolution.__parentItem) {
            await tryToSaveExceptions(targetSolution.__parentItem, mode);
            return;
          } else {
            throw new Error(t('configurationCanvas.noPlaceToStoreExceptions'));
          }
        }

        const key = JSON.stringify(
          buildSolutionComponentChain(mode === ExceptionsStorageMode.Environment ? solution! : solution!.__parentItem!, solutionToUpdate.identifier)
        );
        const previousNodeExceptions = solutionToUpdate[nodeExceptionsPath] as NodeExceptions | undefined;
        const updatedSolution = {
          ...solutionToUpdate,
          [nodeExceptionsPath]: (previousNodeExceptions || {}) as NodeExceptions,
        } as Schemas.Solution & IDataItem<Schemas.Solution> & { [nodeExceptionsPath]: NodeExceptions };

        switch (mode) {
          case ExceptionsStorageMode.Environment:
            updatedSolution[nodeExceptionsPath].environment = {
              ...previousNodeExceptions?.environment,
              [key]: {
                ..._.omit((previousNodeExceptions?.environment?.[key] as Record<string, string | number>) || {}, allDiscoveredKeys),
                ...allExceptions,
              },
            };
            break;
          case ExceptionsStorageMode.Component:
            updatedSolution[nodeExceptionsPath].components = {
              ...previousNodeExceptions?.components,
              [key]: {
                ...previousNodeExceptions?.components?.[key],
                [component!._componentId]: _.omit(component, '_componentId'),
              },
            };
            break;
        }

        updatedSolution[nodeExceptionsPath] = clearNulls(cloneDeepWithMetadata(updatedSolution[nodeExceptionsPath]));

        try {
          uploadedSolution = await putEntityToEndpoint<Schemas.Solution & IDataItem<Schemas.Solution>>(
            page!.dataEndpoint?.identifier || axiosDictionary.appDataService,
            page!.dataUrl!,
            solutionToUpdate,
            updatedSolution
          );
        } catch (e) {
          if ('statusCode' in e && e.statusCode >= 400 && e.statusCode < 499 && targetSolution.__parentItem && e.statusCode !== 412) {
            await tryToSaveExceptions(targetSolution.__parentItem, mode);
            return;
          }

          throw e;
        }
      }

      try {
        await tryToSaveExceptions(solution, ExceptionsStorageMode.Environment);
        if (solution.__parentItem) {
          await tryToSaveExceptions(solution.__parentItem, ExceptionsStorageMode.Component);
        }

        setGeneratedSchema(undefined);
        setFormData(undefined);
        trackedFormData.current = undefined;
        onClose();
      } catch (e) {
        console.error('Environment discovery failed', e);
        setErrors([e.message]);
      }
    },
    [generatedSchema, singleItemContext?.rootPage, solution, t, onClose]
  );

  const onChangeHandler = useCallback((obj: IFormChangeEvent) => {
    trackedFormData.current = obj.formData;
  }, []);

  const handleDrawerClose = useCallback(async () => {
    const closeConfirmed =
      areDataItemsEqual(trackedFormData.current, formData) ||
      ((await showDialog({
        message: '',
        dialogContentProps: {
          type: DialogType.largeHeader,
          title: t('common.beforeClose'),
          subText: t('common.beforeCloseSubtitle'),
        },
        primaryButtonText: 'common.close',
        secondaryButtonText: 'common.cancel',
        closeOnClickOutside: true,
        closeOnAction: true,
      })) as boolean);

    if (closeConfirmed) {
      onClose();
    }
  }, [formData, t, onClose]);

  if (!solution) {
    return null;
  }

  let drawerContent;
  if (errors.length) {
    drawerContent = <MessageBars messageBarType={MessageBarType.error} isMultiline={true} messages={errors} />;
  } else if (generatedSchema?.properties) {
    drawerContent =
      Object.keys(generatedSchema.properties).length === 0 ? (
        <MessageBar messageBarType={MessageBarType.info} isMultiline={false}>
          {t('configurationCanvas.noExceptions')}
        </MessageBar>
      ) : (
        <Form formData={formData} onChange={onChangeHandler} schema={generatedSchema} disableStickySubmitButton={true} onSubmit={handleSubmit} />
      );
  } else {
    drawerContent = <LoadingArea />;
  }

  return (
    <Drawer isOpen={true} onClose={handleDrawerClose} title={`${solution?.name} - ${title}`}>
      {drawerContent}
    </Drawer>
  );
};

export default SolutionEnvironmentExceptionsDrawer;
