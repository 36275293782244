import { EventEmitter } from 'events';

import {
  FieldTrigger,
  IJSONSchema,
  OnAfterFormChangeExecutionContext,
  OnBeforeFormInitExecutionContext,
  OnInputBlurExecutionContext,
  Schemas,
  TriggerType,
  TypeTrigger,
} from '@cp/base-types';
import { clearNulls, cloneDeepWithMetadata, sleep } from '@cp/base-utils';
import { FormContext, ItemInfoContext } from '@cpa/base-core/constants';
import { cloneValueAndCleanUpInternalProperties, executeUiTriggers, ignoreRefs, isTouchDevice, resolveUiTriggersCode } from '@cpa/base-core/helpers';
import { GuidedStep, RegularStep, StepTypes } from '@cpa/base-core/helpers/wizard';
import { useLanguage, usePowerUser } from '@cpa/base-core/hooks';
import { useWizard } from '@cpa/base-core/hooks/form';
import { IGlobalState } from '@cpa/base-core/store';
import { IDataItem } from '@cpa/base-core/types';
import { DefaultButton, IContextualMenuProps, MessageBarType, PrimaryButton, Sticky, StickyPositionType } from '@fluentui/react';
import { useBoolean, useForceUpdate, usePrevious, useSetTimeout } from '@fluentui/react-hooks';
import { AjvError, ISubmitEvent, UiSchema } from '@rjsf/core';
import type { Editor } from '@tinymce/tinymce-react';
import classNames from 'classnames';
import * as _ from 'lodash';
import React, { lazy, Suspense, SyntheticEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import LoadingArea from '../LoadingArea/LoadingArea';
import MessageBars from '../MessageBars/MessageBars';
import { IWizardOptions } from '../ScrollingContent/ScrollingContent';

import JsonSchemaForm, { FormRef } from './components/JsonSchemaForm/JsonSchemaForm';
import styles from './Form.module.scss';
import { makeUiSchema } from './helpers/data';
import theme from './helpers/theme';
import { IBlurOptions } from './hocs/withBlurEventHandling';
import { SchemaOverwritesContext, useNewSchemaOverwritesStore } from './stores/SchemaOverwrites';

const JsonFormEditor = lazy(() => import('./components/JsonFormEditor/JsonFormEditor'));

export interface IFormChangeEvent {
  formData: any;
  errors?: AjvError[];
}

export interface IFormRef {
  submit(): void;
}

export interface IFormProps {
  schema: IJSONSchema;
  onBeforeSubmit?: (obj: { formData: unknown }) => Promise<{ cancelSubmit: boolean }>;
  onSubmit?: (obj: { formData: unknown }, closeDrawer?: boolean) => void | Promise<void>;
  onSubmitAs?: (obj: { formData: unknown }) => void | Promise<void>;
  closeForm?: (options?: { skipConfirmation?: boolean }) => Promise<void>;
  btnText?: string;
  formData?: IDataItem;
  readonly?: boolean;
  editFormsAsJson?: boolean;
  onChange?: (obj: IFormChangeEvent) => void;
  getCurrentFormState?: () => IDataItem | null | undefined;
  showSaveUrl?: boolean;
  onCopiedToClipboard?: (formDataToShare: IDataItem<unknown>) => void;
  onFieldLocalizationChanged?: (_eTag: string, language: string, dataPath: string, updatedValue: IDataItem) => void;
  hideSubmit?: boolean;
  page?: Schemas.CpaPage;
  disableStickySubmitButton?: boolean;
  enableKeyboardHotkeys?: boolean;
  wizardOptions?: IWizardOptions;
  customButtons?: JSX.Element[];
}

enum SubmitType {
  AsCopy = 'SUBMIT_AS_COPY',
  WithoutClose = 'SUBMIT_WITHOUT_CLOSE',
}

const loadingPlaceholder = <LoadingArea />;

const Form: React.FC<IFormProps> = ({
  schema,
  onBeforeSubmit,
  onSubmit,
  onSubmitAs,
  closeForm,
  btnText,
  formData,
  readonly = false,
  onChange,
  getCurrentFormState,
  showSaveUrl,
  onCopiedToClipboard,
  onFieldLocalizationChanged,
  editFormsAsJson,
  hideSubmit,
  page,
  disableStickySubmitButton,
  enableKeyboardHotkeys,
  wizardOptions,
  customButtons,
}) => {
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const currentFormData = useRef(formData ? clearNulls(cloneDeepWithMetadata(formData)) : undefined);
  const timeoutManager = useSetTimeout();

  const formRef: FormRef = useRef(null);

  const previousCurrentFormData = useRef(currentFormData.current);
  const previousFormData = usePrevious(formData);

  if (previousFormData !== formData) {
    currentFormData.current = formData ? clearNulls(cloneDeepWithMetadata(formData)) : undefined;
  }

  const forceUpdate = useForceUpdate();
  const processedSchema: IJSONSchema = useMemo<IJSONSchema>(() => ignoreRefs<IJSONSchema>(schema), [schema]);
  const [formSchema, setFormSchema] = useState<IJSONSchema | null>(null);
  const [t] = useTranslation();
  const id = useMemo(() => Math.random().toString(), []);
  const powerUser = usePowerUser();

  const currentLanguage = useLanguage();

  const itemInfoContext = useContext(ItemInfoContext);

  useEffect(() => {
    const initTriggers = schema.cp_typeTriggers?.[TypeTrigger.OnBeforeFormInit];

    if (Array.isArray(initTriggers) && initTriggers.length) {
      const schemaForTrigger = cloneDeepWithMetadata(processedSchema);

      resolveUiTriggersCode(initTriggers)
        .then((triggers) =>
          executeUiTriggers<OnBeforeFormInitExecutionContext>(
            {
              event: TypeTrigger.OnBeforeFormInit,
              data: currentFormData.current,
              schema: schemaForTrigger,
              page: page,
              showMessage: itemInfoContext?.showMessage,
              isCreate: itemInfoContext?.type === 'add',
              isUpdate: itemInfoContext?.type === 'edit',
              get formData(): IDataItem | undefined {
                return cloneDeepWithMetadata(currentFormData.current);
              },
              setFormData: (newFormData: IDataItem) => {
                currentFormData.current = newFormData;
                forceUpdate();
              },
              overrideFormSchema,
              closeForm,
            },
            triggers
          )
        )
        .finally(() => {
          setFormSchema(schemaForTrigger);
        });
    } else {
      setFormSchema(processedSchema);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema]);

  const generateFormEventEmitter = (): EventEmitter => {
    const eventEmitter = new EventEmitter();
    eventEmitter.setMaxListeners(0);
    return eventEmitter;
  };

  const formEventEmitter = useMemo(() => generateFormEventEmitter(), []);

  const useSchemaOverwritesStore = useNewSchemaOverwritesStore();

  const overrideFormSchema = useSchemaOverwritesStore((state) => state.mergeWith);

  const submitDisableTimeout = useRef<number | null>(null);
  const readyToSubmit = useRef<{ ready: boolean; submitFunction?: () => void }>({ ready: false });
  const actionsCounter = useRef(0);
  const [submitDisabled, { setTrue: disableSubmit, setFalse: enableSubmit }] = useBoolean(false);

  const onChangeHandler = useCallback(
    (obj: IFormChangeEvent) => {
      currentFormData.current = obj.formData ? clearNulls(cloneDeepWithMetadata(obj.formData)) : undefined;

      onChange?.(obj);

      const changeTriggers = formSchema?.cp_typeTriggers?.[TypeTrigger.OnAfterFormChange];
      if (Array.isArray(changeTriggers) && changeTriggers.length && formSchema) {
        resolveUiTriggersCode(changeTriggers).then((triggers) =>
          executeUiTriggers<OnAfterFormChangeExecutionContext>(
            {
              event: TypeTrigger.OnAfterFormChange,
              schema: formSchema,
              page: page,
              showMessage: itemInfoContext?.showMessage,
              dismissMessage: itemInfoContext?.dismissMessage,
              isCreate: itemInfoContext?.type === 'add',
              isUpdate: itemInfoContext?.type === 'edit',
              get formData(): IDataItem | undefined {
                return cloneDeepWithMetadata(currentFormData.current);
              },
              previousFormData: previousCurrentFormData.current,
              setFormData: (newFormData: IDataItem) => {
                currentFormData.current = newFormData;
                forceUpdate();
              },
              overrideFormSchema,
            },
            triggers
          )
        );
      }

      formEventEmitter.emit('change', currentFormData.current);

      previousCurrentFormData.current = currentFormData.current;
    },
    [forceUpdate, formEventEmitter, formSchema, itemInfoContext, onChange, page, overrideFormSchema]
  );

  const disableSubmitForLongActions = useCallback(() => {
    if (submitDisableTimeout.current === null) {
      submitDisableTimeout.current = timeoutManager.setTimeout(() => {
        submitDisableTimeout.current = null;
        if (readyToSubmit.current.ready) {
          console.warn(`Form is not ready to submit, disabling submit button. Background actions: ${actionsCounter.current}.`);
          disableSubmit();
        }
      }, 1000);
    }
  }, [disableSubmit, timeoutManager]);

  const onFormSubmitAs = useCallback(
    (formData: unknown) => {
      if (onSubmitAs && formData) {
        onSubmitAs({ formData });
      }
      enableSubmit();
    },
    [enableSubmit, onSubmitAs]
  );

  const onFormSubmit = useCallback(
    async (event: ISubmitEvent<{}> | null, formEvent: React.FormEvent<HTMLFormElement>) => {
      // Trigger image upload/conversion from base64 to cdn url in TinyMCE instances
      if (editorInstances.current) {
        for (const editorInstance of editorInstances.current) {
          await editorInstance.editor?.uploadImages();
        }
      }

      // We need to let react update in order to receive change event from rjsf
      // https://github.com/rjsf-team/react-jsonschema-form/blob/c78a1c9de2fb1913fd1052fc9e0e879a76aa8225/packages/core/src/components/Form.tsx#L594
      await sleep(0);

      const type = (formEvent.nativeEvent as Event & { detail?: string }).detail;
      const formData = currentFormData.current;

      if (!formData) return;

      switch (type) {
        case SubmitType.AsCopy: {
          onFormSubmitAs(formData);
          break;
        }
        case SubmitType.WithoutClose: {
          onSubmit?.({ formData: formData }, false);
          break;
        }
        default: {
          onSubmit?.({ formData: formData });
        }
      }

      enableSubmit();
    },
    [enableSubmit, onFormSubmitAs, onSubmit]
  );

  const initiateFormSubmit = useCallback(
    async (event?: unknown, type?: SubmitType) => {
      (event as SyntheticEvent)?.preventDefault?.();
      (event as SyntheticEvent)?.stopPropagation?.();

      console.info(`Form submit initiated. Background actions: ${actionsCounter.current}.`);
      if (actionsCounter.current > 0) {
        readyToSubmit.current = { ready: true, submitFunction: initiateFormSubmit };

        // Disable submit if trigger takes too much time
        disableSubmitForLongActions();
        return;
      }

      if (editFormsAsJson) {
        await onFormSubmit(null, event as React.FormEvent<HTMLFormElement>);
      } else {
        const cancelSubmit = (await onBeforeSubmit?.({ formData: currentFormData.current }))?.cancelSubmit ?? false;
        if (!cancelSubmit) {
          // https://github.com/rjsf-team/react-jsonschema-form/issues/2104#issuecomment-848399222
          (formRef?.current as any)?.formElement.dispatchEvent(
            new CustomEvent('submit', {
              detail: type,
              cancelable: true,
              bubbles: true,
            })
          );
        }
      }
    },
    [disableSubmitForLongActions, onBeforeSubmit, onFormSubmit, editFormsAsJson]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (enableKeyboardHotkeys && (event.ctrlKey || event.metaKey) && event.code === 'KeyS') {
        initiateFormSubmit(undefined, SubmitType.WithoutClose);
        event.preventDefault();
      }
    };
    window.addEventListener('keydown', handleKeyDown, true);

    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [enableKeyboardHotkeys, initiateFormSubmit]);

  const startAction = useCallback(() => {
    actionsCounter.current++;
    console.info(`Started form background action. Current count: ${actionsCounter.current}.`);
  }, []);
  const finishAction = useCallback(async () => {
    actionsCounter.current--;
    console.info(`Finished form background action. Current count: ${actionsCounter.current}.`);

    if (actionsCounter.current === 0) {
      if (readyToSubmit.current.ready) {
        // Ready to submit
        readyToSubmit.current.ready = false;
        // Submit
        console.info(`Form background actions are finished, initiating form submit...`);

        // We need this in order to skip one tick and allow change events to be handled
        // It fixed file upload + submit
        await sleep(0);
        readyToSubmit.current.submitFunction?.();
        readyToSubmit.current.submitFunction = undefined;
      }
    }
  }, []);

  const editorInstances = useRef(new Set<Editor>());

  const registerEditor = useCallback(
    (editor: Editor) => {
      if (editorInstances.current) {
        editorInstances.current.add(editor);
      }
    },
    [editorInstances]
  );

  const unregisterEditor = useCallback(
    (editor: Editor) => {
      if (editorInstances.current) {
        editorInstances.current.delete(editor);
      }
    },
    [editorInstances]
  );

  const handleFieldLocalizationChanged = useCallback(
    (_eTag: string, language: string, dataPath: string, updatedValue: IDataItem) => {
      onFieldLocalizationChanged?.(_eTag, language, dataPath, updatedValue);

      const updatedFormData = cloneDeepWithMetadata({ ...currentFormData.current, _eTag: _eTag });

      if (currentLanguage === language) {
        const path = _.toPath(dataPath);
        const fieldName = path.slice(-1)[0];

        _.set(updatedFormData, path, updatedValue[fieldName]);
      }

      onChangeHandler({
        formData: updatedFormData,
      });
    },
    [onFieldLocalizationChanged, onChangeHandler, currentLanguage]
  );

  const sharePayload = useCallback(async () => {
    const formDataToShare = cloneValueAndCleanUpInternalProperties(getCurrentFormState?.() || {}, schema);
    delete formDataToShare['identifier'];

    onCopiedToClipboard?.(formDataToShare);
  }, [onCopiedToClipboard, getCurrentFormState, schema]);

  const menuProps: IContextualMenuProps = useMemo(() => {
    const touchDevice: boolean = isTouchDevice();
    const items: IContextualMenuProps['items'] = [];

    if (itemInfoContext?.type === 'edit') {
      items.push({
        key: 'saveAsCopy',
        text: t('common.submitAsCopy'),
        onClick: (): void => {
          initiateFormSubmit(undefined, SubmitType.AsCopy);
        },
      });
    }

    items.push({
      key: 'saveWithoutClose',
      text: t('common.submitWithoutClose'),
      onClick: (): void => {
        initiateFormSubmit(undefined, SubmitType.WithoutClose);
      },
    });

    if (onCopiedToClipboard) {
      items.push({
        key: 'share',
        text: t('common.share'),
        onClick: (): void => {
          sharePayload();
        },
      });
    }

    if (touchDevice) {
      items.unshift({
        key: 'submit',
        text: btnText || t('common.submit'),
        onClick: (): void => {
          initiateFormSubmit();
        },
      });
    }

    return { items };
  }, [itemInfoContext?.type, t, onCopiedToClipboard, sharePayload, btnText, initiateFormSubmit]);

  const submitButtonStyle = useMemo(() => ({ display: hideSubmit ? 'none' : undefined }), [hideSubmit]);

  const submitButton = useMemo(() => {
    return showSaveUrl && powerUser ? (
      <PrimaryButton
        data-testid="Form__submitButton"
        form={id}
        type="submit"
        split={true}
        style={submitButtonStyle}
        className={styles.btnSplit}
        as="label"
        htmlFor={id}
        menuProps={menuProps}
        onClick={initiateFormSubmit}
        text={btnText || t('common.submit')}
        disabled={readonly || !onSubmit || submitDisabled}
      />
    ) : (
      <PrimaryButton
        data-testid="Form__submitButton"
        form={id}
        type="submit"
        style={submitButtonStyle}
        as="label"
        htmlFor={id}
        onClick={initiateFormSubmit}
        text={btnText || t('common.submit')}
        disabled={readonly || !onSubmit || submitDisabled}
      />
    );
  }, [powerUser, showSaveUrl, id, submitButtonStyle, menuProps, initiateFormSubmit, btnText, t, readonly, onSubmit, submitDisabled]);

  const buttonsContainer = useMemo(() => {
    return (
      <div className={styles.buttonsContainer}>
        {submitButton}

        {customButtons}
      </div>
    );
  }, [submitButton, customButtons]);

  const onFormBlur = useCallback(
    async (fieldId: string, value: unknown, customOptions: IBlurOptions = {}) => {
      if (!formSchema) {
        return;
      }

      // getting array of path names
      let fieldNamePath = (fieldId.startsWith('root_') ? fieldId.slice(5) : fieldId).split('_');

      // checking if it is relation field
      if (fieldNamePath.length > 1 && fieldNamePath.lastIndexOf('identifier') === fieldNamePath.length - 1) {
        fieldNamePath = fieldNamePath.slice(0, fieldNamePath.length - 1);
      }

      if (!customOptions.schema) {
        console.debug(`No subschema found for id: ${fieldId}`, formSchema);
        return;
      }

      // Get triggers from current schema
      const triggers: string[] = [];
      const fieldTriggers: string[] | undefined = customOptions.schema?.cp_fieldTriggers?.[FieldTrigger.OnInputBlur];
      if (fieldTriggers) {
        triggers.push(...fieldTriggers);
      }

      // If field is relation (field path ends with 'identifier') also check upper level schema for triggers
      if (customOptions.path?.endsWith(`['identifier']`)) {
        // Get schema from upper level
        let parentSchema: IJSONSchema | undefined = formSchema;
        for (const fieldNamePathPart of fieldNamePath) {
          parentSchema = parentSchema?.properties?.[fieldNamePathPart];
        }
        // If parent schema found add parent schema triggers
        if (parentSchema) {
          const parentTriggers: string[] | undefined = parentSchema?.cp_fieldTriggers?.[FieldTrigger.OnInputBlur];
          if (parentTriggers) {
            triggers.push(...parentTriggers);
          }
        }
      }

      // Execute 'OnInputBlur' triggers
      if (Array.isArray(triggers) && triggers.length > 0) {
        startAction();
        try {
          await executeUiTriggers<OnInputBlurExecutionContext>(
            {
              event: FieldTrigger.OnInputBlur,
              showMessage: itemInfoContext?.showMessage,
              dismissMessage: itemInfoContext?.dismissMessage,
              fieldValue: value,
              fieldItem: customOptions.fieldItem,
              fieldSchema: customOptions.schema,
              fieldPath: customOptions.path,
              fieldId: fieldId,
              fieldName: fieldNamePath[fieldNamePath.length - 1],
              schema: formSchema,
              page: page,
              initialFormData: formData,
              rootFormData: currentFormData.current,
              oldFieldValue: customOptions.oldFieldValue,
              isCreate: itemInfoContext?.type === 'add',
              isUpdate: itemInfoContext?.type === 'edit',
              get formData(): IDataItem | undefined {
                return cloneDeepWithMetadata(currentFormData.current);
              },
              setFormData: (newFormData: IDataItem) => {
                currentFormData.current = newFormData;
                forceUpdate();
              },
              overrideFormSchema,
            },
            await resolveUiTriggersCode(triggers, TriggerType.Field)
          );
        } finally {
          finishAction();
        }
      }
    },
    [
      formSchema,
      startAction,
      itemInfoContext?.showMessage,
      itemInfoContext?.dismissMessage,
      itemInfoContext?.type,
      page,
      formData,
      finishAction,
      forceUpdate,
      overrideFormSchema,
    ]
  );

  const [
    steppedSchema,
    steps,
    step,
    wizardArrayCursor,
    stepsCount,
    visibleStepsCount,
    visibleStep,
    progress,
    question,
    validationErrors,
    nextButtonDisabled,
    handleAddMore,
    handleNext,
    handleStepNext,
    handleStepBack,
  ] = useWizard(schema, formSchema, currentFormData, wizardOptions?.isWizardMode);

  const uiSchema: UiSchema | undefined = useMemo(() => {
    if (!steppedSchema) {
      return;
    }
    const uiSchema = makeUiSchema(steppedSchema);
    if (_.isEmpty(uiSchema)) {
      return;
    }

    return uiSchema;
  }, [steppedSchema]);

  const formContextValue = useMemo(
    () => ({
      startAction: startAction,
      finishAction: finishAction,
      registerEditor: registerEditor,
      unregisterEditor: unregisterEditor,
      onFieldLocalizationChanged: handleFieldLocalizationChanged,
      page,
      formEventEmitter,
      currentFormData,
      initialFormData: formData,
      isWizard: steps.length ? steps[step]?.type === StepTypes.Guided : false,
      wizardArrayMeta: wizardArrayCursor.current,
    }),
    [startAction, finishAction, registerEditor, unregisterEditor, handleFieldLocalizationChanged, page, formEventEmitter, formData, steps, step]
  );

  if (!steppedSchema) {
    return <LoadingArea />;
  }

  return (
    <ErrorBoundary>
      <FormContext.Provider value={formContextValue}>
        <SchemaOverwritesContext.Provider value={useSchemaOverwritesStore}>
          <div className={classNames(styles.form, { [styles.formWithJson]: editFormsAsJson })}>
            {editFormsAsJson && (
              <Suspense fallback={loadingPlaceholder}>
                <JsonFormEditor formData={currentFormData.current} onChange={onChangeHandler}>
                  {buttonsContainer}
                </JsonFormEditor>
              </Suspense>
            )}
            {wizardOptions?.isWizardMode && stepsCount !== 1 ? (
              <Sticky stickyPosition={StickyPositionType.Header}>
                <MessageBars messages={validationErrors || []} messageBarType={MessageBarType.error}></MessageBars>
                <div className={styles.progressWrapper}>
                  <div>{t('common.stepN', { step: visibleStep + 1, stepsCount: visibleStepsCount })}</div>
                  <div className={styles.progress}>
                    <div style={{ width: `${progress}%` }}></div>
                  </div>
                </div>
              </Sticky>
            ) : null}
            {wizardOptions?.isWizardMode && steps[step]?.type !== StepTypes.Repeat && (steps[step] as GuidedStep | RegularStep)?.description ? (
              <p className={styles.stepDescription}>{(steps[step] as GuidedStep | RegularStep)?.description}</p>
            ) : null}
            <div className={classNames({ [styles.formEditorHidden]: editFormsAsJson })}>
              <div
                className={classNames(styles.stepQuestion, {
                  [styles.hidden]: !wizardOptions?.isWizardMode || (wizardOptions?.isWizardMode && !question),
                })}
              >
                <div className={styles.question}>{question?.question}</div>
                <div className={styles.buttonsWrapper}>
                  <div className={classNames(styles.navigationButtons)}>
                    {nextButtonDisabled ? null : <DefaultButton onClick={handleNext}>{t('common.no')}</DefaultButton>}
                    <DefaultButton onClick={handleAddMore}>{t('common.yes')}</DefaultButton>
                  </div>
                  {wizardOptions?.isWizardMode && !nextButtonDisabled ? null : (
                    <div data-testid="Form__buttonContainer" className={classNames(styles.buttonsContainerWrapper, { [styles.dark]: darkMode })}>
                      {buttonsContainer}
                    </div>
                  )}
                </div>
              </div>
              <div className={classNames({ [styles.hidden]: wizardOptions?.isWizardMode && question })}>
                <JsonSchemaForm
                  formRef={formRef}
                  id={id}
                  schema={steppedSchema as IJSONSchema}
                  uiSchema={uiSchema}
                  onChange={onChangeHandler}
                  onBlur={onFormBlur}
                  onSubmit={(e, formEvent) => onFormSubmit(e, formEvent)}
                  formData={currentFormData.current}
                  noHtml5Validate={true}
                  {...theme}
                >
                  {disableStickySubmitButton ? (
                    <div className={classNames(styles.buttonsContainerWrapper, { [styles.dark]: darkMode })}>{buttonsContainer}</div>
                  ) : (
                    <Sticky stickyPosition={StickyPositionType.Footer} stickyBackgroundColor="transparent">
                      <div
                        data-testid="Form__buttonsWrapper"
                        className={classNames(styles.buttonsWrapper, { [styles.submitOnly]: !wizardOptions?.isWizardMode })}
                      >
                        {wizardOptions?.isWizardMode ? (
                          <div className={classNames(styles.navigationButtons, { [styles.buttonsHidden]: stepsCount === 1 })}>
                            <DefaultButton disabled={step === 0} onClick={handleStepBack}>
                              {t('common.previous')}
                            </DefaultButton>
                            <DefaultButton disabled={nextButtonDisabled} onClick={handleStepNext}>
                              {t('common.next')}
                            </DefaultButton>
                          </div>
                        ) : null}
                        {wizardOptions?.isWizardMode && !nextButtonDisabled ? null : (
                          <div
                            data-testid="Form__buttonContainer"
                            className={classNames(styles.buttonsContainerWrapper, { [styles.dark]: darkMode })}
                          >
                            {buttonsContainer}
                          </div>
                        )}
                      </div>
                    </Sticky>
                  )}
                </JsonSchemaForm>
              </div>
            </div>
          </div>
        </SchemaOverwritesContext.Provider>
      </FormContext.Provider>
    </ErrorBoundary>
  );
};

export default Form;
