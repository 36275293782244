export const DataServiceModules = {
    DATA_STORE: 'data-store',
    SHARE: 'share',
    SITEMAP: 'sitemap',
};
export const environmentToCpaConfigurationMap = {
    local: 'localConfiguration',
    dev: 'developmentConfiguration',
    staging: 'stagingConfiguration',
    prod: 'productionConfiguration',
};
