import {IJSONSchema} from '@cp/base-types';
import * as _ from 'lodash';
import {getMatchingEnum} from '@cpa/base-core/helpers';
import {cloneDeepWithMetadata, isRelationSchema} from '@cp/base-utils';
import {utils} from '@rjsf/core';
import {IDataItem} from '@cpa/base-core/types';
import flatten from 'flat';
import React from 'react';
import {i18n} from '@cpa/base-core/app';

export const makeUiSchema = (schema: IJSONSchema): object => {
  const transformer =
    (path: string = '') =>
    (acc: any, value: unknown, key: string | number): any => {
      const currentPath = path ? `${path}.${key}` : key.toString();

      if (key === 'cp_rjsfUiSchema') {
        _.merge(acc, value);
        return acc;
      }

      // If object
      if (_.isObject(value)) {
        const transformedObject = _.transform(
          (_.isArray(value) ? Object.assign({}, value) : value) as _.Dictionary<unknown>,
          transformer(currentPath)
        );
        if (Object.keys(transformedObject).length === 0) {
          return acc;
        }

        const appendLevelToUiSchema = _.toPath(path.toString()).pop() === 'properties';
        if (appendLevelToUiSchema) {
          acc[key] = transformedObject;
        } else {
          _.merge(acc, transformedObject);
        }
        return acc;
      }

      return acc;
    };

  return _.transform(schema, transformer());
};

export const compareSchemaSortOrder = (aSchema?: IJSONSchema, bSchema?: IJSONSchema): number => {
  const aSortOrder = aSchema?.cp_ui?.sortOrderForm || Infinity;
  const bSortOrder = bSchema?.cp_ui?.sortOrderForm || Infinity;
  return aSortOrder < bSortOrder ? -1 : aSortOrder > bSortOrder ? 1 : 0;
};
