import { IDataItem, IGenericComponentProps, ISingleItemTemplateProps } from '@cpa/base-core/types';
import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Schemas } from '@cp/base-types';
import { useTranslation } from 'react-i18next';
import { PanelType } from '@fluentui/react';
import urlJoin from 'url-join';
import { getAppBasePath } from '@cpa/base-core/helpers';

import ScrollablePaneContextProvider from '../../../../../components/ScrollablePaneContextProvider/ScrollablePaneContextProvider';
import GenericScreen from '../../../../../screens/GenericScreen/GenericScreen';
import Drawer from '../../../../../components/Drawer/Drawer';
import SolutionSingleItemTemplate from '../../../SolutionSingleItemTemplate/SolutionSingleItemTemplate';

import styles from './RightSide.module.scss';

const RightSide: React.FC<ISingleItemTemplateProps & { item: Schemas.Solution }> = ({ page: solutionsPage, schema: solutionsSchema }) => {
  const [t] = useTranslation();
  const rightSidePage = useMemo(() => {
    return {
      ...solutionsPage,
      allowCreate: false,
      singleItemTemplate: undefined,
      staticContent: undefined,
      description: undefined,
      actions: [],
    } as Schemas.CpaPage;
  }, [solutionsPage]);

  const [activeSolution, setActiveSolution] = useState<Schemas.Solution>();
  const handleItemOpen = useCallback((item: IDataItem) => setActiveSolution(item as Schemas.Solution), []);
  const handleDrawerClose = useCallback(() => setActiveSolution(undefined), []);

  const genericComponentProps = useMemo<Partial<IGenericComponentProps>>(
    () => ({
      hideSettings: true,
      hideMoreButton: true,
      showFilterInWidget: true,
      scrollingContentProps: {
        disableItemSharing: true,
        widgetHeight: '42vh',
        customActions: [
          {
            button: {
              key: 'view',
              icon: 'ViewOriginal',
              title: t('common.view'),
              url: (selectedItems) => {
                return solutionsPage.path && selectedItems[0].identifier
                  ? urlJoin(getAppBasePath(), solutionsPage.path.replace('/:id', ''), encodeURIComponent(selectedItems[0].identifier))
                  : '';
              },
              condition: (selectedItems) => selectedItems.length === 1,
            },
            onClick: (items, event) => {
              event?.preventDefault();
              if (!items?.length) {
                return;
              }
              handleItemOpen(items[0]);
            },
          },
        ],
        tableProps: {
          onItemOpen: (item) => {
            handleItemOpen(item);
          },
        },
      },
    }),
    [t, solutionsPage.path, handleItemOpen]
  );

  return (
    <>
      <div className={classNames(styles.wrapper)}>
        <GenericScreen
          page={rightSidePage}
          isWidget={true}
          withoutAnimation={true}
          resetFilterOnRefresh={false}
          externalSchema={solutionsSchema}
          genericComponentProps={genericComponentProps}
          showEmptyMessage={true}
        />
      </div>
      <Drawer isOpen={!!activeSolution} onClose={handleDrawerClose} isBlocking={false} type={PanelType.customNear}>
        {!!activeSolution && (
          <ScrollablePaneContextProvider>
            <SolutionSingleItemTemplate
              // @ts-ignore
              page={rightSidePage}
              item={activeSolution as IDataItem & Schemas.Solution}
              schema={solutionsSchema}
              subPages={[]}
              subPageFilter={{}}
              subPagePrefill={{}}
              disableSidePages={true}
              hideCover={true}
            />
          </ScrollablePaneContextProvider>
        )}
      </Drawer>
    </>
  );
};

export default RightSide;
