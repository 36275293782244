const Schema = {
    '$schema': 'http://json-schema.org/draft-07/schema',
    '$id': 'http://platform.cosmoconsult.com/ontology/infrastructure/Cluster',
    'type': 'object',
    'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Finfrastructure%2FCluster' }],
    'cp_handledByApiGateway': false,
    'cp_disableTrash': false,
    'cp_disableLocalization': true,
    'cp_disableVersions': true,
    'cp_disableClone': false,
    'cp_disableLazyLoading': false,
    'patternProperties': { '^[_@].*$': {} },
    'additionalProperties': false,
    'title': 'Cluster',
    'properties': {
        'email': {
            'title': 'Administrator Email',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 35 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/email', 'rel': 'canonical' }],
        },
        'azure': {
            'title': 'Azure',
            '$id': 'http://platform.cosmoconsult.com/ontology/infrastructure/Azure',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Finfrastructure%2FAzure' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/azure', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'location': {
                    'title': 'Location',
                    'description': '<div><a href="https://gist.github.com/ausfestivus/04e55c7d80229069bf3bc75870630ec8" target="_blank" rel="noopener">Azure region</a> where infrastructure will be deployed</div>',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 7 },
                    'links': [{ 'href': 'http://schema.org/location', 'rel': 'canonical' }],
                },
                'subscriptionId': {
                    'title': 'Subscription Id',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 8 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/subscriptionId', 'rel': 'canonical' }],
                },
                'user': {
                    'title': 'Username',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 10 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/user', 'rel': 'canonical' }],
                },
                'tenantId': {
                    'title': 'Tenant Id',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 9 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/tenantId', 'rel': 'canonical' }],
                },
                'password': {
                    'title': 'Password',
                    'type': 'string',
                    'format': 'password',
                    'cp_ui': { 'sortOrderForm': 13 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/password', 'rel': 'canonical' }],
                },
            },
            'required': ['location', 'subscriptionId', 'user', 'tenantId', 'password'],
            'cp_ui': { 'hiddenInTable': true, 'sortOrderForm': 36 },
        },
        'status': {
            'title': 'Status',
            '$id': 'http://platform.cosmoconsult.com/ontology/infrastructure/Status',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Finfrastructure%2FStatus' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/status', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'endDate': {
                    'title': 'End Date',
                    'description': 'The end date of a thing',
                    'type': 'string',
                    'format': 'date-time',
                    'readOnly': true,
                    'cp_ui': { 'sortOrderForm': 13 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/endDate', 'rel': 'canonical' }],
                },
                'startDate': {
                    'title': 'Start Date',
                    'description': 'The start date of a thing',
                    'type': 'string',
                    'format': 'date-time',
                    'readOnly': true,
                    'cp_ui': { 'sortOrderForm': 12 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/startDate', 'rel': 'canonical' }],
                },
                'isRunning': {
                    'title': 'Is Running',
                    'type': 'boolean',
                    'readOnly': true,
                    'cp_ui': { 'sortOrderForm': 7 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/isRunning', 'rel': 'canonical' }],
                },
                'operation': {
                    'title': 'Operation',
                    '$id': 'http://platform.cosmoconsult.com/ontology/infrastructure/Operation',
                    'type': 'string',
                    'links': [
                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Finfrastructure%2FOperation' },
                        { 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/operation', 'rel': 'canonical' },
                    ],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'enum': [
                        'http://platform.cosmoconsult.com/ontology/infrastructure/Up',
                        'http://platform.cosmoconsult.com/ontology/infrastructure/Preview',
                        'http://platform.cosmoconsult.com/ontology/infrastructure/Destroy',
                        'http://platform.cosmoconsult.com/ontology/infrastructure/Refresh',
                    ],
                    'enumNames': ['Up', 'Preview', 'Destroy', 'Refresh'],
                    'readOnly': true,
                    'cp_ui': { 'sortOrderForm': 8 },
                },
                'output': {
                    'title': 'Output',
                    'type': 'string',
                    'readOnly': true,
                    'format': 'cp:multiline',
                    'cp_ui': { 'sortOrderForm': 9 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/output', 'rel': 'canonical' }],
                },
                'exports': {
                    'title': 'Exports',
                    '$id': 'http://platform.cosmoconsult.com/ontology/AnyThing',
                    'type': 'object',
                    'links': [
                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FAnyThing' },
                        { 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/exports', 'rel': 'canonical' },
                    ],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': true,
                    'properties': {
                        'name': {
                            'title': 'Name (Title or Designation)',
                            'description': 'The name of the thing or subject.',
                            'type': 'string',
                            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
                        },
                    },
                    'cp_ui': { 'hiddenInForm': true, 'sortOrderForm': 10 },
                },
                'state': {
                    'title': 'State',
                    'type': 'string',
                    'cp_ui': { 'hiddenInForm': true, 'sortOrderForm': 11 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/state', 'rel': 'canonical' }],
                },
            },
            'readOnly': true,
            'cp_ui': { 'hiddenInTable': true, 'sortOrderForm': 40 },
        },
        'kubernetes': {
            'title': 'Kubernetes',
            '$id': 'http://platform.cosmoconsult.com/ontology/infrastructure/Kubernetes',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Finfrastructure%2FKubernetes' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/kubernetes', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'containerRegistry': {
                    'title': 'Container Registry',
                    'description': '<div>Identifier of container registry used to pull images</div>',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 5 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/containerRegistry', 'rel': 'canonical' }],
                },
                'systemNodePool': {
                    'title': 'System Node Pool',
                    '$id': 'http://platform.cosmoconsult.com/ontology/infrastructure/NodePool',
                    'type': 'object',
                    'links': [
                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Finfrastructure%2FNodePool' },
                        { 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/systemNodePool', 'rel': 'canonical' },
                    ],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'properties': {
                        'minCount': {
                            'title': 'Min Count',
                            'type': 'number',
                            'default': 1,
                            'cp_ui': { 'sortOrderForm': 5 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/minCount', 'rel': 'canonical' }],
                        },
                        'maxCount': {
                            'title': 'Max Count',
                            'type': 'number',
                            'default': 1,
                            'cp_ui': { 'sortOrderForm': 6 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/maxCount', 'rel': 'canonical' }],
                        },
                        'vmSize': {
                            'title': 'Vm Size',
                            'description': '<div>System <a href="https://cloudprice.net/?currency=EUR&amp;region=westeurope&amp;timeoption=month" target="_blank" rel="noopener">node size</a></div>',
                            'type': 'string',
                            'default': 'Standard_B2als_v2',
                            'cp_ui': { 'sortOrderForm': 4 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/vmSize', 'rel': 'canonical' }],
                        },
                        'swapSizeMB': {
                            'title': 'Swap',
                            'description': '<div>\n<div>Amount of swap provisioned in MB</div>\n<div>(Optional, might be a good idea for memory heavy tasks)</div>\n</div>',
                            'type': 'number',
                            'cp_ui': { 'sortOrderForm': 7 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/swapSizeMB', 'rel': 'canonical' }],
                        },
                    },
                    'required': ['minCount', 'maxCount', 'vmSize'],
                    'cp_ui': { 'sortOrderForm': 8 },
                },
                'applicationNodePool': {
                    'title': 'Application Node Pool',
                    '$id': 'http://platform.cosmoconsult.com/ontology/infrastructure/NodePool',
                    'type': 'object',
                    'links': [
                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Finfrastructure%2FNodePool' },
                        { 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/applicationNodePool', 'rel': 'canonical' },
                    ],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'properties': {
                        'minCount': {
                            'title': 'Min Count',
                            'type': 'number',
                            'default': 1,
                            'cp_ui': { 'sortOrderForm': 6 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/minCount', 'rel': 'canonical' }],
                        },
                        'maxCount': {
                            'title': 'Max Count',
                            'type': 'number',
                            'default': 2,
                            'cp_ui': { 'sortOrderForm': 7 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/maxCount', 'rel': 'canonical' }],
                        },
                        'vmSize': {
                            'title': 'Vm Size',
                            'description': '<div>Application <a href="https://cloudprice.net/?currency=EUR&amp;region=westeurope&amp;timeoption=month" target="_blank" rel="noopener">node size</a></div>',
                            'type': 'string',
                            'default': 'Standard_D4as_v5',
                            'cp_ui': { 'sortOrderForm': 5 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/vmSize', 'rel': 'canonical' }],
                        },
                        'swapSizeMB': {
                            'title': 'Swap',
                            'description': '<div>Amount of swap provisioned in MB</div>\n<div>(Optional, might be a good idea for memory heavy tasks)</div>',
                            'type': 'number',
                            'cp_ui': { 'sortOrderForm': 8 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/swapSizeMB', 'rel': 'canonical' }],
                        },
                        'availabilityZones': {
                            'title': 'Availability Zones',
                            'type': 'array',
                            'items': { 'type': 'number' },
                            'minItems': 1,
                            'cp_ui': { 'sortOrderForm': 9 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/availabilityZone', 'rel': 'canonical' }],
                        },
                    },
                    'required': ['minCount', 'maxCount', 'vmSize', 'availabilityZones'],
                    'cp_ui': { 'sortOrderForm': 9 },
                },
                'clusterTier': {
                    'title': 'Cluster Tier',
                    '$id': 'http://platform.cosmoconsult.com/ontology/infrastructure/ClusterTier',
                    'type': 'string',
                    'links': [
                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Finfrastructure%2FClusterTier' },
                        { 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/clusterTier', 'rel': 'canonical' },
                    ],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'enum': [
                        'http://platform.cosmoconsult.com/ontology/infrastructure/Premium',
                        'http://platform.cosmoconsult.com/ontology/infrastructure/Standard',
                        'http://platform.cosmoconsult.com/ontology/infrastructure/Free',
                    ],
                    'enumNames': ['Premium', 'Standard', 'Free'],
                    'cp_ui': { 'sortOrderForm': 6 },
                },
                'ingressPodsCount': {
                    'title': 'Ingress Pods Count',
                    'type': 'number',
                    'cp_ui': { 'sortOrderForm': 7 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/ingressPodsCount', 'rel': 'canonical' }],
                },
            },
            'required': ['containerRegistry', 'ingressPodsCount', 'clusterTier', 'systemNodePool', 'applicationNodePool'],
            'cp_ui': { 'sortOrderForm': 37 },
        },
        'customerInstance': {
            'title': 'Administration Instance',
            'description': '<div>Used to manage customer instances inside the cluster</div>',
            '$id': 'http://platform.cosmoconsult.com/ontology/infrastructure/CustomerInstance',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Finfrastructure%2FCustomerInstance' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/customerInstance', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'mailTransferSettings': {
                    'title': 'Mail Transfer Settings',
                    '$id': 'http://platform.cosmoconsult.com/ontology/MailTransferSettings',
                    'type': 'object',
                    'links': [
                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FMailTransferSettings' },
                        { 'href': 'http://platform.cosmoconsult.com/ontology/mailTransferSettings', 'rel': 'canonical' },
                    ],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'properties': {
                        'sender': {
                            'title': 'Sender',
                            'description': 'A sub property of participant. The participant who is at the sending end of the action.',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 36 },
                            'links': [{ 'href': 'http://schema.org/sender', 'rel': 'canonical' }],
                        },
                        'user': {
                            'title': 'User',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 34 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/user', 'rel': 'canonical' }],
                        },
                        'host': {
                            'title': 'Host',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 33 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/host', 'rel': 'canonical' }],
                        },
                        'password': {
                            'title': 'Password',
                            'type': 'string',
                            'format': 'password',
                            'cp_ui': { 'sortOrderForm': 35 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/password', 'rel': 'canonical' }],
                        },
                        'port': {
                            'title': 'Port',
                            'type': 'number',
                            'cp_ui': { 'sortOrderForm': 37 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/port', 'rel': 'canonical' }],
                        },
                        'secure': {
                            'title': 'Secure',
                            'type': 'boolean',
                            'cp_ui': { 'sortOrderForm': 38 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/secure', 'rel': 'canonical' }],
                        },
                    },
                    'required': ['sender', 'user', 'host', 'password', 'port'],
                    'cp_ui': { 'sortOrderForm': 41 },
                },
                'b2bGraph': {
                    'title': 'B2B Graph',
                    'description': '<div>Used to gather user information during authentication</div>',
                    '$id': 'http://platform.cosmoconsult.com/ontology/infrastructure/Azure',
                    'type': 'object',
                    'links': [
                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Finfrastructure%2FAzure' },
                        { 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/b2bGraph', 'rel': 'canonical' },
                    ],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'properties': {
                        'user': {
                            'title': 'User',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 37 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/user', 'rel': 'canonical' }],
                        },
                        'clientId': {
                            'title': 'Client Id',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 35 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/clientId', 'rel': 'canonical' }],
                        },
                        'clientSecret': {
                            'title': 'Client Secret',
                            'type': 'string',
                            'format': 'password',
                            'cp_ui': { 'sortOrderForm': 36 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/clientSecret', 'rel': 'canonical' }],
                        },
                        'password': {
                            'title': 'Password',
                            'type': 'string',
                            'format': 'password',
                            'cp_ui': { 'sortOrderForm': 38 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/password', 'rel': 'canonical' }],
                        },
                        'msalAuthScope': {
                            'title': 'Scope',
                            'type': 'string',
                            'default': 'https://graph.microsoft.com/.default',
                            'cp_ui': { 'sortOrderForm': 39 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/msalAuthScope', 'rel': 'canonical' }],
                        },
                        'url': {
                            'title': 'Endpoint',
                            'description': '<div>Absolute URL of the item, if there is a URL that can be used 1:1 for this item (as a reference).</div>',
                            'type': 'string',
                            'format': 'uri',
                            'default': 'https://graph.microsoft.com/v1.0',
                            'cp_ui': { 'sortOrderForm': 40 },
                            'links': [{ 'href': 'http://schema.org/url', 'rel': 'canonical' }],
                        },
                    },
                    'required': ['user', 'clientId', 'clientSecret', 'password', 'msalAuthScope', 'url'],
                    'cp_ui': { 'sortOrderForm': 38 },
                },
                'idmDiscovery': {
                    'title': 'IDM Discovery',
                    'description': '<div>\n<div>Used to gather user information during authentication</div>\n</div>',
                    '$id': 'http://platform.cosmoconsult.com/ontology/infrastructure/Azure',
                    'type': 'object',
                    'links': [
                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Finfrastructure%2FAzure' },
                        { 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/idmDiscovery', 'rel': 'canonical' },
                    ],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'properties': {
                        'clientId': {
                            'title': 'Client Id',
                            'type': 'string',
                            'cp_ui': { 'sortOrderForm': 35 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/clientId', 'rel': 'canonical' }],
                        },
                        'clientSecret': {
                            'title': 'Client Secret',
                            'type': 'string',
                            'format': 'password',
                            'cp_ui': { 'sortOrderForm': 40 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/clientSecret', 'rel': 'canonical' }],
                        },
                    },
                    'required': ['clientId', 'clientSecret'],
                    'cp_ui': { 'sortOrderForm': 39 },
                },
                'host': {
                    'title': 'Domain (FQDN)',
                    'description': '<div>Administration instance domain, for example - administration.example.com</div>',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 36 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/host', 'rel': 'canonical' }],
                },
                'dnsZoneId': {
                    'title': 'DNS Zone ID',
                    'description': '<div>You can specify existing dns zone for automated records management. Format:</div>\n<div>/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Microsoft.Network/dnszones/{zoneName}</div>',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 37 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/dnsZoneId', 'rel': 'canonical' }],
                },
                'services': {
                    'title': 'Services',
                    'description': '<div>Configuration for services deployed in customer instance</div>',
                    '$id': 'http://platform.cosmoconsult.com/ontology/infrastructure/Services',
                    'type': 'object',
                    'links': [
                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Finfrastructure%2FServices' },
                        { 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/services', 'rel': 'canonical' },
                    ],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'properties': {
                        'dataService': {
                            'title': 'Data Service',
                            '$id': 'http://platform.cosmoconsult.com/ontology/infrastructure/DataService',
                            'type': 'object',
                            'links': [
                                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Finfrastructure%2FDataService' },
                                { 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/dataService', 'rel': 'canonical' },
                            ],
                            'cp_handledByApiGateway': false,
                            'cp_disableTrash': false,
                            'cp_disableLocalization': false,
                            'cp_disableVersions': false,
                            'cp_disableClone': false,
                            'cp_disableLazyLoading': false,
                            'patternProperties': { '^[_@].*$': {} },
                            'additionalProperties': false,
                            'properties': {
                                'version': {
                                    'title': 'Version',
                                    'type': 'string',
                                    'default': 'latest',
                                    'cp_ui': { 'sortOrderForm': 1 },
                                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/version', 'rel': 'canonical' }],
                                },
                            },
                            'required': ['version'],
                            'cp_ui': { 'sortOrderForm': 7 },
                        },
                        'metaService': {
                            'title': 'Meta Service',
                            '$id': 'http://platform.cosmoconsult.com/ontology/infrastructure/MetaService',
                            'type': 'object',
                            'links': [
                                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Finfrastructure%2FMetaService' },
                                { 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/metaService', 'rel': 'canonical' },
                            ],
                            'cp_handledByApiGateway': false,
                            'cp_disableTrash': false,
                            'cp_disableLocalization': false,
                            'cp_disableVersions': false,
                            'cp_disableClone': false,
                            'cp_disableLazyLoading': false,
                            'patternProperties': { '^[_@].*$': {} },
                            'additionalProperties': false,
                            'properties': {
                                'version': {
                                    'title': 'Version',
                                    'type': 'string',
                                    'default': 'latest',
                                    'cp_ui': { 'sortOrderForm': 1 },
                                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/version', 'rel': 'canonical' }],
                                },
                            },
                            'required': ['version'],
                            'cp_ui': { 'sortOrderForm': 8 },
                        },
                        'jobWorkerService': {
                            'title': 'Job Worker Service',
                            '$id': 'http://platform.cosmoconsult.com/ontology/infrastructure/JobWorkerService',
                            'type': 'object',
                            'links': [
                                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Finfrastructure%2FJobWorkerService' },
                                { 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/jobWorkerService', 'rel': 'canonical' },
                            ],
                            'cp_handledByApiGateway': false,
                            'cp_disableTrash': false,
                            'cp_disableLocalization': false,
                            'cp_disableVersions': false,
                            'cp_disableClone': false,
                            'cp_disableLazyLoading': false,
                            'patternProperties': { '^[_@].*$': {} },
                            'additionalProperties': false,
                            'properties': {
                                'version': {
                                    'title': 'Version',
                                    'type': 'string',
                                    'default': 'latest',
                                    'cp_ui': { 'sortOrderForm': 1 },
                                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/version', 'rel': 'canonical' }],
                                },
                            },
                            'required': ['version'],
                            'cp_ui': { 'sortOrderForm': 9 },
                        },
                        'mongodbPublisherService': {
                            'title': 'Mongodb Publisher Service',
                            '$id': 'http://platform.cosmoconsult.com/ontology/infrastructure/MongodbPublisherService',
                            'type': 'object',
                            'links': [
                                {
                                    'rel': 'collection',
                                    'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Finfrastructure%2FMongodbPublisherService',
                                },
                                { 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/mongodbPublisherService', 'rel': 'canonical' },
                            ],
                            'cp_handledByApiGateway': false,
                            'cp_disableTrash': false,
                            'cp_disableLocalization': false,
                            'cp_disableVersions': false,
                            'cp_disableClone': false,
                            'cp_disableLazyLoading': false,
                            'patternProperties': { '^[_@].*$': {} },
                            'additionalProperties': false,
                            'properties': {
                                'version': {
                                    'title': 'Version',
                                    'type': 'string',
                                    'default': 'latest',
                                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/version', 'rel': 'canonical' }],
                                },
                            },
                            'required': ['version'],
                            'cp_ui': { 'sortOrderForm': 10 },
                        },
                        'vectorizationService': {
                            'title': 'Vectorization Service',
                            '$id': 'http://platform.cosmoconsult.com/ontology/infrastructure/VectorizationService',
                            'type': 'object',
                            'links': [
                                {
                                    'rel': 'collection',
                                    'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Finfrastructure%2FVectorizationService',
                                },
                                { 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/vectorizationService', 'rel': 'canonical' },
                            ],
                            'cp_handledByApiGateway': false,
                            'cp_disableTrash': false,
                            'cp_disableLocalization': false,
                            'cp_disableVersions': false,
                            'cp_disableClone': false,
                            'cp_disableLazyLoading': false,
                            'patternProperties': { '^[_@].*$': {} },
                            'additionalProperties': false,
                            'properties': {
                                'version': {
                                    'title': 'Version',
                                    'type': 'string',
                                    'default': 'latest',
                                    'cp_ui': { 'sortOrderForm': 1 },
                                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/version', 'rel': 'canonical' }],
                                },
                            },
                            'required': ['version'],
                            'cp_ui': { 'sortOrderForm': 11 },
                        },
                        'webhookService': {
                            'title': 'Webhook Service',
                            '$id': 'http://platform.cosmoconsult.com/ontology/infrastructure/WebhookService',
                            'type': 'object',
                            'links': [
                                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Finfrastructure%2FWebhookService' },
                                { 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/webhookService', 'rel': 'canonical' },
                            ],
                            'cp_handledByApiGateway': false,
                            'cp_disableTrash': false,
                            'cp_disableLocalization': false,
                            'cp_disableVersions': false,
                            'cp_disableClone': false,
                            'cp_disableLazyLoading': false,
                            'patternProperties': { '^[_@].*$': {} },
                            'additionalProperties': false,
                            'properties': {
                                'version': {
                                    'title': 'Version',
                                    'type': 'string',
                                    'default': 'latest',
                                    'cp_ui': { 'sortOrderForm': 1 },
                                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/version', 'rel': 'canonical' }],
                                },
                            },
                            'required': ['version'],
                            'cp_ui': { 'sortOrderForm': 12 },
                        },
                    },
                    'required': ['dataService', 'metaService', 'jobWorkerService', 'mongodbPublisherService', 'vectorizationService', 'webhookService'],
                    'cp_ui': { 'sortOrderForm': 43 },
                },
                'erp': {
                    'title': 'ERP',
                    'description': '<div>Used to gather user information during authentication</div>',
                    '$id': 'http://platform.cosmoconsult.com/ontology/infrastructure/Azure',
                    'type': 'object',
                    'links': [
                        { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Finfrastructure%2FAzure' },
                        { 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/erp', 'rel': 'canonical' },
                    ],
                    'cp_handledByApiGateway': false,
                    'cp_disableTrash': false,
                    'cp_disableLocalization': false,
                    'cp_disableVersions': false,
                    'cp_disableClone': false,
                    'cp_disableLazyLoading': false,
                    'patternProperties': { '^[_@].*$': {} },
                    'additionalProperties': false,
                    'properties': {
                        'clientSecret': {
                            'title': 'Client Secret',
                            'type': 'string',
                            'format': 'password',
                            'cp_ui': { 'sortOrderForm': 40 },
                            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/clientSecret', 'rel': 'canonical' }],
                        },
                        'url': {
                            'title': 'Endpoint',
                            'description': '<div>Absolute URL of the item, if there is a URL that can be used 1:1 for this item (as a reference).</div>',
                            'type': 'string',
                            'format': 'uri',
                            'cp_ui': { 'sortOrderForm': 35 },
                            'links': [{ 'href': 'http://schema.org/url', 'rel': 'canonical' }],
                        },
                    },
                    'required': ['clientSecret', 'url'],
                    'cp_ui': { 'sortOrderForm': 40 },
                },
                'forceSeeding': {
                    'title': 'Force Seeding',
                    'description': '<div>Runs database seeding</div>',
                    'type': 'boolean',
                    'cp_ui': { 'sortOrderForm': 44 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/forceSeeding', 'rel': 'canonical' }],
                },
                'owners': {
                    'title': 'Owners',
                    'description': '<div>List of customer instance owners, they will be granted with full access</div>',
                    'type': 'array',
                    'items': { 'type': 'string', 'format': 'email', 'transform': ['toLowerCase', 'trim'] },
                    'minItems': 1,
                    'cp_ui': { 'sortOrderForm': 42 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/owner', 'rel': 'canonical' }],
                },
            },
            'required': ['host', 'owners', 'mailTransferSettings', 'b2bGraph', 'idmDiscovery', 'erp', 'services'],
            'cp_ui': { 'hiddenInTable': true, 'sortOrderForm': 39 },
        },
        'database': {
            'title': 'Database',
            '$id': 'http://platform.cosmoconsult.com/ontology/infrastructure/Atlas',
            'type': 'object',
            'links': [
                { 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2Finfrastructure%2FAtlas' },
                { 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/database', 'rel': 'canonical' },
            ],
            'cp_handledByApiGateway': false,
            'cp_disableTrash': false,
            'cp_disableLocalization': false,
            'cp_disableVersions': false,
            'cp_disableClone': false,
            'cp_disableLazyLoading': false,
            'patternProperties': { '^[_@].*$': {} },
            'additionalProperties': false,
            'properties': {
                'addresses': {
                    'title': 'Allowed IPs',
                    'description': '<div>List of developer ips used to connect to database</div>',
                    'type': 'array',
                    'items': { 'type': 'string' },
                    'cp_ui': { 'sortOrderForm': 8 },
                    'links': [{ 'href': 'http://schema.org/address', 'rel': 'canonical' }],
                },
                'location': {
                    'title': 'Atlas Region',
                    'description': '<div>Region where database is location in <a href="https://www.mongodb.com/docs/atlas/reference/microsoft-azure/#std-label-microsoft-azure" target="_blank" rel="noopener">mongodb atlas format</a></div>',
                    'type': 'string',
                    'cp_ui': { 'sortOrderForm': 5 },
                    'links': [{ 'href': 'http://schema.org/location', 'rel': 'canonical' }],
                },
                'atlasPublicKey': {
                    'title': 'Atlas Public Key',
                    'type': 'string',
                    'format': 'password',
                    'cp_ui': { 'sortOrderForm': 6 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/atlasPublicKey', 'rel': 'canonical' }],
                },
                'atlasPrivateKey': {
                    'title': 'Atlas Private Key',
                    'type': 'string',
                    'format': 'password',
                    'cp_ui': { 'sortOrderForm': 7 },
                    'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/infrastructure/atlasPrivateKey', 'rel': 'canonical' }],
                },
            },
            'required': ['location', 'atlasPublicKey', 'atlasPrivateKey'],
            'cp_ui': { 'hiddenInTable': true, 'sortOrderForm': 38 },
        },
        'accessPermissions': {
            'title': 'Access Permissions',
            'type': 'array',
            'items': {
                'anyOf': [
                    {
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetRole',
                        'type': 'object',
                        'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetRole' }],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'title': 'Cp Permission Target Role',
                        'properties': {
                            'role': {
                                'title': 'Role',
                                'type': 'string',
                                'links': [
                                    { 'rel': 'glossary', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionRoleSuggestion' },
                                    { 'href': 'http://platform.cosmoconsult.com/ontology/role', 'rel': 'canonical' },
                                ],
                            },
                            '_type': {
                                'type': 'string',
                                'const': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetRole',
                                'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                            },
                        },
                    },
                    {
                        '$id': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetUser',
                        'type': 'object',
                        'links': [{ 'rel': 'collection', 'href': 'data-store/http%3A%2F%2Fplatform.cosmoconsult.com%2Fontology%2FCpPermissionTargetUser' }],
                        'cp_handledByApiGateway': false,
                        'cp_disableTrash': false,
                        'cp_disableLocalization': false,
                        'cp_disableVersions': false,
                        'cp_disableClone': false,
                        'cp_disableLazyLoading': false,
                        'patternProperties': { '^[_@].*$': {} },
                        'additionalProperties': false,
                        'title': 'Cp Permission Target User',
                        'properties': {
                            'email': {
                                'title': 'Email',
                                'type': 'string',
                                'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/email', 'rel': 'canonical' }],
                            },
                            '_type': {
                                'type': 'string',
                                'const': 'http://platform.cosmoconsult.com/ontology/CpPermissionTargetUser',
                                'cp_ui': { 'hiddenInTable': true, 'hiddenInFilter': true, 'hiddenInForm': true, 'hiddenInReadonlyContent': true },
                            },
                        },
                    },
                ],
            },
            'cp_ui': { 'sortOrderForm': 41 },
            'links': [{ 'href': 'http://platform.cosmoconsult.com/ontology/accessPermission', 'rel': 'canonical' }],
        },
        'identifier': {
            'title': 'Identifier',
            'description': 'The identifier property represents any kind of identifier for any kind of [[Thing]], such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See [background notes](/docs/datamodel.html#identifierBg) for more details.\n        ',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 33 },
            'links': [{ 'href': 'http://schema.org/identifier', 'rel': 'canonical' }],
        },
        'name': {
            'title': 'Name (Title or Designation)',
            'description': 'The name of the thing or subject.',
            'type': 'string',
            'cp_ui': { 'sortOrderForm': 34 },
            'links': [{ 'href': 'http://schema.org/name', 'rel': 'canonical' }],
        },
    },
    'required': ['email', 'name', 'azure', 'database', 'kubernetes', 'customerInstance'],
};
export const ClusterSchema = Schema;
