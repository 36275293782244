import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { IJSONSchema, Schemas } from '@cp/base-types';
import { clearNulls, cloneDeepWithMetadata } from '@cp/base-utils';
import * as _ from 'lodash';
import { IDataItem } from '@cpa/base-core/types';

import Form, { IFormChangeEvent } from '../../../../../../Form';
import styles from '../../TreeView.module.scss';

const modalProps = {
  isBlocking: false,
  focusTrapZoneProps: {
    forceFocusInsideTrap: false,
  },
};

interface IDialogProps {
  schema: IJSONSchema;
  formData: Extract<Schemas.CpType['assignments'], {}>[0] | null;
  onSubmit: (formData: Extract<Schemas.CpType['assignments'], {}>[0] | null) => void;
  onDismiss: () => void;
  isPropertyPathLocked: boolean;
  disableAnnotations?: boolean;
}

const FormDialog: React.FC<IDialogProps> = ({ schema, formData, onSubmit, onDismiss, isPropertyPathLocked }) => {
  const currentFormData = useRef<Extract<Schemas.CpType['assignments'], {}>[0] | undefined>(
    formData ? clearNulls(cloneDeepWithMetadata(formData)) : undefined
  );

  useEffect(() => {
    currentFormData.current = formData ? clearNulls(cloneDeepWithMetadata(formData)) : undefined;
  }, [formData]);

  const modifiedSchema = useMemo(() => {
    const isRootLevelProperty = _.toPath(formData?.propertyJsonPath).length === 1;
    const schemaCopy = cloneDeepWithMetadata(schema);
    if (isPropertyPathLocked) {
      _.set(schemaCopy, 'properties.propertyJsonPath.readonly', true);
    }

    if (!isRootLevelProperty) {
      _.unset(schemaCopy, 'properties.permissions');
    }
    return schemaCopy;
  }, [formData?.propertyJsonPath, isPropertyPathLocked, schema]);

  const onChangeHandler = useCallback(({ formData }: IFormChangeEvent) => {
    currentFormData.current = formData;
  }, []);

  const onSubmitClick = useCallback(() => {
    onSubmit(currentFormData.current || {});
  }, [onSubmit]);

  return (
    <Dialog
      className={styles.dialog}
      dialogContentProps={{ type: DialogType.largeHeader }}
      hidden={false}
      minWidth={'60%'}
      maxWidth={'60%'}
      modalProps={modalProps}
      onDismiss={undefined}
    >
      <div className={styles.formWrapper}>
        <Form schema={modifiedSchema} formData={formData as IDataItem} onChange={onChangeHandler} hideSubmit />
      </div>
      <DialogFooter>
        <PrimaryButton onClick={onSubmitClick} text="Submit" />
        <DefaultButton onClick={onDismiss} text="Cancel" />
      </DialogFooter>
    </Dialog>
  );
};

export default FormDialog;
