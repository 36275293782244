import { Schemas } from '@cp/base-types';
import { Environment } from '@cpa/base-core/app/environment';
import { chunkWidgets } from '@cpa/base-core/helpers';
import { IGlobalState } from '@cpa/base-core/store';
import { IDataItem, IScreenProps } from '@cpa/base-core/types';
import { Icon } from '@fluentui/react';
import classNames from 'classnames';
import Mustache from 'mustache';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import dashboardLogo from '../../assets/dashboard-logo.svg';
import HtmlContent from '../../components/HtmlContent/HtmlContent';
import Kpi from '../../components/Kpi/Kpi';
import Widgets from '../Dashboard/components/Widgets/Widgets';
import KpiView from '../RebrushedDashboard/components/KpiView/KpiView';

import styles from './RebrushedDashboard.module.scss';
import CallToAction from './components/CallToAction/CallToAction';

interface IDashboardProps extends IScreenProps {
  hideStats?: boolean;
  hideTitle?: boolean;
  suspendAnimation?: boolean;
}

const Dashboard: React.FC<IDashboardProps> = ({
  hideStats,
  hideTitle,
  suspendAnimation,
  page: {
    callToActions,
    description,
    iconFull,
    isDashboard,
    path,
    parentCpaPage,
    topVisualImageSmall,
    topVisualImageLarge,
    identifier: dashboardIdentifier,
    staticContent,
  },
}) => {
  const appName = useSelector((state: IGlobalState) => state.app.cpa?.name);
  const cpaLogo = useSelector((state: IGlobalState) => state.app.cpa?.image);
  const [t] = useTranslation();
  const allPagesSettings = useSelector((state: IGlobalState) => state.settings.pages);
  const userName = useSelector((state: IGlobalState) => state.auth.user?.account?.name);
  const user = useSelector((state: IGlobalState) => state.auth.user?.account);
  const desktopOnly = useMediaQuery({ query: '(min-width: 992px)' });
  const allPages = useSelector((state: IGlobalState) => state.app.pages);
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const isHomePage = useMemo(() => {
    return isDashboard && path && !parentCpaPage;
  }, [isDashboard, parentCpaPage, path]);

  const widgetsChunked: Schemas.CpaPage[][] = useMemo(
    () =>
      chunkWidgets(
        allPages.filter((page) => allPagesSettings[page.identifier!]?.showOnDashboards?.includes(dashboardIdentifier!)),
        desktopOnly ? 3 : 1
      ),
    [allPages, desktopOnly, allPagesSettings, dashboardIdentifier]
  );

  const kpis = useMemo(() => {
    return allPages.reduce((acc, page: Schemas.CpaPage) => {
      if (!page.kpis || !page.kpis.length) {
        return acc;
      }
      return [
        ...acc,
        ...page.kpis
          .filter((kpi) => kpi.showOnDashboards?.some(({ identifier }) => identifier === dashboardIdentifier))
          .map((kpi) => ({ ...kpi, page })),
      ];
    }, []);
  }, [allPages, dashboardIdentifier]);

  const [dataUpdate, setDataUpdate] = useState<number>(Date.now());

  const [loadedItemsMap, setLoadedItemsMap] = useState<Record<string, IDataItem[]>>({});

  const backgroundPreview = useMemo(() => {
    if (typeof topVisualImageSmall === 'string') {
      return topVisualImageSmall;
    }

    return topVisualImageSmall?.file || Environment.env.REACT_APP_CPA_HEADER_VISUAL_SMALL!;
  }, [topVisualImageSmall]);

  const background = useMemo(() => {
    if (typeof topVisualImageLarge === 'string') {
      return topVisualImageLarge;
    }

    return topVisualImageLarge?.file || Environment.env.REACT_APP_CPA_HEADER_VISUAL_LARGE!;
  }, [topVisualImageLarge]);

  // Background
  const backgroundRenderer = useMemo(
    () =>
      (src: string, style: { filter: string; transition: string }): JSX.Element =>
        (
          <div
            // Interim: Better to control it by custom CSS setting inside page
            style={{
              backgroundImage: `url(${CSS.escape(src)})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: suspendAnimation ? 'none' : 'center 70%',
              animation: suspendAnimation ? 'none' : 'back-image 20s infinite',
            }}
          ></div>
        ),
    [suspendAnimation]
  );

  const onSnowClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.currentTarget.style.background = `rgba(${Math.floor(Math.random() * 255)},${Math.floor(Math.random() * 255)},${Math.floor(
      Math.random() * 255
    )})`;
  }, []);

  const greeting = useMemo(() => {
    if (description) {
      const view = {
        given_name: user?.given_name,
        family_name: user?.family_name,
        name: user?.name || 'guest',
        email: user?.email,
        cpa_name: appName,
      };
      return <HtmlContent className={styles.greeting} html={Mustache.render(description, view)} />;
    }
    return (
      <div className={styles.greeting}>
        <h1>{userName ? t('dashboard.greetingRebrushed', { userName }) : t('common.greetingsTitle')}</h1>
        <h1>{t('dashboard.subGreetingRebrushed', { appName })}</h1>
      </div>
    );
  }, [appName, description, t, user?.email, user?.family_name, user?.given_name, user?.name, userName]);

  const logo = useMemo(() => {
    if (iconFull) {
      return <Icon className={styles.customIcon} iconName={iconFull} />;
    }
    const image = (cpaLogo as string) || dashboardLogo;
    return <img className={styles.image} src={image} />;
  }, [cpaLogo, iconFull]);

  return (
    <>
      {!hideStats ? (
        <>
          {/*<ProgressiveImage preview={backgroundPreview} src={background} render={backgroundRenderer} initialBlur={20} transitionTime={500} />*/}
          <div className={styles.headerWrapper}>
            {/* > Temporary for Christmas time */}
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            <div className={styles.snow} onClick={onSnowClick}></div>
            {/* < Temporary for Christmas time */}

            <div className={styles.header}>
              {greeting}
              {logo}
            </div>
          </div>
          <HtmlContent html={staticContent} allowScripts={Environment.env.REACT_APP_ALLOW_SCRIPTS_IN_STATIC_CONTENT === 'true'} />
          {!isHomePage || hideTitle ? null : (
            <span className={classNames(styles.title, { [styles.titleDark]: darkMode })}>{t('common.myDashboard')}</span>
          )}
          {kpis.length ? (
            <div className={styles.kpis}>
              {kpis.map((kpi) => {
                return (
                  <Kpi
                    key={kpi.identifier}
                    kpi={kpi}
                    page={kpi.page}
                    lastUpdate={dataUpdate}
                    items={loadedItemsMap[kpi.page.identifier!]}
                    component={KpiView}
                  />
                );
              })}
              {callToActions?.map((callToAction, index) => {
                return <CallToAction key={index} callToAction={callToAction} />;
              })}
            </div>
          ) : null}
        </>
      ) : null}
      <Widgets widgets={widgetsChunked} setLoadedItemsMap={setLoadedItemsMap} setDataUpdate={setDataUpdate} renderShowMoreText />
    </>
  );
};

export default React.memo(Dashboard);
