import { IJSONSchema, TypeConstants } from '@cp/base-types';
import urlJoin from 'url-join';
import { cloneDeepWithMetadata } from '@cp/base-utils';
import flatten from 'flat';
import * as _ from 'lodash';

import { IDataItem } from '../types';
import { axiosDictionary, getEndpoint, getSchema } from '../api';

import { isDefined } from './data/data';

export function ignoreRefs<T extends object = IJSONSchema>(schema: T): T {
  if (!schema || typeof schema !== 'object') {
    return schema;
  }

  if (Array.isArray(schema)) {
    return schema.map((item) => ignoreRefs(item)) as T;
  }

  if ('$ref' in schema) {
    return {} as T;
  }

  return Object.entries(schema).reduce<T>((acc, [key, value]) => {
    if (key === '$ref') {
      return acc;
    }

    return {
      ...acc,
      [key]: ignoreRefs(value),
    };
  }, {} as T);
}

export const findFieldInSchema = (
  schema: IJSONSchema,
  key: string,
  result: {
    value: object | null;
  },
  many: boolean = false
): void => {
  if ((!many && !!result.value) || !schema) {
    return;
  }

  for (const [prop, value] of Object.entries(schema)) {
    if (value) {
      if (prop === key) {
        if (many) {
          result.value = Array.isArray(result.value) ? [...result.value, value] : [value];
        } else {
          result.value = value;
        }
      } else {
        if (value && typeof value === 'object' && !Array.isArray(value)) {
          findFieldInSchema(value, key, result, many);
        } else if (Array.isArray(value)) {
          value.forEach((item) => {
            if (item && typeof item === 'object') {
              findFieldInSchema(item, key, result, many);
            }
          });
        }
      }
    }
  }
};

// Works only with top level properties
export const replaceAnyThingWithSchema = async (schema: IJSONSchema, item: IDataItem): Promise<IJSONSchema> => {
  const clonedSchema = cloneDeepWithMetadata(schema);
  const metaServiceEndpoint = getEndpoint(axiosDictionary.appMetaService);
  if (!clonedSchema.properties) return schema;

  let isAnyThingReplaced = false;

  for (const key of Object.keys(clonedSchema.properties)) {
    if (!item[key]) continue;
    const itemType = (item[key] as IDataItem | undefined | null)?._type;
    if (clonedSchema.properties![key].$id === TypeConstants.AnyThing && itemType) {
      const matchedSchema = await getSchema(urlJoin(metaServiceEndpoint.url, `ontology/schemajson?subjectUri=${encodeURIComponent(itemType)}`));
      matchedSchema.title = clonedSchema.properties![key].title;
      if (matchedSchema) {
        clonedSchema.properties![key] = matchedSchema;
        isAnyThingReplaced = true;
      }
    }
  }

  return isAnyThingReplaced ? clonedSchema : schema;
};

interface IReducedFormFields {
  create?: string[];
  update?: string[];
  table?: string[];
  readonlyContent?: string[];
}

export function generateReducedSchema(schema: IJSONSchema, reducedFormFields: IReducedFormFields): IJSONSchema {
  const newSchema = cloneDeepWithMetadata(schema);

  if (newSchema.properties) {
    for (const [key, property] of Object.entries(newSchema.properties)) {
      if (!property.cp_ui) {
        property.cp_ui = {};
      }

      if (reducedFormFields.create && !reducedFormFields.create.includes(key)) {
        property.cp_ui.hiddenIfCreate = true;
      }
      if (reducedFormFields.update && !reducedFormFields.update.includes(key)) {
        property.cp_ui.hiddenIfUpdate = true;
      }
      if (reducedFormFields.table && !reducedFormFields.table.includes(key)) {
        property.cp_ui.hiddenInTable = true;
      }
      if (reducedFormFields.readonlyContent && !reducedFormFields.readonlyContent.includes(key)) {
        property.cp_ui.hiddenInReadonlyContent = true;
      }
    }
  }

  return newSchema;
}

export const generateDefaultFormData = (schema: IJSONSchema, fullSchema?: IJSONSchema | null): IDataItem => {
  const systemSchemaProperties = ['properties', 'default'];
  const ignoredSchemaProperties = ['items', 'anyOf'];
  const flatSchema = flatten(schema.properties) as Record<string, unknown>;
  const flatFullSchema = fullSchema ? (flatten(fullSchema.properties) as Record<string, unknown>) : {};
  const defaultFields = Object.keys(flatSchema).filter((key) => key.endsWith('.default'));
  const defaultFullFields = Object.keys(flatFullSchema).filter((key) => key.endsWith('.default'));
  const result = {};
  const filteredDefaultFields = defaultFields.filter((defaultField) => !defaultFullFields.includes(defaultField));
  for (const defaultField of filteredDefaultFields) {
    const value = flatSchema[defaultField];
    const path = defaultField.split('.');
    if (path.some((property) => ignoredSchemaProperties.includes(property))) {
      continue;
    }
    const resultPath = path.filter((property) => !systemSchemaProperties.includes(property)).join('.');
    _.set(result, resultPath, value);
  }
  return result;
};

export const removeRequiredPropertiesWithQuestion = (schema: IJSONSchema): IJSONSchema => {
  const schemaCopy = cloneDeepWithMetadata(schema);
  const stepQuestions = schemaCopy['cp_wizardStepQuestion'] ? JSON.parse(schemaCopy['cp_wizardStepQuestion']) : undefined;
  const properties = schemaCopy.properties;
  if (!stepQuestions?.length || !properties) {
    return schema;
  }
  for (const key of Object.keys(properties)) {
    const property = properties[key];
    const wizardGroup = property.cp_ui?.wizardGroup;
    if (!isDefined(wizardGroup)) {
      continue;
    }
    if (!stepQuestions[wizardGroup!]) {
      continue;
    }
    const isFieldRequired = schemaCopy?.required?.includes(key);
    if (!isFieldRequired) {
      continue;
    }
    delete properties[key].cp_ui!.wizardGroup;
  }
  return schemaCopy;
};
