import { enableES5, produce } from 'immer';
import { Action, handleActions } from 'redux-actions';
import { Schemas } from '@cp/base-types';

import { StorageKey, systemDarkMode } from '../../constants';
import { IColumnDetails, IPageSetting } from '../../types';
import { loadColorTheme } from '../../helpers';

import {
  applySettings,
  changePageSetting,
  closePageDrawer,
  closeSettings,
  getStateFromStorage,
  openPageDrawer,
  openSettings,
  setCookieAgreement,
  setPageSettings,
  setExpertMode,
  showCookieAgreement,
} from './actions';

export enum TableActionsPosition {
  Left = 'http://platform.cosmoconsult.com/ontology/Left',
  Right = 'http://platform.cosmoconsult.com/ontology/Right',
  Undefined = 'http://platform.cosmoconsult.com/ontology/Undefined',
}

export enum UserExperiencePreference {
  Simple = 'http://platform.cosmoconsult.com/ontology/Simple',
  Expert = 'http://platform.cosmoconsult.com/ontology/Expert',
  Undefined = 'http://platform.cosmoconsult.com/ontology/Undefined',
}

enableES5();

const DEFAULT_STATE: ISettingsState = {
  isOpened: false,
  darkMode: localStorage.getItem(StorageKey.DarkMode) ? localStorage.getItem(StorageKey.DarkMode) === 'true' : systemDarkMode,
  userExperiencePreference: UserExperiencePreference.Undefined,
  tableActionsPosition: TableActionsPosition.Undefined,
  expertMode: localStorage.getItem(StorageKey.ExpertMode) ? localStorage.getItem(StorageKey.ExpertMode) === 'true' : false,
  zebraRows: localStorage.getItem(StorageKey.ZebraRows) ? localStorage.getItem(StorageKey.ZebraRows) === 'true' : true,
  highContrast: localStorage.getItem(StorageKey.HighContrast) ? localStorage.getItem(StorageKey.HighContrast) === 'true' : false,
  hideDescriptionsInForm: localStorage.getItem(StorageKey.HideDescriptionsInForm) === 'true',
  editFormsAsJson: localStorage.getItem(StorageKey.EditFormsAsJson) === 'true',
  disableAnimation: localStorage.getItem(StorageKey.DisableAnimation) === 'true',
  useOnlyHorizontalMenu: localStorage.getItem(StorageKey.UseOnlyHorizontalMenu) === 'true',
  surfaceLight: false,
  dataLanguage: null,
  pages: {},
  pagesDrawer: {
    isOpened: false,
    activePage: null,
    isWidget: false,
    columns: [],
  },
  acceptGA: false,
  hideCookie: false,
  acceptGAWithUserId: false,
  settingsForThisAppOnly: false,
  cpaPageUserConfigurations: null,
};

export interface ISettingsState {
  tableActionsPosition: TableActionsPosition;
  isOpened: boolean;
  darkMode: boolean;
  userExperiencePreference: UserExperiencePreference;
  expertMode: boolean;
  zebraRows: boolean;
  highContrast: boolean;
  hideDescriptionsInForm: boolean;
  editFormsAsJson: boolean;
  disableAnimation: boolean;
  useOnlyHorizontalMenu: boolean;
  surfaceLight: boolean;
  dataLanguage: string | null;
  pages: { [pageKey: string]: IPageSetting };
  pagesDrawer: {
    isOpened: boolean;
    activePage: Schemas.CpaPage | null;
    isWidget: boolean;
    columns: IColumnDetails[];
  };
  settingsForThisAppOnly: boolean;
  cpaPageUserConfigurations:
    | {
        cpa: {
          identifier: string;
        };
        cpaPage: {
          identifier: string;
        };
        [key: string]: boolean | null | object | string;
      }[]
    | null;

  [key: string]: boolean | null | object | string;
}

export default handleActions<ISettingsState, unknown>(
  {
    [openSettings.toString()]: (state) => ({ ...state, isOpened: true }),
    [closeSettings.toString()]: (state) => ({ ...state, isOpened: false }),
    [applySettings.toString()]: (
      state,
      {
        payload,
      }: Action<{
        settings: Record<string, boolean>;
        palette?: Schemas.Cpa['colorPalette'];
      }>
    ) => {
      for (const [key, value] of Object.entries(payload.settings)) {
        if (key === StorageKey.DarkMode) {
          loadColorTheme(payload.palette, value, payload.settings[StorageKey.HighContrast] ?? state[StorageKey.HighContrast]);
        } else if (key === StorageKey.HighContrast) {
          loadColorTheme(payload.palette, payload.settings[StorageKey.DarkMode] ?? state[StorageKey.DarkMode], value);
        }
      }

      return {
        ...state,
        ...payload.settings,
      };
    },
    [setPageSettings.toString()]: (state, { payload }: Action<{ pages: { [pageKey: string]: IPageSetting } }>) => ({
      ...state,
      pages: payload.pages,
    }),
    [setExpertMode.toString()]: (state, { payload }: Action<{ expertMode: boolean }>) => {
      localStorage.setItem(StorageKey.ExpertMode, JSON.stringify(payload.expertMode));
      return {
        ...state,
        expertMode: payload.expertMode,
      };
    },
    [openPageDrawer.toString()]: (
      state,
      {
        payload,
      }: Action<{
        page: Schemas.CpaPage;
        isWidget?: boolean;
        columns?: IColumnDetails[];
      }>
    ) => ({
      ...state,
      pagesDrawer: {
        isOpened: true,
        activePage: payload.page,
        isWidget: !!payload.isWidget,
        columns: payload.columns || [],
      },
    }),
    [closePageDrawer.toString()]: (state) => ({
      ...state,
      pagesDrawer: { isOpened: false, activePage: null, isWidget: false, columns: [] },
    }),
    [changePageSetting.toString()]: (
      state,
      {
        payload,
      }: Action<{
        pageKey: string;
        setting: keyof IPageSetting;
        value: string | boolean | string[] | null;
      }>
    ) => {
      // localStorage.setItem(`${payload.pageKey}.${payload.setting}`, JSON.stringify(payload.value));
      return {
        ...state,
        pages: {
          ...state.pages,
          [payload.pageKey]: {
            ...state.pages[payload.pageKey],
            [payload.setting]: payload.value,
          },
        },
      };
    },
    [getStateFromStorage.toString()]: produce((state, { payload }: Action<Record<string, boolean>>) => {
      for (const [key, value] of Object.entries(payload)) {
        state[key] = typeof value === 'boolean' ? value : state[key];
      }
    }),
    [setCookieAgreement.toString()]: produce((state, { payload }: Action<Record<string, boolean>>) => {
      for (const [key, value] of Object.entries(payload)) {
        state[key] = typeof value === 'boolean' ? value : state[key];
      }
      state[StorageKey.HideCookie] = true;
    }),
    [showCookieAgreement.toString()]: produce((state) => {
      state[StorageKey.HideCookie] = false;
    }),
  },
  DEFAULT_STATE
);
