import { IJSONSchema } from '@cp/base-types';
import { FormatSource, itemExpandColumnKey, simpleCardKey } from '@cpa/base-core/constants';
import {
  ITableDataSerializer,
  TableDataSerializer,
  findFieldInSchema,
  generateTableHeader,
  getFileExtension,
  getItemsKeys,
  isDefined,
  prepareSchema,
  splitByGroup,
  tableSort,
} from '@cpa/base-core/helpers';
import { CustomColumn, IDataItem, IFieldInSchema, ITableProps, ITableRowProps } from '@cpa/base-core/types';
import {
  CheckboxVisibility,
  ConstrainMode,
  DetailsHeader,
  DetailsListLayoutMode,
  DirectionalHint,
  GroupHeader,
  IColumnReorderOptions,
  IDetailsGroupDividerProps,
  IDetailsList,
  IDetailsListProps,
  IDetailsRowProps,
  IGroup,
  IObjectWithKey,
  Icon,
  MarqueeSelection,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  StickyPositionType,
  ThemeContext,
} from '@fluentui/react';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import classNames from 'classnames';
import * as _ from 'lodash';
import React, { ElementType, MouseEventHandler, createRef, useCallback, useMemo } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { CustomRowTemplates } from '../../../../mapping';
import { formatValue } from '../../../Formatter/formatter';
import HoverTooltip from '../../../HoverTooltip/HoverTooltip';
import { doubleClickActions } from '../../ScrollingContent';
import { Sticky } from '../Sticky/Sticky';

import styles from './Table.module.scss';

export interface ITableState {
  values: IDataItem[];
  columns: CustomColumn[];
  collapsedGroups: string[];
  sortedItemKeys: string[];
  itemsKeys: string[];
}

type DataSerializerType = ITableDataSerializer<{ [key: string]: { width?: number } }>;
type OrderSerializerType = ITableDataSerializer<string[]>;

const fileIconTooltipCalloutProps = {
  isBeakVisible: true,
  beakWidth: 8,
  gapSpace: 6,
  setInitialFocus: false,
  doNotLayer: false,
};

export class Table extends React.PureComponent<ITableProps, ITableState> {
  static contextType = ThemeContext;
  context!: React.ContextType<typeof ThemeContext>;

  public readonly selection: Selection<IObjectWithKey & IDataItem>;
  // @all: ref made public to access table width
  public readonly tableRef = createRef<IDetailsList & { _root: { current: HTMLDivElement } }>();
  public readonly containerRef = createRef<HTMLDivElement>();
  public readonly wrapperRef = createRef<HTMLDivElement>();
  private readonly tableDataSerializer: DataSerializerType;
  private readonly columnOrderSerializer: OrderSerializerType;
  private wrapperListenerAttached: boolean;
  private isWrapperRendered: boolean;

  constructor(props: ITableProps) {
    super(props);

    this.tableDataSerializer = new TableDataSerializer<{
      [key: string]: { width?: number };
    }>(props.tableKey + '.tableData');
    this.columnOrderSerializer = new TableDataSerializer<string[]>(props.tableKey + '.columnOrder');

    this.selection =
      props.selection ||
      new Selection<IDataItem>({
        onSelectionChanged: (): void => {
          this.props.onItemsSelect(this.selection.getSelection(), this.props.tableKey, false);
        },
        getKey: (item, index): string | number => {
          return item.identifier || item.__identifier || index || JSON.stringify(item);
        },
      });

    const deserializedColumnOrder = this.columnOrderSerializer.deserialize();

    const itemsKeys = Table.getNewItemsKeys(props.items, [], props.schema);

    this.deselectAll = this.deselectAll.bind(this);
    this.handleContainerScroll = this.handleContainerScroll.bind(this);
    this.handleWrapperScroll = this.handleWrapperScroll.bind(this);

    this.wrapperListenerAttached = false;
    this.isWrapperRendered = false;

    this.state = {
      values: props.items,
      columns:
        props.columnsToUse ||
        Table.processColumns(
          generateTableHeader({
            schema: props.schema,
            spanColumn: props.spanColumn,
            multilineFields: props.multilineFields,
            keys: itemsKeys,
            sortedItemKeys: props.columnsOrder || deserializedColumnOrder,
            ignoredColumns: props.hiddenInTable,
            groupPropertyJsonPath: props.groupPropertyJsonPath,
            parsedFilters: props.parsedFilters,
            orderOptions: props.order,
          }),
          props
        ),
      collapsedGroups: [],
      itemsKeys: itemsKeys,
      sortedItemKeys: props.columnsOrder || deserializedColumnOrder,
    };
  }

  private static getNewItemsKeys(newItems: IDataItem[], currentKeys: string[], schema: IJSONSchema): string[] {
    const newItemsKeys = getItemsKeys(newItems, schema);
    return Array.from(new Set([...newItemsKeys, ...currentKeys].sort()));
  }

  public componentWillUnmount(): void {
    this.props.selectionEmitter?.off('deselectAll', this.deselectAll);
    this.props.onItemsSelect([], this.props.tableKey, true);

    // Remove scroll event listeners
    this.containerRef.current?.removeEventListener('scroll', this.handleContainerScroll);
  }

  public componentDidUpdate(prevProps: Readonly<ITableProps>, prevState: Readonly<ITableState>): void {
    if (!this.wrapperRef.current) {
      this.isWrapperRendered = false;
      this.wrapperListenerAttached = false;
    }
    if (this.props.isFetching) {
      this.wrapperRef.current?.removeEventListener('scroll', this.handleWrapperScroll);
      this.wrapperListenerAttached = false;
    }
    if (!this.wrapperListenerAttached) {
      if (this.wrapperRef.current) {
        this.isWrapperRendered = true;
        this.wrapperRef.current?.addEventListener('scroll', this.handleWrapperScroll);
        this.wrapperListenerAttached = true;
      }
    }
    if (prevState.values !== this.state.values) {
      this.selection.setItems(this.state.values, false);
    }
    if (prevProps.tableKey !== this.props.tableKey) {
      this.props.onItemsSelect([], prevProps.tableKey, false);
    }
    if (prevProps.items !== this.props.items) {
      this.props.onItemsSelect(this.selection.getSelection(), this.props.tableKey, false);
    }
  }

  public componentDidMount(): void {
    const deserializeColumns = this.tableDataSerializer.deserialize();
    this.colsKeyToWidth = Object.entries(this.props.columnsWidth || deserializeColumns).reduce(
      (acc, [key, { width }]) => ({
        ...acc,
        [key]: width,
      }),
      {}
    );
    this.props.selectionEmitter?.on('deselectAll', this.deselectAll);
    this.containerRef.current?.addEventListener('scroll', this.handleContainerScroll);
    if (this.wrapperRef.current) {
      this.wrapperRef.current.addEventListener('scroll', this.handleWrapperScroll);
      this.wrapperListenerAttached = true;
    }
  }

  private handleContainerScroll(e: Event): void {
    const header = document.getElementById(`${this.props.tableKey}-header`);
    if (header) {
      if (header.offsetParent?.className.includes('stickyAbove')) {
        header.style.transform = `translateX(${-(e.target as HTMLDivElement).scrollLeft}px)`;
      } else {
        header.style.transform = `translateX(0px)`;
      }
    }
    if (this.wrapperRef.current && this.containerRef.current) {
      this.wrapperRef.current.scrollLeft = this.containerRef.current.scrollLeft;
    }
  }

  private handleWrapperScroll(e: Event): void {
    if (this.containerRef.current && this.wrapperRef.current) {
      this.containerRef.current.scrollLeft = this.wrapperRef.current?.scrollLeft;
    }
  }

  private deselectAll(): void {
    this.selection.setAllSelected(false);
  }

  public resetHeaderLeftScroll = (): void => {
    const header = document.getElementById(`${this.props.tableKey}-header`);
    if (header) {
      if (!header.offsetParent?.className.includes('stickyAbove')) {
        header.style.transform = `translateX(0px)`;
      } else {
        header.style.transform = `translateX(-${this.containerRef.current?.scrollLeft}px)`;
      }
    }
  };

  private onColumnClick = async (e: React.MouseEvent<HTMLElement, MouseEvent>, column: CustomColumn): Promise<void> => {
    const columnsWithNewSorting: CustomColumn[] = [...this.state.columns].map((col) => {
      const columnWithNewSorting = { ...col };
      if (columnWithNewSorting.key === column.key) {
        columnWithNewSorting.isSortedDescending = !columnWithNewSorting.isSorted ? false : !columnWithNewSorting.isSortedDescending;
        columnWithNewSorting.isSorted = true;
      } else {
        columnWithNewSorting.isSorted = false;
      }
      return columnWithNewSorting;
    });
    const sortedColumn = columnsWithNewSorting.find((columnWithNewSorting) => columnWithNewSorting.key === column.key);
    if (!sortedColumn) {
      return;
    }

    const sortedColumnIndex = columnsWithNewSorting.indexOf(sortedColumn);
    if (sortedColumnIndex < (this.getColumnReorderOptions().frozenColumnCountFromStart || 0)) {
      return;
    }

    this.selection.setAllSelected(false);

    await this.props.onOrderChange({ columnKey: sortedColumn.key, isAscending: !sortedColumn.isSortedDescending });

    const nextValues = this.props.disableSorting
      ? this.state.values
      : tableSort(this.state.values, {
          column: sortedColumn.fieldName as string,
          isDesc: !!sortedColumn.isSortedDescending,
          type: sortedColumn.type as string,
          groupPropertyJsonPath: this.props.groupPropertyJsonPath,
          propertySchema: sortedColumn.propertySchema,
          isODataSupportedByEndpoint: this.props.isODataSupportedByEndpoint,
        });

    this.setState({
      columns: columnsWithNewSorting,
      values: nextValues,
    });
  };

  // Generate table header based on schema, items, files and parenting
  private static processColumns = (cols: CustomColumn[], props: Readonly<ITableProps>): CustomColumn[] => {
    const isDynamicRowActions =
      props.page.customRowTemplate?.identifier &&
      CustomRowTemplates[props.page.customRowTemplate.identifier]?.options?.table?.dynamicRowActions === true;
    const getExpandColumnProps = () => {
      if (isDynamicRowActions && props.isMobileDevice && !props.powerUser) {
        return { minWidth: 30, maxWidth: 30, isIconOnly: false, name: undefined };
      }
      if (isDynamicRowActions && !props.powerUser)
        return { minWidth: 122, maxWidth: props.onAddClick ? 122 : 46, isIconOnly: false, name: undefined };
      if (isDynamicRowActions) return { minWidth: 82, maxWidth: props.onAddClick ? 82 : 46, isIconOnly: false, name: undefined };
      return {
        minWidth: props.onAddClick ? 46 : 21,
        maxWidth: props.onAddClick ? 46 : 21,
        isIconOnly: true,
        name: itemExpandColumnKey,
      };
    };
    return [
      // Parenting
      ...(!!props.parentPropertyJsonPath && !!props.loadItems
        ? [
            {
              key: itemExpandColumnKey,
              iconName: 'MoreVertical',
              ...getExpandColumnProps(),
              isCollapsible: false,
              isResizable: true,
              onRenderHeader: (headerProps, defaultRender): JSX.Element | null => {
                if (headerProps) {
                  headerProps.column.minWidth = isDynamicRowActions ? 21 : props.onAddClick ? 46 : 21;
                }
                return defaultRender?.(headerProps) || null;
              },
            } as CustomColumn,
          ]
        : []),
      // Virtual column for simplified card view
      ...(props.powerUser
        ? []
        : [
            {
              key: simpleCardKey,
              name: props.page.name,
              minWidth: props.isMobileDevice ? 200 : 400,
              isResizable: true,
              isCollapsible: false,
              isIconOnly: false,
              hidden: false,
              onRenderHeader: (headerProps, defaultRender): JSX.Element | null => {
                return defaultRender?.(headerProps) || null;
              },
            } as CustomColumn,
          ]),
      // Files
      ...(props.items.length > 0 ? props.downloadableFilePropertyJsonPaths : [])
        .filter(({ propertyJsonPath }) => {
          return props.items.length > 0 && props.items.some((item) => !!_.get(item, propertyJsonPath));
        })
        .map(({ propertyJsonPath, schemaPathItems }) => {
          const fileIconTooltipText = schemaPathItems
            .map((schemaPathItem) => schemaPathItem.title)
            .slice(1)
            .filter(Boolean)
            .join(' - ');

          return {
            key: `__file-${propertyJsonPath}`,
            name: propertyJsonPath,
            iconName: 'Page',
            isIconOnly: true,
            fieldName: propertyJsonPath,
            minWidth: 16,
            maxWidth: 16,
            onRender: (item: { [key: string]: string }): JSX.Element | null => {
              if (Object.keys(item).length === 0) {
                return null;
              }

              const itemDownloadValue = _.get(item, propertyJsonPath);
              if (!itemDownloadValue) {
                return null;
              }

              let extension: string | undefined = undefined;
              if (Array.isArray(itemDownloadValue)) {
                for (const itemDownloadValueItem of itemDownloadValue) {
                  if (!extension) {
                    extension = getFileExtension(itemDownloadValueItem)[0];
                  } else {
                    const nextExtension = getFileExtension(itemDownloadValueItem)[0];
                    if (extension !== nextExtension) {
                      // Different extensions in one array
                      extension = undefined;
                    }
                  }
                }
              } else {
                extension = getFileExtension(itemDownloadValue)[0];
              }

              // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
              const onFileIconClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
                event.preventDefault();
                event.stopPropagation();
                props.onDownload?.([item], [propertyJsonPath]);
              };

              return (
                <HoverTooltip
                  content={Array.isArray(itemDownloadValue) ? `${fileIconTooltipText} (${itemDownloadValue.length})` : fileIconTooltipText}
                  directionalHint={DirectionalHint.topCenter}
                  calloutProps={fileIconTooltipCalloutProps}
                >
                  <a onClick={onFileIconClick} className={styles.file}>
                    <Icon
                      {...getFileTypeIconProps({
                        extension: extension,
                        size: 16,
                        imageFileType: 'svg',
                      })}
                    />
                  </a>
                </HoverTooltip>
              );
            },
          };
        }),
      // Other columns
      ...cols
        .filter((column: CustomColumn) => {
          return (
            !props.downloadableFilePropertyJsonPaths.map(({ propertyJsonPath }) => propertyJsonPath).includes(column.fieldName!) &&
            ![itemExpandColumnKey, simpleCardKey].includes(column.key)
          );
        })
        .map((column: CustomColumn) => ({
          ...column,
          hidden: false,
          onRender: (item: IDataItem): JSX.Element | string | null => {
            const value = item[column.fieldName as string];

            // If no value for this column
            if (value === null || value === undefined) {
              return '-';
            }

            const schemaForFormatter = prepareSchema(props.schema?.properties?.[column.fieldName as string] as IJSONSchema, value);
            const formattedTableValue = formatValue(value, FormatSource.Table, {
              schema: schemaForFormatter || null,
              locale: props.locale,
              darkMode: !!props.darkMode,
              t: props.t,
              itemKey: column.fieldName as string,
              rootSchema: props.schema,
              originalItem: item?.__originalItem || item,
              page: props.page,
            });

            if (
              props.showItemLinksInTable &&
              typeof formattedTableValue === 'string' &&
              (column.fieldName === 'identifier' || (column.fieldName === 'name' && props.hiddenInTable?.includes('identifier')))
            ) {
              return (
                <HoverTooltip
                  content={props.t('common.view')}
                  directionalHint={DirectionalHint.topLeftEdge}
                  style={{ pointerEvents: 'none' }}
                  calloutProps={{
                    isBeakVisible: true,
                    beakWidth: 10,
                    gapSpace: 2,
                    setInitialFocus: false,
                    doNotLayer: false,
                  }}
                >
                  <span
                    className={styles.itemLink}
                    onClickCapture={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      props.onItemOpen(item?.__originalItem || item, true);
                    }}
                  >
                    {formattedTableValue}
                  </span>
                </HoverTooltip>
              );
            }

            return formattedTableValue;
          },
        })),
    ].filter((column: CustomColumn) => {
      if (!props?.powerUser && column.key !== simpleCardKey && column.key !== itemExpandColumnKey) {
        return false;
      }
      if (props.hiddenInTable && props.hiddenInTable.length) {
        return !props.hiddenInTable.includes(column.fieldName as string);
      } else {
        return true;
      }
    });
  };

  private colsKeyToWidth: Record<string, number> = {};

  private static getNewState(props: Readonly<ITableProps>, state: ITableState): ITableState {
    let sortedColumn = state.columns.find((col) => col.isSorted);

    const itemsKeys = this.getNewItemsKeys(props.items, state.itemsKeys, props.schema);

    // Initial order from props
    const headerOrderOptions = sortedColumn?.fieldName
      ? { columnKey: sortedColumn.fieldName, isAscending: !sortedColumn.isSortedDescending }
      : props.order;

    const generatedColumns =
      props.columnsToUse ||
      this.processColumns(
        generateTableHeader({
          schema: props.schema,
          orderOptions: headerOrderOptions,
          previousColumns: state.columns,
          spanColumn: props.spanColumn,
          ignoredColumns: props.hiddenInTable,
          multilineFields: props.multilineFields,
          keys: itemsKeys,
          sortedItemKeys: props.columnsOrder || state.sortedItemKeys,
          groupPropertyJsonPath: props.groupPropertyJsonPath,
          parsedFilters: props.parsedFilters,
        }),
        props
      );

    if (!sortedColumn) {
      // Try to find sorted column once again, maybe it appeared from props.order
      sortedColumn = generatedColumns.find((col) => col.isSorted);
    }

    return {
      ...state,
      values:
        sortedColumn && !props.disableSorting
          ? tableSort(props.items, {
              column: sortedColumn.fieldName as string,
              isDesc: sortedColumn.isSortedDescending as boolean,
              type: sortedColumn.type as string,
              groupPropertyJsonPath: props.groupPropertyJsonPath,
              propertySchema: sortedColumn.propertySchema,
              isODataSupportedByEndpoint: props.isODataSupportedByEndpoint,
            })
          : props.items,
      columns: generatedColumns,
      itemsKeys: itemsKeys,
      sortedItemKeys: props.columnsOrder || state.sortedItemKeys,
    };
  }

  private onNewChildItemsLoaded = (items: IDataItem[]): void => {
    if (this.props.onNewChildItemsLoaded) {
      this.props.onNewChildItemsLoaded(items);
      return;
    }

    if (!items.length) {
      return;
    }

    // Handle
    this.setState((state, props) => {
      const newItemsKeys = Table.getNewItemsKeys(items, state.itemsKeys, props.schema);
      if (_.isEqual(newItemsKeys, state.itemsKeys)) {
        return state;
      }
      return {
        ...state,
        itemsKeys: newItemsKeys,
      };
    });
  };

  public static getDerivedStateFromProps(props: Readonly<ITableProps>, state: ITableState): ITableState {
    return Table.getNewState(props, state);
  }

  private handleColumnReorder = (draggedIndex: number, targetIndex: number): void => {
    const draggedItem = this.state.columns[draggedIndex];
    const newColumns: CustomColumn[] = [...this.state.columns];

    // insert before the dropped item
    newColumns.splice(draggedIndex, 1);
    newColumns.splice(targetIndex, 0, draggedItem as CustomColumn);

    const sanitizedColumns = newColumns.map(({ key }) => key);

    this.setState({
      columns: newColumns,
      sortedItemKeys: sanitizedColumns,
    });

    this.props.handleColumnsReorder?.(sanitizedColumns);
  };

  public getColumnReorderOptions(): IColumnReorderOptions {
    let frozenColumns = 0;

    this.props.downloadableFilePropertyJsonPaths.forEach(({ propertyJsonPath }) => {
      if (
        !this.props.hiddenInTable?.includes(propertyJsonPath) &&
        this.props.items.length > 0 &&
        this.props.items.some((item) => !!_.get(item, propertyJsonPath))
      ) {
        frozenColumns += 1;
      }
    });

    if (this.props.parentPropertyJsonPath) {
      frozenColumns += 1;
    }

    return {
      frozenColumnCountFromStart: frozenColumns,
      frozenColumnCountFromEnd: 0,
      handleColumnReorder: this.handleColumnReorder,
    };
  }

  private visibleItems = (): [IDataItem[], IGroup[]] => {
    const { groupPropertyJsonPath, schema } = this.props;
    const { values: stateValues, collapsedGroups } = this.state;
    const filteredItems = stateValues;

    if (groupPropertyJsonPath) {
      const splittingByGroup = splitByGroup(filteredItems, groupPropertyJsonPath);
      const columnInSchema: IFieldInSchema = { value: null };
      findFieldInSchema(schema, _.toPath(groupPropertyJsonPath)[0], columnInSchema);

      const groups = [];
      const items = [];
      for (const [key, group] of splittingByGroup) {
        const startIndex = items.length;
        const count = group.length;

        let groupName = columnInSchema.value
          ? formatValue(key, FormatSource.Table, {
              schema: columnInSchema.value,
              locale: this.props.locale,
              t: this.props.t,
              darkMode: !!this.props.darkMode,
              itemKey: groupPropertyJsonPath,
              rootSchema: this.props.schema,
              originalItem: {},
              page: this.props.page,
              disableTooltip: true,
            })
          : key?.toString();

        if (typeof groupName !== 'string') {
          // We fall back to string in case if formatValue returned jsx
          groupName = key?.toString();
        }
        if (!isDefined(groupName)) {
          groupName = '-';
        }

        groups.push({
          key: groupName as string,
          name: `${columnInSchema.value ? columnInSchema.value.title : groupPropertyJsonPath}: "${groupName}"`,
          startIndex,
          count,
          level: 0,
          isCollapsed: collapsedGroups.includes(groupName as string),
        });
        items.push(...group);
      }

      return [items.length ? items : filteredItems, groups];
    }

    return [filteredItems, []];
  };

  private onGroupHeaderRender = (props: IDetailsGroupDividerProps): JSX.Element | null => {
    const onToggle = (group: IGroup): void => {
      const exists = this.state.collapsedGroups.includes(group.key);
      if (exists) {
        this.setState({
          collapsedGroups: this.state.collapsedGroups.filter((el) => el !== group.key),
        });
      } else {
        this.setState({
          collapsedGroups: [...this.state.collapsedGroups, group.key],
        });
      }
    };
    return this.props.groupPropertyJsonPath ? <GroupHeader {...props} onToggleCollapse={onToggle} /> : null;
  };

  private onRowRender = (rowProps: IDetailsRowProps): JSX.Element => {
    const { TableRowBound } = this;
    const { id, ...newProps } = rowProps;
    return <TableRowBound {...newProps} />;
  };

  private TableRowBound = (rowProps: IDetailsRowProps): JSX.Element => {
    const {
      page,
      disableDoubleClick,
      onItemOpen,
      onItemEdit,
      onAddClick,
      columnsToUse,
      items,
      onItemRightClick,
      parentPropertyJsonPath,
      colorizedRows,
      darkMode,
      reloadEmitter,
      selectionEmitter,
      tableLevel,
      loadItems,
      order,
      parsedFilters,
      pageSize,
    } = this.props;

    const disabledManagedColumnsWidth = !this.props.powerUser || this.props.disabledManagedColumnsWidth;

    const columns =
      columnsToUse ||
      (disabledManagedColumnsWidth
        ? rowProps.columns
        : Table.getColsWithUpdatedWidth(this.colsKeyToWidth, rowProps?.columns as CustomColumn[]) || []);

    const existedKey = rowProps?.item.identifier ? 'identifier' : '__identifier';
    const item: IDataItem = items.find((el) => el?.[existedKey] === rowProps?.item?.[existedKey]) || (rowProps?.item as IDataItem);
    const target = ((item.identifier || item.__identifier) as string)?.replaceAll(/[^\d\w]/g, '_');

    const onAddClickHandler = useMemo<ITableRowProps['onAddClick']>(() => {
      if (onAddClick) {
        return (customAddHandler, getPrefill): unknown => {
          const prefill = getPrefill?.((item?.__originalItem as IDataItem) || item);
          return onAddClick?.((item?.__originalItem as IDataItem) || item, customAddHandler, undefined, prefill);
        };
      }
      return undefined;
    }, [item, onAddClick]);

    const onDoubleClickHandler = useMemo(() => {
      if (!disableDoubleClick) {
        if (page.doubleClickAction === doubleClickActions.EDIT) {
          return (): unknown => onItemEdit([(item?.__originalItem as IDataItem) || item]);
        }
        return (): unknown => onItemOpen((item?.__originalItem as IDataItem) || item, true);
      }
      return undefined;
    }, [disableDoubleClick, page.doubleClickAction, onItemOpen, onItemEdit, item]);

    const onContextMenu: MouseEventHandler = useCallback(
      (e): void => {
        e.preventDefault();
        e.stopPropagation();
        selectionEmitter?.emit('deselectAll');
        this.selection.setIndexSelected(rowProps.itemIndex, true, false);
        onItemRightClick?.((item?.__originalItem as IDataItem) || item, target, e);
      },
      [selectionEmitter, rowProps.itemIndex, onItemRightClick, item, target]
    );

    const TableRowComponent = useMemo<ElementType<ITableRowProps>>(() => {
      if (page.customRowTemplate?.identifier) {
        return CustomRowTemplates[page.customRowTemplate.identifier] || CustomRowTemplates.Default;
      }
      return CustomRowTemplates.Default;
    }, [page]);

    return (
      <TableRowComponent
        key={target}
        parentPropertyJsonPath={parentPropertyJsonPath}
        rowId={`table-item--${target}`}
        fireInvokeEvent={true}
        item={item}
        detailedRowProps={
          this.props.forceSpacerIndentation !== undefined
            ? {
                ...rowProps,
                groupNestingDepth: this.props.forceSpacerIndentation,
              }
            : rowProps
        }
        schema={this.props.schema}
        columns={columns}
        colorizedRows={colorizedRows}
        darkMode={darkMode}
        childTableRender={this.childTableRender}
        reloadEmitter={reloadEmitter}
        onAddClick={onAddClickHandler}
        onDoubleClick={onDoubleClickHandler}
        onContextMenu={onContextMenu}
        level={tableLevel || 0}
        loadItems={loadItems}
        order={order}
        parsedFilters={parsedFilters}
        onNewChildItemsLoaded={this.onNewChildItemsLoaded}
        pageSize={pageSize}
        page={page}
        dataOperations={this.props.dataOperations}
        onItemsSelect={this.props.onItemsSelect}
        tableKey={this.props.tableKey}
        selectionEmitter={this.props.selectionEmitter}
      />
    );
  };

  private childTableRender = (columns: CustomColumn[], itemId: string, items: IDataItem[], isFetching: boolean, totalItems: number): JSX.Element => {
    return (
      <Table
        forceSpacerIndentation={this.props.groupPropertyJsonPath ? 1 : undefined}
        {...this.props}
        tableKey={`${this.props.tableKey}-${itemId}`}
        hideHeader={true}
        disabledManagedColumnsWidth={true}
        disableSorting={true}
        columnsToUse={columns}
        items={items}
        tableLevel={(this.props.tableLevel || 0) + 1}
        groupPropertyJsonPath={undefined}
        isFetching={isFetching}
        totalItems={totalItems}
        disableMarqueeSelection={true}
        onNewChildItemsLoaded={this.onNewChildItemsLoaded}
        selection={undefined}
      />
    );
  };

  private getShimmeredItems = (): null[] => {
    const { totalItems, items, pageSize } = this.props;
    return totalItems && pageSize ? [...Array(Math.abs(totalItems - items.length > pageSize ? pageSize : totalItems - items.length))].fill(null) : [];
  };

  private static getColsWithUpdatedWidth = (colsKeyToWidth: Record<string, number>, columns?: CustomColumn[]): CustomColumn[] | undefined =>
    columns?.map((col) => {
      const calculatedWidth = colsKeyToWidth[col.key] ?? col.calculatedWidth ?? col.currentWidth;

      if (calculatedWidth === null || calculatedWidth === undefined) {
        return col;
      }

      return {
        ...col,
        calculatedWidth,
      };
    });

  private serializeOnResizing = _.debounce((columnsForSerializing: { [key: string]: { width?: number } }) => {
    this.props.handleColumnsResize?.(columnsForSerializing);
  }, 200);

  private onRenderDetailsHeader: IDetailsListProps['onRenderDetailsHeader'] = (detailsHeaderProps) => {
    const onToggle = (): void => {
      const [, groups] = this.visibleItems();
      this.setState({
        collapsedGroups: this.state.collapsedGroups.length ? [] : groups.map(({ key }) => key),
      });
    };

    const withSticky = (el: JSX.Element | null, disablePointerEvents?: boolean): JSX.Element | null => {
      const width = this.containerRef?.current?.getBoundingClientRect().width;

      return el !== null ? (
        <Sticky
          stickyPosition={StickyPositionType.Header}
          stickyClassName={classNames({
            [styles.pageSticky]: !this.props.isWidget,
            [styles.movedRight]: !this.props.isWidget && !!this.props.pageSettings?.displayChart,
            [styles.disablePointerEvents]: disablePointerEvents,
          })}
          stickyOffset={this.props.topbarContentHeight}
          disableStickyOffset={true}
          scrollablePaneContext={this.props.scrollablePaneContext}
          stickyBackgroundColor={this.props.darkMode ? this.context?.palette.neutralSecondaryAlt : this.context?.palette.white}
          tableContainer={this.containerRef.current}
        >
          {() => (
            <div style={{ width }} id={`${this.props.tableKey}-header`}>
              {el}
            </div>
          )}
        </Sticky>
      ) : null;
    };

    const colsWithUpdatedWidth = Table.getColsWithUpdatedWidth(this.colsKeyToWidth, detailsHeaderProps?.columns as CustomColumn[]);

    let header = null;
    if (detailsHeaderProps) {
      const managedColumnsResizeProps = {
        onColumnResized: (col: CustomColumn, width: number): void => {
          if (width < col.minWidth) {
            return;
          }
          const lastCol = detailsHeaderProps?.columns[detailsHeaderProps?.columns.length - 1];
          const lastColWidth = detailsHeaderProps.columns.slice(0, detailsHeaderProps.columns.length - 1).reduce((iteratedWidth, iteratedCol) => {
            const currentColWidth = col.key === iteratedCol.key ? width : this.colsKeyToWidth[iteratedCol.key] ?? iteratedCol.currentWidth ?? 0;
            return iteratedWidth - currentColWidth - 44;
          }, (this.containerRef.current?.clientWidth ?? 0) - 48 - 50);

          this.colsKeyToWidth[col.key] = width;
          this.colsKeyToWidth[lastCol.key] = lastColWidth < 100 ? 100 : lastColWidth;

          const updatedCols = Table.getColsWithUpdatedWidth(this.colsKeyToWidth, detailsHeaderProps.columns as CustomColumn[]) ?? [];

          this.setState({
            columns: updatedCols,
          });

          const columnsForSerializing = Object.entries(this.colsKeyToWidth).reduce(
            (acc, [key, value]) => ({ ...acc, [key]: { width: value } }),
            {} as Record<string, { width: number }>
          );

          this.serializeOnResizing(columnsForSerializing);
        },
      };

      const disabledManagedColumnsWidth = !this.props.powerUser || this.props.disabledManagedColumnsWidth;

      header = (
        <DetailsHeader
          {...detailsHeaderProps}
          layoutMode={DetailsListLayoutMode.justified}
          onToggleCollapseAll={onToggle}
          columns={disabledManagedColumnsWidth ? detailsHeaderProps?.columns : colsWithUpdatedWidth}
          {...(disabledManagedColumnsWidth ? {} : managedColumnsResizeProps)}
        />
      );
    }

    return !this.props.hideHeader && detailsHeaderProps
      ? this.props.disableStickyHeader || this.props.scrollOverFooter
        ? header
        : withSticky(header, this.props.headerPointerEventsDisabled)
      : null;
  };

  public render(): JSX.Element {
    const { tableKey, darkMode, isFetching, onItemClick, selectionEmitter, isWidget, tableLevel, cached } = this.props;
    const [items, groups] = this.visibleItems();

    const columnsWithEventHandling = this.state.columns.map((column) => ({
      ...column,
      onColumnClick: !isFetching ? this.onColumnClick : undefined,
    }));

    return (
      <div className={classNames(styles.tableContainer, { [styles.dark]: darkMode })}>
        {!cached && !isWidget && !tableLevel && (
          <ReactResizeDetector handleWidth={true} targetRef={this.tableRef?.current?._root}>
            {(): JSX.Element => {
              const tableWidth = this.tableRef?.current?._root.current.getBoundingClientRect().width;
              const containerWidth = this.containerRef?.current?.getBoundingClientRect().width;
              const isScrollVisible = tableWidth && containerWidth && tableWidth > containerWidth;
              return (
                <div
                  ref={this.wrapperRef}
                  style={{
                    visibility: isScrollVisible ? 'visible' : 'hidden',
                    pointerEvents: isScrollVisible ? 'auto' : 'none',
                  }}
                  className={styles.scrollWrapper}
                >
                  <div className={styles.scrollDiv} style={{ width: `${tableWidth}px` }} />
                </div>
              );
            }}
          </ReactResizeDetector>
        )}
        <div ref={this.containerRef} style={{ overflowX: !this.props.isWidget ? 'auto' : undefined }}>
          <MarqueeSelection
            selection={this.selection}
            styles={(): object => ({
              boxFill: { backgroundColor: darkMode ? 'rgba(251, 139, 35, 0.15)' : 'rgba(0, 45, 110, 0.1)' },
            })}
            isEnabled={!this.props.disableMarqueeSelection && !this.props.parentPropertyJsonPath}
            onShouldStartSelection={(e): boolean => (e.target as HTMLElement).tagName !== 'INPUT'}
          >
            <ShimmeredDetailsList
              dragDropEvents={this.props.dragDropEvents}
              useReducedRowRenderer={true}
              onItemInvoked={(item: IDataItem): void => {
                if (onItemClick) {
                  onItemClick(item, this.selection);
                } else {
                  selectionEmitter?.emit('deselectAll');
                  this.selection.setKeySelected(this.selection.getKey(item), true, false);
                }
              }}
              componentRef={this.tableRef}
              className={styles.table}
              detailsListStyles={{ root: { width: 'fit-content' } }}
              constrainMode={ConstrainMode.horizontalConstrained}
              setKey={tableKey}
              items={isFetching ? [...items, ...this.getShimmeredItems()] : items}
              groups={
                groups.length > 0
                  ? isFetching
                    ? [
                        ...groups.slice(0, -1),
                        {
                          ...groups[groups.length - 1],
                          count: groups[groups.length - 1].count + this.getShimmeredItems().length,
                        },
                      ]
                    : groups
                  : undefined
              }
              groupProps={{ onRenderHeader: this.onGroupHeaderRender }}
              columns={columnsWithEventHandling}
              selection={this.selection}
              selectionMode={Number.isInteger(this.props.selectionMode) ? this.props.selectionMode : SelectionMode.multiple}
              selectionPreservedOnEmptyClick={true}
              checkboxVisibility={CheckboxVisibility.always}
              columnReorderOptions={this.getColumnReorderOptions()}
              layoutMode={DetailsListLayoutMode.justified}
              onRenderRow={this.onRowRender}
              onRenderDetailsHeader={this.onRenderDetailsHeader}
              onShouldVirtualize={(): boolean => !this.props.disableVirtualization}
            />
          </MarqueeSelection>
        </div>
      </div>
    );
  }
}

export type TableComponentType = Table;
export default Table;
