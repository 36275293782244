import {IJSONSchema} from '@cp/base-types';
import {cloneDeepWithMetadata, isRelationSchema} from '@cp/base-utils';
import * as _ from 'lodash';
import React from 'react';
import {AjvError, utils} from '@rjsf/core';
import flatten, {unflatten} from 'flat';

import {IDataItem} from '../types';
import {i18n} from '../app';

import {cleanupRecursive} from './data/data';
import {getMatchingEnum} from './data/form';
import {validateFormData} from './data/ajv';

export enum StepTypes {
  Regular = 'regular',
  Guided = 'guided',
  Repeat = 'repeat',
}

// Normal step w/o extra logic, mainly used for object fields
export type RegularStep = {
  type: StepTypes.Regular;
  fields: string[];
  description?: string;
}

// Step with extra logic of handling array items
export type GuidedStep = {
  type: StepTypes.Guided;
  fields: string[];
  description?: string;
}

// Utility step used after GuidedStep to create one more array item and move cursor
export type RepeatStep = {
  type: StepTypes.Repeat;
  from: number;
  question: string;
  path: string;
  deactivated?: boolean;
}

export type Step = RepeatStep | GuidedStep | RegularStep;


// Constants for array manipulation operations
const ANYOF_ARRAY_STRIP_COUNT = 5; // Number of items to strip from an "anyOf" array
const ANYOF_ARRAY_EXTEND_COUNT = 3; // Number of items to extend an "anyOf" array by
const ARRAY_STRIP_COUNT = 3; // Number of items to strip from a regular array
const ANYOF_EXTEND_COUNT = 2; // Number of items to extend an "anyOf" array by in a specific context

const WIZARD_STEP_DESCRIPTION_PATH = 'cp_wizardStepQuestion';

/**
 * Removes a specified property and all its child properties from a flat schema. Mutates provided flat schema.
 *
 * @param flatSchema - A flat representation of the schema in which properties are stored as key-value pairs.
 * @param property - The base property whose key and all child keys starting with this property will be removed.
 */
const removePropertyAndChildrenFromSchema = (flatSchema: Record<string,unknown>, property: string) => {
  const propertyLevel = property.split('.').length;
  for (const key of Object.keys(flatSchema)) {
    const keyOnPropertyLevel = key.split('.').slice(0, propertyLevel).join('.');
    if (keyOnPropertyLevel !== property) continue;
    delete flatSchema[key];
  }
};

/**
 * Creates a modified schema that removes any properties that are set to `hiddenInForm: true` and all their child properties.
 *
 * @param schema - The schema from which to create the modified schema.
 * @returns The modified schema with the properties removed.
 */
const createValidationSchema = (schema: IJSONSchema): IJSONSchema => {
  const schemaCopy = cloneDeepWithMetadata(schema);
  const flatSchema = flatten(schemaCopy) as Record<string,unknown>;

  for (const property of Object.keys(flatSchema)) {
    if (!flatSchema[property]) continue;
    if (property.endsWith('hiddenInForm')) {
      const value = flatSchema[property];
      if (value === true) {
        const pathAsArray = property.split('.');
        const path = pathAsArray.slice(0, pathAsArray.length - 2).join('.');
        if (!path.endsWith('_type')) {
          removePropertyAndChildrenFromSchema(flatSchema, path);
        }
      }
    }
  }
  return unflatten(flatSchema);
};

/**
 * Recursively iterates over an object and its child objects, removing any `undefined` values.
 *
 * This function is useful for cleaning up form data that may contain `undefined` values due to the way it is
 * processed in the `Form` component.
 *
 * @param obj - The object to clean.
 * @returns The cleaned object.
 */
function deepIterateAndClean<T>(obj: T): T {
  if (Array.isArray(obj)) {
    return obj
      .filter((item) => item !== undefined)
      .map((item) => deepIterateAndClean(item)) as unknown as T;
  }

  if (typeof obj === 'object' && obj !== null) {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      (acc as any)[key] = deepIterateAndClean(value);
      return acc;
    }, {} as Record<string, any>) as T;
  }

  return obj;
}

/**
 * Prepares a new form data object based on the given form data and array of visible fields.
 *
 * The function works by creating a new object and picking the properties from the form data based
 * on the visible fields and array cursor calculated from the given wizard steps and step index.
 *
 * The object is then cleaned up by removing any `undefined` values and the result is returned.
 *
 * @param formData - The form data object to prepare.
 * @param visibleFields - The array of visible fields to include in the new form data object.
 * @param steps - The wizard steps to use for calculating the array cursor.
 * @param stepIndex - The index of the current step in the wizard steps.
 * @returns The prepared form data object.
 */
const prepareValidationFormData = (formData: IDataItem<unknown>, visibleFields: string[], steps: Step[], stepIndex: number): IDataItem<unknown> => {
  const wizardCursor = getWizardArrayMeta(steps, '', stepIndex);
  const result: IDataItem<unknown> = {};
  // Create new formdata based on visible fields and array cursor
  for (const field of visibleFields) {
    const formDataPath = transformSchemaPathToFormDataPath(field, formData, wizardCursor, false);
    const picked = _.pick(formData, formDataPath);
    _.merge(result, picked);
  }
  cleanupRecursive(result);
  return deepIterateAndClean(result);
};

/**
 * Validates the given form data for a specific step in the wizard.
 *
 * @param schema - The schema of the form.
 * @param steps - The wizard steps.
 * @param step - The index of the step to validate.
 * @param formData - The form data to validate. If not provided, the function will return `undefined`.
 * @returns The validation result, or `undefined` if no form data was provided.
 */
export const validateStep = (schema: IJSONSchema, steps: Step[], step: number, formData?: IDataItem<unknown>): {
  errors: AjvError[];
  errorSchema: {};
} | undefined => {
  const formDataClone: IDataItem<unknown> | undefined = _.cloneDeep(formData);
  if (!formDataClone) return;
  const visibleFields = (steps[step] as GuidedStep).fields;
  const validationFormData = prepareValidationFormData(formDataClone, visibleFields, steps, step);
  const validationSchema = createValidationSchema(hideFields(schema, visibleFields, true, true));
  return validateFormData(validationFormData, validationSchema);
};

export const getObjectPreviewText = (
  formDataEntries: [string, unknown][],
  propertiesEntries: [string, unknown][],
  formData: any,
  schema: IJSONSchema
): string | undefined => {
  const previewProperty = formDataEntries
    .map(([property, value]) => {
      const matchingPropertyEntry = propertiesEntries.find((propertyEntry) => propertyEntry[0] === property);
      if (!matchingPropertyEntry) return;
      if (value && typeof value === 'string') {
        const matchedEnum = getMatchingEnum(schema as IJSONSchema, property, value as string | undefined);
        if (matchedEnum) return matchedEnum;
        else return value;
      }
      if (isRelationSchema(matchingPropertyEntry[1] as IJSONSchema) && formData[property]?.identifier) {
        const formDataProperty = formData[property];
        return formDataProperty?.name || formDataProperty?.identifier;
      }
      return false;
    })
    .filter(Boolean);
  return previewProperty[0];
};

/**
 * Strips the first `level` segments from each exception path in `exceptions`.
 *
 * @param exceptions - The exception paths to strip.
 * @param level - The number of segments to strip.
 * @returns The stripped exception paths.
 */
const stripExceptions = (exceptions: string[], level: number): string[] => {
  return exceptions.map((exception) => {
    return _.toPath(exception).slice(level).join('.');
  });
};

/**
 * Transforms a schema path to a form data path by traversing the form data object
 * and using the wizard array meta to determine the indices of array items to traverse.
 *
 * The function takes a schema path as a dot-separated string and a form data object,
 * and returns the corresponding form data path as a dot-separated string.
 *
 * The function also takes a wizard array meta array and a boolean flag `addNewItem`
 * which determines whether to add a new item to the array when traversing it or not.
 *
 * The function will strip any 'anyOf', 'oneOf', 'allOf', 'items', or 'properties' segments
 * from the schema path and will traverse the form data object accordingly.
 *
 * If the schema path segment is not an array index, the function will simply traverse
 * the form data object by the given property name.
 *
 * If the schema path segment is an array index, the function will use the wizard array
 * meta to determine the index of the array item to traverse. If the index is not
 * available in the wizard array meta, the function will traverse the array by its
 * length. If the array is empty, the function will set the index to 0.
 *
 * @param schemaPath - The schema path as a dot-separated string.
 * @param formData - The form data object to traverse.
 * @param wizardArrayMeta - The wizard array meta to use for determining array indices.
 * @param addNewItem - A boolean flag indicating whether to add a new item to the array when traversing it or not.
 * @returns The form data path as a dot-separated string.
 */
export function transformSchemaPathToFormDataPath(schemaPath: string, formData: any, wizardArrayMeta: (number | undefined)[], addNewItem: boolean): string {
  const wizardMetaClone = [...wizardArrayMeta];
  const pathSegments = schemaPath.split('.');
  const formDataPath: string[] = [];
  let currentData = formData;

  for (let i = 0; i < pathSegments.length; i++) {
    const segment = pathSegments[i];

    if (['anyOf', 'oneOf', 'allOf', 'items', 'properties'].includes(segment)) {
      if (segment === 'anyOf' && i + 1 < pathSegments.length) {
        i++;
        continue;
      }
      continue;
    }

    if (Array.isArray(currentData)) {
      const indexFromWizardMeta: number | undefined = wizardMetaClone.shift();
      const index = typeof indexFromWizardMeta === 'number' ? indexFromWizardMeta : currentData.length > 0 ? addNewItem ? currentData.length : currentData.length - 1 : 0;
      if (formDataPath.length === 0 || isNaN(Number(formDataPath[formDataPath.length - 1]))) {
        formDataPath.push(String(index));
      }
      currentData = currentData[index];
    }

    formDataPath.push(segment);

    if (currentData && typeof currentData === 'object') {
      if (currentData.hasOwnProperty(segment)) {
        currentData = currentData[segment];
      } else {
        currentData = [];
      }
    }
  }

  return formDataPath.join('.');
}

/**
 * Takes a schema path and removes any segments that are "schema keywords",
 * namely `anyOf`, `oneOf`, `allOf`, `items`, and `properties`. If the
 * keyword is `anyOf`, the segment immediately following it is also removed.
 *
 * @param path - The schema path to process.
 * @returns The modified schema path.
 */
const removeSchemaKeywordsFromPath = (path: string) => {
  const repeatPathAsArray = _.toPath(path);
  let skipNext: boolean = false;
  const repeatArrayPath = repeatPathAsArray.filter((item, index) => {
    if (skipNext) {
      skipNext = false;
      return false;
    }
    if (['anyOf', 'oneOf', 'allOf', 'items', 'properties'].includes(item)) {
      if (item === 'anyOf') {
        skipNext = true;
        return false;
      }
      return false;
    }
    return true;
  });
  return repeatArrayPath.join('.');
};

/**
 * Creates a new array item at the specified path in the formData object.
 * It is used by the wizard to add new items to an array field.
 *
 * @param currentStepArrayPath - The schema path of the array field.
 * @param schema - The schema of the form.
 * @param formData - The formData object.
 * @param wizardArrayMeta - The metadata of the wizard array field.
 * @param addNewItem - If true, a new item will be added to the array. If false, the last item will be duplicated.
 */
export const createNewArrayItemAtPath = (currentStepArrayPath: string, schema: IJSONSchema, formData: IDataItem<unknown> | undefined, wizardArrayMeta: (number | undefined)[], addNewItem: boolean) => {
  const fieldSchema = _.get(schema.properties, currentStepArrayPath);
  const defaultFormData = getNewFormDataRow(fieldSchema as IJSONSchema);
  const formDataPath = transformSchemaPathToFormDataPath(currentStepArrayPath, formData, wizardArrayMeta, addNewItem);
  if (!wizardArrayMeta.length) {
    const currentArrayPath = removeSchemaKeywordsFromPath(currentStepArrayPath);
    const currentArrayPathSegments = currentArrayPath.split('.');
    const generatedData = currentArrayPathSegments.reduce((acc: any, segment: string, index) => {
      // Skip first array because acc is already array
      if (index === 0) return acc;
      if (index === currentArrayPathSegments.length - 1) {
        acc[segment] = [defaultFormData];
        return acc;
      }
      acc[segment] = [];
      return acc;
    }, {});
    const currentData = _.get(formData, formDataPath) as IDataItem[] || {};
    if (Array.isArray(currentData)) {
      _.set(formData || {}, currentArrayPathSegments[0], [...currentData, generatedData ]);
    } else if (typeof currentData === 'object') {
      // @ts-ignore
      _.set(formData || {}, formDataPath, [defaultFormData]);
    }
    return;
  }
  const currentData = _.get(formData, formDataPath) as IDataItem[] || {};
  if (Array.isArray(currentData)) {
    _.set(formData || {}, formDataPath, [...currentData, defaultFormData ]);
  } else if (typeof currentData === 'object') {
    // @ts-ignore
    _.set(formData || {}, formDataPath, [defaultFormData]);
  }
};

/**
 * Increments the 'from' property of RepeatSteps in the given array that are placed after the given index.
 * @param stepsAfterCurrent - The array of steps to modify.
 * @param count - The number of steps to increment by.
 * @param index - The index of the step after which the RepeatSteps should be incremented.
 * @returns The modified array of steps.
 */
const incrementRepeatStepsAfterCurrent = (stepsAfterCurrent: Step[], count: number, index?: number): Step[] => {
  return stepsAfterCurrent.map((step) => {
    if (step.type !== StepTypes.Repeat) return step;
    // If step repeat step is leading to the step before current, it should not be incremented
    if (index && step.from < index) return step;
    return {
      ...step,
      from: step.from + count
    };
  });
};

/**
 * Filters out any RepeatSteps that refer to the same array path as an earlier step.
 * Also filters out any fields in GuidedSteps that refer to the same array path as
 * an earlier RepeatStep.
 *
 * The purpose of this function is to prevent duplicate array items from being
 * added when traversing the wizard in reverse order.
 *
 * @param steps - The array of wizard steps to filter.
 * @returns The filtered array of wizard steps.
 */
const filterRepeatingArraySteps = (steps: Step[]): Step[] => {
  const seenPaths = new Set<string>();
  const result: Step[] = [];

  for (const step of steps) {
    if (step.type === 'repeat') {
      if (!seenPaths.has(step.path)) {
        seenPaths.add(step.path);
        result.push(step);
      }
    } else if (step.type === 'guided') {
      const filteredFields = step.fields.filter((field) =>
        ![...seenPaths].some((path) => field.startsWith(path))
      );
      if (filteredFields.length > 0) {
        result.push({...step, fields: filteredFields});
      }
    } else {
      result.push(step);
    }
  }
  return result;
};

/**
 * Modifies the given array of wizard steps to add repeat steps when traversing
 * the wizard in reverse order.
 *
 * The purpose of this function is to prevent duplicate array items from being
 * added when traversing the wizard in reverse order.
 *
 * It works by:
 * 1. finding the steps before the current array
 * 2. finding the steps in the current array
 * 3. filtering out any RepeatSteps in the current array that refer to the same
 *    array path as an earlier step
 * 4. incrementing the `from` property of all RepeatSteps after the current
 *    array by the number of steps in the current array
 * 5. modifying the current step to return to the first step of the current
 *    array
 * 6. incrementing the `from` property of all RepeatSteps after the current
 *    array by the number of steps in the current array
 * 7. returning the modified steps array
 *
 * @param steps - The array of wizard steps to modify.
 * @param currentStepIndex - The index of the current step in the array.
 * @param lastVisitedStep - A mutable reference to the last visited step index.
 * @param returnTo - The index of the step to return to when traversing the
 *   wizard in reverse order.
 * @returns The modified array of wizard steps.
 */
export const addRepeatSteps = (steps: Step[], currentStepIndex: number, lastVisitedStep: React.MutableRefObject<number>, returnTo?: number): Step[] => {
  if (!returnTo) {
    return steps;
  }
  const stepsBeforeArray = steps.slice(0, currentStepIndex + 1);
  const arraySteps = steps.slice(returnTo, currentStepIndex);
  const arrayStepsWithoutDuplicatedRepeats = filterRepeatingArraySteps(arraySteps);
  const currentStep = steps[currentStepIndex] as RepeatStep;
  const incrementedArrayStepsWithoutDuplicatedRepeats = incrementRepeatStepsAfterCurrent(arrayStepsWithoutDuplicatedRepeats, currentStepIndex + 1 - currentStep.from);
  const modifiedReturnStep = {...currentStep, from: currentStepIndex + 1 };
  const stepsAfterArray = steps.slice(currentStepIndex + 1);
  const incrementedStepsAfterArray = incrementRepeatStepsAfterCurrent(stepsAfterArray, arraySteps.length + 1, currentStepIndex);
  lastVisitedStep.current += 1;
  return [...stepsBeforeArray, ...incrementedArrayStepsWithoutDuplicatedRepeats, modifiedReturnStep, ...incrementedStepsAfterArray];
};

/**
 * Given an array of paths, returns an array of paths at the specified level.
 * If exact is true, the first element of the path at the specified level is
 * returned. Otherwise, the elements of the path at the specified level are
 * joined with '.'.
 *
 * @param paths - The array of paths.
 * @param level - The level of the path to return.
 * @param exact - If true, the first element of the path at the specified level
 *   is returned. Otherwise, the elements of the path at the specified level are
 *   joined with '.'.
 * @returns An array of paths at the specified level.
 */
const getPathAtLevel = (paths: string[], level: number, exact?: boolean) => {
  return paths.map((path) => {
    const exceptionPath = _.toPath(path).slice(0, level + 1);
    if (exact) {
      return exceptionPath[0];
    }
    return exceptionPath.join('.');
  });
};

/**
 * Checks if new array items should be created at the specified step index.
 *
 * Returns true if the array at the specified step index has never been filled
 * before and the user has never visited the step before. Otherwise, returns
 * false.
 *
 * @param steps - The array of wizard steps.
 * @param stepIndex - The index of the step to check.
 * @returns Whether new array items should be created.
 */
export const isNewArrayItemsRequired = (steps: Step[], stepIndex: number) => {
  const step = steps[stepIndex];
  if (step.type !== StepTypes.Guided) return false;
  const fields = step.fields;
  const path = fields[0];
  const currentArrayPath = getCurrentArrayPath(path, getCurrentDepth(path));
  const currentArrayPathAtFirstDepth = getCurrentArrayPath(path, 1);

  const isFirstFill = steps.every((step, index) => {
    if (index >= stepIndex) return true;
    if (step.type !== StepTypes.Guided) return true;
    const path = step.fields[0];
    return !path.startsWith(currentArrayPathAtFirstDepth);
  });

  return isFirstFill;
};

/**
 * Removes the given key from the `required` array of the given schema.
 * @param schema - The schema to modify.
 * @param key - The key to remove from the `required` array.
 */
const removeRequiredField = (schema: IJSONSchema, key: string): void => {
  if (!schema.required?.length) {
    return;
  }
  schema.required = schema.required.filter((requiredKey) => requiredKey !== key);
};

/**
 * Returns a new, empty object that conforms to the given schema.
 *
 * For array schemas, this function determines the schema for the array items.
 * If the array has fixed items and additional items are allowed, the
 * additional items schema is used. Otherwise, the items schema is used.
 *
 * The returned object is the default form state for the schema.
 *
 * @param schema - The schema to generate a new form row for.
 * @returns The new, empty form row object.
 */
export const getNewFormDataRow = (schema: IJSONSchema): object => {
  let itemSchema = schema.items;
  if (utils.isFixedItems(schema) && utils.allowAdditionalItems(schema)) {
    itemSchema = schema.additionalItems as IJSONSchema;
  }
  return utils.getDefaultFormState(itemSchema as IJSONSchema, {}) as unknown as IJSONSchema;
};

/**
 * Modifies a given JSON schema to hide fields based on specific conditions,
 * while allowing exceptions for specified paths. The function can also
 * create new array items if required.
 *
 * The function traverses the schema properties, applying visibility and
 * expansion settings to fields and their UI schema definitions. It checks
 * for exceptions at each level and adjusts the schema properties accordingly.
 *
 * @param schema - The JSON schema to be modified.
 * @param exceptions - An array of schema paths to be excluded from hiding.
 * @param forValidation - A boolean indicating if the schema is being prepared
 * for validation. If true, additional properties are allowed.
 * @param createNewArrayItems - A boolean indicating whether new array items
 * should be created.
 * @param currentPath - The current path within the schema being processed.
 * Defaults to an empty string.
 * @returns The modified JSON schema with the specified fields hidden and
 * exceptions applied.
 */
export const hideFields = (schema: IJSONSchema, exceptions: string[], forValidation: boolean, createNewArrayItems: boolean, currentPath = ''): IJSONSchema => {
  // if (forValidation) {
  //   schema.additionalProperties = true;
  // }
  const currentLevel = _.toPath(currentPath).length;
  const schemaCopy = cloneDeepWithMetadata(schema);
  const properties = schemaCopy.properties || {};
  for (const key of Object.keys(properties)) {
    const isRootException = exceptions.includes(key);
    if (isRootException) {
      if (properties[key].items?.anyOf) {
        properties[key] = {
          ...properties[key],
          items: {
            ...properties[key].items,
            cp_rjsfUiSchema: {
              defaultExpanded: true,
              visible: true
            }
          },
          cp_rjsfUiSchema: {
            defaultExpanded: true,
            visible: true,
          }
        };
      }
      properties[key] = {
        ...properties[key],
        cp_rjsfUiSchema: {
          defaultExpanded: true,
          visible: true,
          defaultView: true
        }
      };
    }
    const exceptionsAtCurrentLevel = getPathAtLevel(exceptions, currentLevel, true);
    if (exceptionsAtCurrentLevel.includes(key)) {
      // anyOf array
      if (properties[key]?.items?.anyOf) {
        schemaCopy.cp_rjsfUiSchema = {
          ...schemaCopy.cp_rjsfUiSchema,
          defaultExpanded: true
        };
        if (properties[key].items) {
          properties[key].items!.cp_rjsfUiSchema = {
            ...properties[key].items!.cp_rjsfUiSchema,
            defaultExpanded: true
          };
        }
        const exceptionsAtAnyOfLevel = getPathAtLevel(exceptions, currentLevel + ANYOF_ARRAY_EXTEND_COUNT);
        if (createNewArrayItems) {
          properties[key].minItems = 1;
        }
        properties[key].items!.anyOf = (properties[key].items?.anyOf || []).map((item, index) => {
          const anyOfPath = `${currentPath ? `${currentPath}.properties.` : ''}${key}.items.anyOf.${index}`;
          // Add default view if current anyOf is in exceptions
          if (exceptions.includes(anyOfPath)) {
            return {
              ...item,
              cp_rjsfUiSchema: {
                ...item.cp_rjsfUiSchema,
                defaultView: true
              }
            };
          }
          // Hide all anyOf if it is not in path
          if (exceptionsAtAnyOfLevel.includes(anyOfPath)) {
            return hideFields(item, stripExceptions(exceptions, ANYOF_ARRAY_STRIP_COUNT), forValidation, createNewArrayItems, anyOfPath);
          }
          if (exceptionsAtAnyOfLevel.includes(key)) {
            return item;
          }
          // If we need to hide then
          if (forValidation) {
            return null;
          } else {
            if (properties[key].items) {
              properties[key].items!.cp_rjsfUiSchema = {
                ...schemaCopy.cp_rjsfUiSchema,
                hiddenOptions: [...(properties[key].items?.cp_rjsfUiSchema?.hiddenOptions || []), index]
              };
            }
            return item;
          }
        }).filter(Boolean) as IJSONSchema[];
        // array
      } else if (properties[key]?.items) {
        const itemsPath = `${currentPath ? `${currentPath}.properties.` : ''}${key}.items`;
        if (!properties[key].items?.format && createNewArrayItems) {
          properties[key].minItems = 1;
        }
        if (!exceptions.includes(key)) {
          properties[key].items = hideFields(properties[key].items || {}, stripExceptions(exceptions, ARRAY_STRIP_COUNT), forValidation, createNewArrayItems, itemsPath);
        } else {
          properties[key].items!.cp_rjsfUiSchema = {
            defaultView: true,
          };
        }
        // anyOf
      } else if (properties[key]?.anyOf) {
        properties[key].cp_rjsfUiSchema = {
          ...properties[key].cp_rjsfUiSchema,
          defaultExpanded: true
        };
        const exceptionsAtAnyOfLevel = getPathAtLevel(exceptions, currentLevel + ANYOF_EXTEND_COUNT);
        const finishedExceptions = exceptionsAtAnyOfLevel.filter((exception) => _.toPath(exception).length === 1);
        properties[key].anyOf = (properties[key].anyOf || []).map((item, index) => {
          const anyOfPath = `${key}.anyOf.${index}`;
          if (exceptionsAtAnyOfLevel.includes(anyOfPath) || finishedExceptions.includes(key)) {
            return item;
          }
          return null;
        }).filter(Boolean) as IJSONSchema[];
      }
      const propertyPath = `${currentPath ? `${currentPath}.properties.` : ''}${key}`;
      const exceptionsAtPropertyLevel = getPathAtLevel(exceptions, currentLevel + ANYOF_EXTEND_COUNT);
      const currentExceptionIndex = exceptionsAtCurrentLevel.findIndex((exception) => exception === key);
      const currentExceptionAtNextLevel = currentExceptionIndex !== -1 ? stripExceptions([exceptionsAtPropertyLevel[currentExceptionIndex]], ANYOF_EXTEND_COUNT)[0] : null;
      if (!currentExceptionAtNextLevel) {
        continue;
      }
      properties[key] = hideFields(properties[key], stripExceptions(exceptions, ANYOF_EXTEND_COUNT), forValidation, createNewArrayItems, propertyPath);
    } else {
      removeRequiredField(schemaCopy, key);
      if (forValidation) {
        schema.additionalProperties = true;
        delete properties[key];
      } else {
        properties[key] = {
          ...properties[key],
          cp_ui: {
            ...properties[key].cp_ui,
            hiddenInForm: true,
          },
        };
      }
    }
  }

  return {...schemaCopy, cp_rjsfUiSchema: {
      ...schemaCopy.cp_rjsfUiSchema,
      defaultExpanded: true
    }};
};

/**
 * Checks if a given schema path is a regular field (i.e. not an array item).
 *
 * A regular field is a field that is not an item of an array. This is determined by
 * checking if the path contains the string 'items'. If it does, then the path is
 * not a regular field.
 *
 * @param path - The dot-separated schema path as a string.
 * @returns true if the path is a regular field, false otherwise.
 */
const isRegularField = (path: string) => {
  const pathAsArray = path.split('.');
  return !pathAsArray.includes('items');
};

/**
 * Calculates the depth of the 'items' in a given schema path.
 *
 * The depth is determined by counting how many times 'items'
 * appears in the path. This is useful for understanding the
 * nesting level of array items in the schema.
 *
 * @param path - The dot-separated schema path as a string.
 * @returns The number of 'items' occurrences in the path.
 */
export const getCurrentDepth = (path: string) => {
  const pathAsArray = path.split('.');
  return pathAsArray.filter((item) => item === 'items').length;
};

/**
 * Calculates the array path at a given depth from a given schema path.
 *
 * The array path is the path to the array item in the schema, without the 'items'
 * property. This is useful for understanding the nesting level of array items in the
 * schema.
 *
 * @param path - The dot-separated schema path as a string.
 * @param depth - The desired depth of the array path.
 * @returns The array path at the given depth.
 */
const getCurrentArrayPath = (path: string, depth: number) => {
  const pathAsArray = path.split('.');
  const targetItemsIndex = pathAsArray.findIndex((pathPart) => {
    if (pathPart === 'items') {
      if (depth === 1) return true;
      else {
        depth--;
        return false;
      }
    }
    return false;
  });
  if (targetItemsIndex === -1) {
    return path;
  }
  return pathAsArray.slice(0, targetItemsIndex).join('.');
};

/**
 * Checks if a given schema path is a root path.
 *
 * A root path is a path that only contains one element, i.e. it is not nested
 * inside another array or object. This is determined by splitting the path
 * into its components and checking if the resulting array has a length of 1.
 *
 * @param path - The dot-separated schema path as a string.
 * @returns true if the path is a root path, false otherwise.
 */
const isRoot = (path: string): boolean => {
  const level = path.split('.').length;
  return level === 1;
};

/**
 * Splits a given dot-separated schema path into an array of path components.
 *
 * @param path - The dot-separated schema path as a string.
 * @returns The array of path components.
 */
const toPath = (path: string): string[] => {
  return path.split('.');
};

/**
 * Calculates the index of each path segment in the given array of field paths.
 *
 * The index is determined by counting the number of times the path segment
 * appears in the array of fields, starting from the root level (level 0).
 * If the path segment is an array item (i.e. not a root path), the count
 * is determined by the number of times the path segment appears in the
 * array of fields after the level switch.
 *
 * @param fields - The array of field paths as strings.
 * @returns An array of indices, where each index corresponds to the
 * index of the path segment at that level in the given array of fields.
 */
const getFieldIndices = (fields: string[]): (number | undefined)[] => {
  const target = fields[fields.length - 1];
  const targetArray = toPath(target);
  const targetLevel = targetArray.length - 1;
  const result = [];
  let usedPaths: string[] = [];
  let level = 1;
  let currentRoot = '';
  for (const segment of targetArray) {
    // Get count at level 0
    currentRoot = targetArray.slice(0, level).join('.');
    const fieldsFiltered = fields.filter((field) =>
      field.startsWith(currentRoot)
    );
    const useCount = fieldsFiltered.reduce((acc, field) => {
      if (isRoot(field) || field === currentRoot) {
        return acc + 1;
      } else {
        if (usedPaths.includes(field)) {
          return acc;
        }
        return acc;
      }
    }, 0);
    level++;
    usedPaths = [];
    result.push(useCount);
  }

  if (targetLevel > 1) {
    // Recalc target count
    let prevLevelAppearanceCount = result[targetLevel - 1];
    const prevLevelFieldPath = targetArray.slice(0, targetLevel);
    const fieldsAfterLevelSwitch = fields.filter((field, index) => {
      if (field === prevLevelFieldPath.join('.')) {
        prevLevelAppearanceCount--;
        return false;
      }
      if (prevLevelAppearanceCount > 0) {
        return false;
      }
      if (prevLevelAppearanceCount === 0) return true;
      return true;
    });

    const targetCount = fieldsAfterLevelSwitch.filter((field) => {
      return field === target;
    }).length;

    result[targetLevel] = targetCount;
  }

  return result;
};


/**
 * Calculates the wizard array metadata for the given step index.
 *
 * The metadata is an array of numbers, where each number corresponds to the
 * index of the path segment at that level in the given array of fields.
 * If a path segment is not present in the array of fields, the number is
 * undefined.
 *
 * @param steps - The array of wizard steps.
 * @param path - The dot-separated schema path as a string.
 * @param stepIndex - The index of the step for which to calculate the metadata.
 * @returns The array of indices.
 */
export const getWizardArrayMeta = (steps: Step[], path: string, stepIndex: number): (number | undefined)[] => {
  const stepsOnTheLeft = steps.slice(0, stepIndex + 1).filter((step) => step.type === StepTypes.Repeat);
  const repeatStepPaths = stepsOnTheLeft.map((step) => (step as RepeatStep).path);
  const repeatStepPathsArrays = repeatStepPaths.map(removeSchemaKeywordsFromPath);
  if (!repeatStepPathsArrays.length) {
    if (!path) return [];
    const depth = toPath(path).length;
    return new Array(depth).fill(0);
  }
  return getFieldIndices(repeatStepPathsArrays);
};

/**
 * Finds the index of the next `RepeatStep` to the right of the given index in the array of wizard steps.
 *
 * The function works by iterating over the array of wizard steps and checking if the step at the given index is a `RepeatStep`.
 * If it is, the function returns the index of the next `RepeatStep` in the array.
 * If no `RepeatStep` is found, the function returns `null`.
 *
 * @param steps - The array of wizard steps.
 * @param index - The index to the right of which to search for the next `RepeatStep`.
 * @returns The index of the next `RepeatStep` to the right of the given index, or `null` if no `RepeatStep` is found.
 */
export const getRepeatStepIndexToTheRight = (steps: Step[], index: number): number | null => {
  const nextRepeatToCurrentIndexStep = steps.findIndex((step, stepIndex) => {
    if (stepIndex <= index) return false;
    return step.type === StepTypes.Repeat;
  });
  if (nextRepeatToCurrentIndexStep === -1) {
    return null;
  }
  return nextRepeatToCurrentIndexStep;
};

/**
 * Finds the index of the next `RepeatStep` in the array of wizard steps that refers to the given index.
 *
 * The function works by iterating over the array of wizard steps and checking if the step is a `RepeatStep` and its `from` property is equal to the given index.
 * If it is, the function returns the index of the next `RepeatStep` in the array.
 * If no `RepeatStep` is found, the function returns `null`.
 *
 * @param steps - The array of wizard steps.
 * @param index - The index for which to find the next `RepeatStep`.
 * @returns The index of the next `RepeatStep` in the array of wizard steps that refers to the given index, or `null` if no `RepeatStep` is found.
 */
export const getNextRepeatStepIndex = (steps: Step[], index: number): number | null => {
  const nextRepeatToCurrentIndexStep = steps.findIndex((step) => {
    if (step.type !== StepTypes.Repeat) return false;
    return step.from === index;
  });
  if (nextRepeatToCurrentIndexStep === -1) {
    return null;
  }
  return nextRepeatToCurrentIndexStep;
};

/**
 * Checks if the given step is a `GuidedStep` and if it is necessary to add a new array item to the given path.
 *
 * The function works by:
 * 1. checking if the given step is a `GuidedStep`.
 * 2. checking if the given step is the first step in the array.
 * 3. checking if the given step is not the same as the previous step.
 * 4. if all conditions are met, the function creates a new array item at the given path.
 *
 * @param steps - The array of wizard steps.
 * @param stepIndex - The index of the step to check.
 * @param schema - The JSON schema of the current step.
 * @param wizardArrayMeta - The array of wizard array metadata.
 * @param force - Whether to force adding a new array item.
 * @param addNewItem - Whether to add a new array item.
 * @param formData - The current form data.
 * @returns Whether a new array item was added.
 */
export const addArrayItemIfRequired = (steps: Step[], stepIndex: number, schema: IJSONSchema, wizardArrayMeta: (number | undefined)[], force: boolean, addNewItem: boolean, formData?: IDataItem<unknown> ): boolean => {
  const step = steps[stepIndex];
  // New array items are possible only for 'guided' step
  if (step.type !== StepTypes.Guided) return false;
  // It is not possible to have items from different arrays at the same step, so we take first field path
  const path = step.fields[0];
  const currentArrayPath = getCurrentArrayPath(path, getCurrentDepth(path));
  const currentArrayPathAtFirstDepth = getCurrentArrayPath(path, 1);
  const prevStep = steps[stepIndex - 1];
  // If current array was never filled in the past, skip it
  const isFirstFill = steps.every((step, index) => {
    if (index >= stepIndex) return true;
    if (step.type !== StepTypes.Guided) return true;
    const path = step.fields[0];
    return !path.startsWith(currentArrayPathAtFirstDepth);
  });
  if (isFirstFill && !force) return false;
  if (prevStep.type !== StepTypes.Guided) {
    const isAnyOfArray = path.startsWith(`${currentArrayPath}.items.anyOf`);
    const anyOfIndex = isAnyOfArray ? _.toPath(path.replace(`${currentArrayPath}.items.anyOf.`, ''))[0] : undefined;
    createNewArrayItemAtPath(currentArrayPath, schema, formData, [], addNewItem);
    return true;
  }
  const prevPath = (prevStep as GuidedStep).fields[0];
  const prevStepArrayPath = getCurrentArrayPath(prevPath, getCurrentDepth(prevPath));
  if (currentArrayPath !== prevStepArrayPath) {
    createNewArrayItemAtPath(currentArrayPath, schema, formData, wizardArrayMeta, addNewItem);
    return true;
  }
  return false;
};

/**
 * Finds the index of the previous step that is not a RepeatStep in the given array of wizard steps.
 *
 * The function works by iterating over the array of wizard steps in reverse order and checking if the step is a RepeatStep.
 * If it is, the function decrements the index and continues the loop until it finds a step that is not a RepeatStep.
 * If no such step is found, the function returns 0.
 *
 * @param steps - The array of wizard steps.
 * @param currentStepIndex - The index of the current step.
 * @returns The index of the previous step that is not a RepeatStep.
 */
export const getPrevStepIndex = (steps: Step[], currentStepIndex: number): number => {
  let prevStepIndex = currentStepIndex - 1;
  while (steps[prevStepIndex].type === StepTypes.Repeat) {
    prevStepIndex = prevStepIndex - 1;
  }
  return prevStepIndex;
};

/**
 * Given a dot-separated path, returns the part of the path that is not part of an array.
 *
 * The function works by iterating over the path as an array and checking if the segment is "items".
 * If it is, the function checks if it is an array of arrays and if the array is at the end of the path.
 * If it is, the function returns the path without the "items" segment.
 * If not, the function checks if the next segment is "anyOf" and if it is, the function returns the path
 * without the "items.anyOf" segments.
 * If not, the function returns the path without the "items" segment.
 *
 * @param path - The dot-separated path.
 * @returns The part of the path that is not part of an array.
 */
const getArrayPath = (path: string): string => {
  let arraysCount = getCurrentDepth(path);
  let extendCount = 0;
  const pathAsArray = path.split('.');
  return pathAsArray.filter((segment, index) => {
    if (extendCount !== 0) {
      extendCount = extendCount - 1;
      return true;
    }
    if (segment === 'items') {
      if (arraysCount !== 1) {
        arraysCount = arraysCount - 1;
        return true;
      }
      if (pathAsArray[index + 1] === 'anyOf') {
        // If there is any of, we need to take anyOf and index
        extendCount = ANYOF_EXTEND_COUNT;
        arraysCount = arraysCount - 1;
        return true;
      }
      if (arraysCount === 1) {
        arraysCount = arraysCount - 1;
        return true;
      }
    }
    return arraysCount !== 0;
  }).join('.');
};

/**
 * Generates a `RepeatStep` object for a given schema path and depth.
 *
 * The function determines the array path at the specified depth and
 * creates a `RepeatStep` with the necessary information, such as type,
 * origin index, question, and path. If the schema contains an `anyOf`
 * clause at the specified array path, the `RepeatStep` is marked as
 * deactivated.
 *
 * @param schema - The JSON schema to reference for creating the `RepeatStep`.
 * @param path - The dot-separated schema path as a string.
 * @param depth - The specified depth of the array path.
 * @param index - The index from which the `RepeatStep` originates.
 * @returns A `RepeatStep` object containing the type, origin, question, path,
 * and optionally, deactivation status.
 */
const getRepeatStep = (schema: IJSONSchema, path: string, depth: number, index: number): RepeatStep => {
  const arrayPath = getCurrentArrayPath(path, depth);
  // Handle case with anyOf
  if (!schema?.properties?.[arrayPath!]?.items?.anyOf) {
    const arrayTitle = arrayPath ? _.get(schema, `properties.${arrayPath}`)?.title : '';
    return {
      type: StepTypes.Repeat,
      from: index!,
      question: i18n.t('common.wizardArrayQuestion', { arrayTitle }),
      path: arrayPath!
    };
  } else {
    const arrayTitle = arrayPath ? _.get(schema, `properties.${arrayPath}`)?.title : '';
    return {
      type: StepTypes.Repeat,
      from: index!,
      question: i18n.t('common.wizardArrayQuestion', { arrayTitle }),
      path: arrayPath!,
      deactivated: true,
    };
  }
};

/**
 * Deletes a key from the current array path map and returns a RepeatStep object associated with the removed key.
 *
 * The RepeatStep object is created by calling `getRepeatStep` with the provided schema, the field path associated with the removed key, the depth of the array path, and the start index associated with the removed key.
 *
 * After creating the RepeatStep object, the key is deleted from the current array path map.
 *
 * @param schema - The JSON schema to reference for creating the RepeatStep.
 * @param keyToRemove - The key to be removed from the current array path map.
 * @param currentArrayPathMap - The current array path map.
 * @returns A RepeatStep object containing the type, origin, question, path, and optionally, deactivation status.
 */
const deleteArrayFromMap = (schema: IJSONSchema, keyToRemove: string, currentArrayPathMap: Record<string, { depth: number, startIndex: number, fieldPath: string }>): RepeatStep => {
  const repeatStep = getRepeatStep(schema, currentArrayPathMap[keyToRemove].fieldPath, currentArrayPathMap[keyToRemove].depth, currentArrayPathMap[keyToRemove].startIndex);
  delete currentArrayPathMap[keyToRemove];
  return repeatStep;
};


/**
 * Deletes multiple keys from the current array path map and returns an array of RepeatStep objects associated with the removed keys.
 *
 * The RepeatStep objects are created by calling `deleteArrayFromMap` for each key to be removed, with the provided schema, the field path associated with the removed key, the depth of the array path, and the start index associated with the removed key.
 *
 * After creating the RepeatStep objects, the keys are deleted from the current array path map.
 *
 * The RepeatStep objects are then sorted in descending order by their `from` index, and in case of equal `from` indexes, deactivated steps come after non-deactivated steps.
 *
 * @param schema - The JSON schema to reference for creating the RepeatStep.
 * @param keyToRemove - The keys to be removed from the current array path map.
 * @param currentArrayPathMap - The current array path map.
 * @returns An array of RepeatStep objects containing the type, origin, question, path, and optionally, deactivation status.
 */
const deleteArraysFromMap = (schema: IJSONSchema, keyToRemove: string[], currentArrayPathMap: Record<string, { depth: number, startIndex: number, fieldPath: string }>): RepeatStep[] => {
  const repeatSteps: RepeatStep[] = keyToRemove.map((key) => deleteArrayFromMap(schema, key, currentArrayPathMap));
  return repeatSteps.sort((a, b) => {
    if (a.from === b.from) {
      if (a.deactivated && !b.deactivated) {
        return 1;
      }
      if (!a.deactivated && b.deactivated) {
        return -1;
      }
      return 0;
    }
    return b.from - a.from;
  });
};

/**
 * Inserts an array path into the current array path map with the specified field depth and index.
 *
 * This function updates the current array path map by adding the given field's array path.
 * If the field depth is greater than 1, it also ensures that the root level array path
 * is added to the map if it is not already present.
 *
 * @param field - The field for which the array path is to be inserted.
 * @param fieldArrayPath - The full array path of the field.
 * @param index - The index at which the field is located.
 * @param fieldDepth - The depth level of the field in the array structure.
 * @param currentArrayPathMap - The map containing the current array paths with their details.
 * @param repeatSteps - The array of RepeatStep objects, used to adjust the start index.
 */
const insertArrayToMap = (field: string, fieldArrayPath: string, index: number, fieldDepth: number, currentArrayPathMap: Record<string, { depth: number, startIndex: number, fieldPath: string }>, repeatSteps: RepeatStep[]) => {
  if (fieldDepth > 1) {
    // Get array path at level 1
    const rootLevelPath = getCurrentArrayPath(field, 1);
    const rootLevelArrayPath = getArrayPath(fieldArrayPath);
    if (!currentArrayPathMap[rootLevelArrayPath]) {
      currentArrayPathMap[rootLevelArrayPath] = { depth: 1, startIndex: index + repeatSteps.length, fieldPath: rootLevelPath };
    }
  }
  currentArrayPathMap[getArrayPath(field)] = { depth: fieldDepth, startIndex: index + repeatSteps.length, fieldPath: fieldArrayPath };
};

/**
 * Handles the termination and initialization of array paths during schema processing.
 *
 * This function checks if the current field is a regular field or part of an array.
 * If it's a regular field, it returns repeat steps for all finished arrays.
 * If it's part of an array, it updates the array path map by inserting new arrays
 * or finalizing existing ones as needed.
 *
 * @param schema - The JSON schema to reference for creating RepeatSteps.
 * @param field - The field path as a string.
 * @param index - The index of the current step in the processing sequence.
 * @param currentArrayPathMap - A map of current array paths with their depth,
 * start index, and field path.
 * @returns An array of RepeatStep objects representing finalized array paths.
 */
const checkAndHandleArrayEnd = (schema: IJSONSchema, field: string, index: number, currentArrayPathMap: Record<string, { depth: number, startIndex: number, fieldPath: string }>): RepeatStep[] => {
  const currentArrayKeys = Object.keys(currentArrayPathMap);
  const fieldDepth = getCurrentDepth(field);
  const fieldArrayPath = getCurrentArrayPath(field, fieldDepth);
  const fieldPath = getArrayPath(field);
  if (isRegularField(field)) {
    if (!currentArrayKeys.length) return [];
    else {
      // Return repeat step if step is regular, meaning that all arrays are finished
      return deleteArraysFromMap(schema, currentArrayKeys, currentArrayPathMap);
    }
  } else {
    // If there is no started array, add it to map
    if (!currentArrayKeys.length) {
      insertArrayToMap(field, fieldArrayPath, index, fieldDepth, currentArrayPathMap, []);
      return [];
    }
    // If array is not started yes, add it
    if (!currentArrayKeys.includes(fieldPath)) {
      const matchingArrays = currentArrayKeys.filter((key) => {
        if (fieldPath.startsWith(key)) return true;
        return false;
      });
      if (!matchingArrays.length) {
        // Nothing is matched, meaning that it is a different array
        const repeatSteps = deleteArraysFromMap(schema, currentArrayKeys, currentArrayPathMap);
        insertArrayToMap(field, fieldArrayPath, index, fieldDepth, currentArrayPathMap, repeatSteps);
        return repeatSteps;
      } else {
        // Finish not matched arrays
        const notMatchedArrays = currentArrayKeys.filter((key) => !matchingArrays.includes(key));
        const repeatSteps = deleteArraysFromMap(schema, notMatchedArrays, currentArrayPathMap);
        // Start new array
        insertArrayToMap(field, fieldArrayPath, index, fieldDepth, currentArrayPathMap, repeatSteps);
        return repeatSteps;
      }
    } else {
      // Check path of field and current array path
      const currentArray = currentArrayPathMap[fieldPath];
      if (field.startsWith(currentArray.fieldPath)) {
        // It is the same array, do nothing
        return [];
      } else {
        const repeatSteps = deleteArraysFromMap(schema, currentArrayKeys, currentArrayPathMap);
        // Finish existing array and add current
        insertArrayToMap(field, currentArray.fieldPath, index, fieldDepth, currentArrayPathMap, repeatSteps);
        return repeatSteps;
      }
    }

  }
};

const getStepDescriptionsFromSchema = (schema: IJSONSchema): string[] => {
  if (!schema[WIZARD_STEP_DESCRIPTION_PATH]) return [];
  try {
    return JSON.parse(schema[WIZARD_STEP_DESCRIPTION_PATH]);
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * Generates an array of steps from the given JSON schema.
 *
 * The function flattens the schema properties and processes properties
 * marked with 'wizardGroup' to determine their step groupings. It categorizes
 * fields into steps based on their group numbers, creating either regular or guided steps.
 * If fields belong to arrays, it manages the array paths and ensures correct step
 * sequencing for repeatable fields.
 *
 * The function also finalizes any open array paths at the end of processing.
 *
 * @param schema - The JSON schema containing properties for step generation.
 * @returns An array of Step objects representing the sequence of steps derived from the schema.
 */
export const getStepsFromSchema = (schema: IJSONSchema) => {
  const result: Step[] = [];
  const schemaFlat = flatten(schema.properties) as Record<string,unknown>;
  const groupProperties = Object.keys(schemaFlat).filter((key) => key.endsWith('wizardGroup')) as string[];
  const fieldsOnSteps: Record<number, string[]> = {};

  const stepDescriptions = getStepDescriptionsFromSchema(schema);

  for (const property of groupProperties) {
    const propertyPath = property.replace('.cp_ui.wizardGroup', '');
    const groupNumber = schemaFlat[property] as number;
    if (fieldsOnSteps[groupNumber]) {
      fieldsOnSteps[groupNumber].push(propertyPath);
    } else {
      fieldsOnSteps[groupNumber] = [propertyPath];
    }
  }

  const currentArrayPathMap: Record<string, { depth: number, startIndex: number, fieldPath: string }> = {};

  for (const step of Object.keys(fieldsOnSteps)) {
    const fields = fieldsOnSteps[step as unknown as number];
    const stepDescription = stepDescriptions[step as unknown as number];
    const field = fields[0];
    if (fields.every(isRegularField)) {
      const repeatStep = checkAndHandleArrayEnd(schema, field, result.length, currentArrayPathMap);
      result.push(...repeatStep);
      result.push({
        type: StepTypes.Regular,
        fields: fields,
        description: stepDescription || undefined
      });
    } else {
      const repeatStep = checkAndHandleArrayEnd(schema, field, result.length, currentArrayPathMap);
      result.push(...repeatStep);
      result.push({
        type: StepTypes.Guided,
        fields: fields,
        description: stepDescription || undefined
      });
    }
  }

  // Finish opened arrays
  const repeatSteps = deleteArraysFromMap(schema, Object.keys(currentArrayPathMap), currentArrayPathMap);
  result.push(...repeatSteps);

  return result;
};
