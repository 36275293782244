import { EventEmitter } from 'events';

import { IFilePropertyJsonPath, IJSONSchema, Schemas } from '@cp/base-types';
import {
  IFilter,
  ODataPropsFilter,
  cloneDeepWithMetadata,
  filterItemsByClientFilter,
  getFilePropertyJsonPaths,
  getODataFilter,
  isDefinedAndNotEmpty,
  parseFilterMessage,
} from '@cp/base-utils';
import { GenericScreenContext, TableKeyPrefix, filterQueryKeyName } from '@cpa/base-core/constants';
import { IMatchedRelatedLink, QueryFilterManager, cleanUpInternalProperties, filterSchema, setParentForItem } from '@cpa/base-core/helpers';
import {
  ActionSourceType,
  ActionType,
  IActionDescriptor,
  useActionHandler,
  useCpaPageActionClickHandler,
  useCpaPageUserConfigurationValue,
  useCustomActions,
  useDebouncedValue,
  useDownload,
  useIsCached,
  useItemIdParam,
  usePowerUser,
  useQuery,
  useSyncedPageSettings,
  useTableActions,
} from '@cpa/base-core/hooks';
import { IGlobalState } from '@cpa/base-core/store';
import {
  ActionSelection,
  BaseApi,
  IAction,
  IDataItem,
  IGenericComponentProps,
  IScrollableContent,
  IScrollingContentProps,
  ITableProps,
  OrderDirection,
} from '@cpa/base-core/types';
import { createCancelToken, isCancelError } from '@cpa/base-http';
import {
  Callout,
  IObjectWithKey,
  IScrollablePane,
  Link,
  MessageBarType,
  ScrollablePane,
  Selection,
  SelectionMode,
  StickyPositionType,
  Target,
  ThemeContext,
} from '@fluentui/react';
import { usePrevious } from '@fluentui/react-hooks';
import { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import { push, replace, RouterLocation } from 'connected-react-router';
import * as _ from 'lodash';
import React, { createContext, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactResizeDetector, { useResizeDetector } from 'react-resize-detector';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useRouteMatch } from 'react-router';
import urlJoin from 'url-join';
// import { getCustomActionsForSelection } from '@cpa/base-core/helpers/actions';

import Drawer from '../../components/Drawer/Drawer';
import EmptySpace from '../../components/EmptySpace/EmptySpace';
import ItemInfo from '../../components/ItemInfo/ItemInfo';
import MessageBars from '../../components/MessageBars/MessageBars';
import { CustomRowTemplates, ScreenTypesConfiguration } from '../../mapping';
import GenericScreen from '../../screens/GenericScreen/GenericScreen';
import { TourContext } from '../Layout/Layout';
import { ScrollablePaneContext } from '../ScrollablePaneContextProvider/ScrollablePaneContextProvider';

import styles from './ScrollingContent.module.scss';
import Actions, { IActionsRef } from './components/Actions/Actions';
import { ChangeBaseLanguageDialog, IChangeBaseLanguageDialogRef } from './components/Dialogs/ChangeBaseLanguage/ChangeBaseLanguageDialog';
import { CompareDialog, ICompareDialogRef } from './components/Dialogs/CompareDialog/CompareDialog';
import { CopyDialog, ICopyDialogRef } from './components/Dialogs/CopyDialog/CopyDialog';
import { IMergeIntoDialogRef, MergeIntoDialog } from './components/Dialogs/MergeIntoDialog/MergeIntoDialog';
import LocalizationsDrawer from './components/LocalizationsDrawer/LocalizationsDrawer';
import PageCardView from './components/PageCardView/PageCardView';
import { Sticky } from './components/Sticky/Sticky';
import Table, { TableComponentType } from './components/Table/Table';
import { ITableDragDropParams, useTableDragDrop } from './components/Table/hooks/useTableDragDrop';
import TopBarContent from './components/TopBarContent/TobBarContent';
import VersionsDrawer from './components/VersionsDrawer/VersionsDrawer';
import WidgetCardView from './components/WidgetCardView/WidgetCardView';
import { copyItemToPage } from './utils/copy';
import { mergeItem } from './utils/merge';
import { fetchSharedItem } from './utils/share';

export interface IDataCallbackContext {
  onFilteredDataUpdated?: (page: Schemas.CpaPage, items: IDataItem[]) => void;
  onDataUpdated?: (page: Schemas.CpaPage, items: IDataItem[]) => void;
  onDataModification?: (page: Schemas.CpaPage, itemData: IDataItem[]) => void;
}

export interface ITableContext {
  availableActions?: IActionDescriptor[];
  relatedActionHandler?: (actionItems: IDataItem[], relatedLink: IMatchedRelatedLink) => void;
  schema?: IJSONSchema | null;
  disableItemSharing?: boolean;
  handleItemOpen?: (item: IDataItem, forceReadonly: boolean, forceTargetPagePath?: string) => void;
  isWidget?: boolean;
}

export interface IWizardOptions {
  isFullScreen?: boolean;
  isWizardMode?: boolean;
  steps?: number[];
  requiredOnly?: boolean;
  fields?: string[];
}

export const DataCallbackContext = createContext<null | IDataCallbackContext>(null);
export const TableContext = createContext<null | ITableContext>(null);

export enum doubleClickActions {
  VIEW = 'http://platform.cosmoconsult.com/ontology/View',
  EDIT = 'http://platform.cosmoconsult.com/ontology/Edit',
  UNDEFINED = 'http://platform.cosmoconsult.com/ontology/Undefined',
}

const getCustomActionsForSelection = (
  selectedItems: IDataItem[],
  page: Schemas.CpaPage,
  schema: IJSONSchema | null,
  user?: string
): IActionDescriptor[] => {
  const result: IActionDescriptor[] = [];
  if (page.customRowTemplate?.identifier) {
    for (const customAction of CustomRowTemplates[page.customRowTemplate.identifier]?.options?.table?.customActions || []) {
      const canShow = customAction.canShow(selectedItems, page, schema, user);
      if (!canShow && !customAction.replaceDefaultAction) {
        continue;
      }
      result.push({ type: ActionType.TemplateCustomAction, templateCustomAction: { ...customAction, hidden: !canShow } });
    }
  }
  return result;
};

const genericComponentProps: Partial<IGenericComponentProps> = {
  hideSettings: true,
  hideMoreButton: true,
  scrollingContentProps: {
    disableItemSharing: true,
    widgetHeight: '70vh',
  },
};

const STICKY_OFFSET = 25;

const generateReloadEventEmitter = (): EventEmitter => {
  const eventEmitter = new EventEmitter();
  eventEmitter.setMaxListeners(0);
  return eventEmitter;
};

const generateSelectionEventEmitter = (): EventEmitter => {
  const eventEmitter = new EventEmitter();
  eventEmitter.setMaxListeners(0);
  return eventEmitter;
};

const initialContextCallout = {
  isOpened: false,
  top: 0,
  left: 0,
  target: null as Target,
};

const SIMPLE_VIEW_WIZARD_OPTIONS: IWizardOptions = {
  isFullScreen: true,
  isWizardMode: true,
};

const ScrollingContent = React.forwardRef(function _ScrollingContent(
  {
    data,
    isWidget,
    onCopy,
    preFillItems,
    onAdd,
    onEdit,
    onDelete,
    loadItems,
    isODataSupportedByEndpoint,
    pageSize,
    hiddenInTable,
    hideFilter,
    multilineFields,
    initialAction,
    hideHeader,
    widgetCardView,
    pageCardView,
    disableStickyHeader,
    disableDoubleClick,
    disableStickyActions,
    chart,
    hideSelectedLabel,
    hideActions,
    selectionMode,
    onItemClick,
    initialFilterValue,
    disabledManagedColumnsWidth,
    customActions,
    parentPropertyJsonPath,
    disableItemSharing,
    disableDragDrop,
    disableAddButtonInTable,
    tableKey,
    onItemsSelect,
    showItemLinksInTable,
    tableProps,
    widgetHeight,
    forceKeySelection,
    onFilterValueChange,
    defaultFilterExpanded,
    widgetMaxHeight,
  }: IScrollingContentProps,
  ref: React.Ref<IScrollableContent>
): JSX.Element | null {
  const genericScreenContext = useContext(GenericScreenContext);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState<string[]>([]);
  const [infoMessages, setInfoMessages] = useState<(string | JSX.Element)[]>([]);
  const [selectedItems, setSelectedItems] = useState<IDataItem[]>([]);
  const selectedItemsPerTable = useRef<Record<string, IDataItem[]>>({});
  const pageSettings = useSelector((state: IGlobalState) => state.settings.pages[data.page.identifier!]);
  const powerUser = usePowerUser(tableKey.split('.')[0] as TableKeyPrefix | undefined, data.page.cpTypeUrl);
  const isActionExecuted = useRef(false);
  const location = useLocation();
  const routeMatch = useRouteMatch();
  const isCached = useIsCached();
  const theme = useContext(ThemeContext);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const updatePageSetting = useSyncedPageSettings(false, true);

  const [topbarContentHeight, setTopbarContentHeight] = useState<number>(0);

  useEffect(() => {
    isActionExecuted.current = false;
  }, [location]);

  const currentLocation = useSelector((state: IGlobalState) => state.router.location) as RouterLocation<{
    externalDataQuery?: Record<string, string>;
    filterValue: string | undefined;
    breadcrumbsStructure?: IDataItem[];
  }>;
  const previousPageCardView = usePrevious(pageCardView);
  const previousWidgetCardView = usePrevious(widgetCardView);

  useEffect(() => {
    const previousState = currentLocation.state && typeof currentLocation.state === 'object' ? currentLocation.state : {};
    const currentState = currentLocation.state;

    if (
      currentState?.breadcrumbsStructure &&
      previousPageCardView !== undefined &&
      previousWidgetCardView !== undefined &&
      (previousPageCardView !== pageCardView || previousWidgetCardView !== widgetCardView)
    ) {
      dispatch(
        push({
          state: {
            ...previousState,
            breadcrumbsStructure: null,
            externalDataQuery: {},
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousPageCardView, pageCardView, previousWidgetCardView, widgetCardView]);

  const relatedActionHandler = useCallback(
    (actionItems: IDataItem[], relatedLink: IMatchedRelatedLink) => {
      if (relatedLink != null) {
        const page = relatedLink.matched;
        const { originalQuery, fullMatch } = relatedLink;
        const dataQuery = Object.fromEntries(new URLSearchParams(encodeURI(originalQuery || '')).entries());
        // Check page displayRelatedPageAsDrawer and display as drawer or redirect user
        if (page.displayRelatedPageAsDrawer || page.identifier === data.page.identifier) {
          // Open drawer with generic screen
          setRelatedPayload([page, dataQuery]);
        } else if (page.path) {
          // Redirect user
          // Path match, we need to pass odata query
          dispatch(
            push(
              page.path,
              !fullMatch
                ? {
                    externalDataQuery: dataQuery,
                    originPage: data.page,
                    originItem: actionItems[0],
                  }
                : undefined
            )
          );
        } else {
          console.warn(`Page ${page.name} doesn't have path to redirect`);
        }
      }
    },
    [data.page, dispatch]
  );

  const filePropertyJsonPaths: IFilePropertyJsonPath[] = useMemo(() => (data.schema ? getFilePropertyJsonPaths(data.schema) : []), [data.schema]);
  const downloadableFilePropertyJsonPaths: IFilePropertyJsonPath[] = useMemo(
    () => filePropertyJsonPaths.filter(({ downloadable }) => downloadable),
    [filePropertyJsonPaths]
  );

  const handleItemOpen = useCallback(
    (item: IDataItem, forceReadonly: boolean = false, forceTargetPagePath?: string) => {
      const currentLocationState = (currentLocation?.state || {}) as { breadcrumbsStructure?: IDataItem[] };
      setErrors([]);
      // Default is edit if modification is allowed (unless forceReadonly)
      const openSingleItemPage = (!data.page.allowModify || forceReadonly) && data.page.singleItemTemplate;
      if (openSingleItemPage) {
        const keyProp = item.identifier ? 'identifier' : '__identifier';
        const pathname = urlJoin(
          (forceTargetPagePath || data.page.path)?.replace('/:id', '') || '/',
          encodeURIComponent(item[keyProp]?.toString() || '-')
        );
        dispatch(push({ pathname, state: { breadcrumbsStructure: currentLocationState.breadcrumbsStructure } }));
      } else {
        setContentOptions({
          activeItem: item,
          isDrawerOpened: true,
          isVersionsOpened: false,
          isLocalizationsOpened: false,
          drawerOptions: {
            readonly: forceReadonly,
            type: 'edit',
          },
        });
      }
    },
    [currentLocation?.state, data, dispatch]
  );

  const {
    actions: availableActions,
    contextMenuActions: availableContextActions,
    formSchemas,
  } = useTableActions(
    data,
    selectedItems,
    disableItemSharing,
    !!loadItems && !isWidget,
    downloadableFilePropertyJsonPaths,
    getCustomActionsForSelection,
    ActionSourceType.Table
  );

  const tableContextValue = useMemo(() => {
    return {
      relatedActionHandler: relatedActionHandler,
      availableActions: availableActions,
      schema: data.schema,
      disableItemSharing: disableItemSharing,
      handleItemOpen: handleItemOpen,
      isWidget: isWidget,
    };
  }, [relatedActionHandler, availableActions, data.schema, disableItemSharing, handleItemOpen, isWidget]);

  const tourContext = useContext(TourContext);

  useEffect(() => {
    tourContext?.tourEventEmitter.current?.emit('forceBubbleCheck');
  }, [selectedItems, tourContext?.tourEventEmitter]);

  const isSortedOnServerSide = useSelector((state: IGlobalState) => {
    if (data.page.dataEndpoint?.identifier) {
      const isDataService = state.app.dataEndpoints.get(data.page.dataEndpoint.identifier)?.dataType === BaseApi.DataService;
      if (isDataService && data.page.dataUrl?.startsWith('/rss')) {
        return false;
      } else {
        return isDataService;
      }
    }
    return false;
  });

  const [contentOptions, setContentOptions] = useState<{
    activeItem: IDataItem | null;
    isDrawerOpened: boolean;
    isVersionsOpened: boolean;
    isLocalizationsOpened: boolean;
    drawerOptions: {
      readonly?: boolean;
      prefill?: IDataItem;
      customAddHandler?: (defaultAddHandler: typeof onAdd, item: IDataItem, reloadCurrentItems: () => void) => Promise<unknown>;
      type: 'add' | 'edit';
      wizardOptions?: IWizardOptions;
      customSchema?: IJSONSchema;
    };
    versionsOptions?: {
      comparedVersions: [string, string];
    };
    presetOptions?: {
      preset: {};
      wizardOptions?: IWizardOptions;
    };
  }>({
    activeItem: null,
    isDrawerOpened: false,
    isVersionsOpened: false,
    isLocalizationsOpened: false,
    drawerOptions: { type: 'add' },
  });

  const copyDialogRef = useRef<ICopyDialogRef>(null);
  const mergeIntoDialogRef = useRef<IMergeIntoDialogRef>(null);
  const compareDialogRef = useRef<ICompareDialogRef>(null);
  const changeBaseLanguageDialogRef = useRef<IChangeBaseLanguageDialogRef>(null);
  const actionsRef = useRef<IActionsRef>(null);
  const isLazyLoading = useRef<boolean>(false);

  const isMobileDevice = useMediaQuery({ query: '(max-width: 699px)' });

  const [relatedPayload, setRelatedPayload] = useState<[Schemas.CpaPage, Record<string, string>] | null>(null);
  const resetRelatedPayload = useCallback((): void => setRelatedPayload(null), []);

  const [filterValue, setFilterValue] = useState(initialFilterValue || '');
  const firstEntered = useRef(false);
  useEffect(() => {
    setFilterValue(initialFilterValue || '');
    firstEntered.current = false;
  }, [initialFilterValue]);

  const [debouncedFilterValue, forceSetDebouncedFilterValue] = useDebouncedValue(filterValue, 600);

  const updateFilterValueFromState = useCallback(() => {
    if (currentLocation.state && typeof currentLocation.state === 'object') {
      const currentState = currentLocation.state;

      if (pageCardView && !isWidget && (currentState.filterValue || currentState.filterValue === '') && currentState.filterValue !== filterValue) {
        setFilterValue(currentState.filterValue);
      }
    }
  }, [currentLocation.state, pageCardView, isWidget, setFilterValue, filterValue]);

  const saveFilterValueToState = useCallback(
    async (valueToSet: string): Promise<void> => {
      const previousState = currentLocation.state;

      if (pageCardView && !isWidget && previousState?.filterValue !== valueToSet && (previousState?.filterValue?.length || valueToSet.length)) {
        dispatch(
          replace({
            state: {
              ...(previousState || {}),
              filterValue: valueToSet,
            },
          })
        );
      }
    },
    [dispatch, pageCardView, isWidget, currentLocation.state]
  );

  useEffect(() => {
    saveFilterValueToState(filterValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue]);

  // We will get filter value from state only is we navigating inside card view (externalDataQuery will be changed).
  useEffect(() => {
    updateFilterValueFromState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation.state?.externalDataQuery]);

  const [order, setOrder] = useState<{ isAscending: boolean; columnKey: string } | undefined>(() => {
    if (data.page.initialOrderPropertyJsonPath && !tableContextValue.schema?.cp_vectorizable) {
      return {
        columnKey: _.toPath(data.page.initialOrderPropertyJsonPath)[0],
        isAscending: data.page.initialOrderDirection !== OrderDirection.DESCENDING,
      };
    }
    return undefined;
  });
  const [t, i18n] = useTranslation();

  const isAllDataLoaded = data.totalItems !== undefined ? data.totalItems <= data.items?.length : true;

  useEffect(() => {
    if (onFilterValueChange) {
      onFilterValueChange(debouncedFilterValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilterValue]);

  const parsedFilters: IFilter[] = useMemo(
    () => [parseFilterMessage(debouncedFilterValue)].filter(isDefinedAndNotEmpty) as IFilter[],
    [debouncedFilterValue]
  );

  const odataFilter: ODataPropsFilter | undefined = useMemo(() => {
    const composedFilter = [...(parsedFilters || []).map((parsedFilter) => getODataFilter(parsedFilter || {}))].filter(isDefinedAndNotEmpty);
    return composedFilter.length > 1 ? { and: composedFilter } : composedFilter[0];
  }, [parsedFilters]);

  useEffect(() => {
    if (genericScreenContext?.latestUserFilter) {
      genericScreenContext.latestUserFilter.current = odataFilter;
    }
  }, [genericScreenContext, odataFilter]);

  const tableRef = useRef<TableComponentType>(null);
  const emptyRef = useRef<HTMLDivElement>(null);
  const cardViewRef = useRef<HTMLDivElement>(null);
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);
  const colorizedRows = useSelector((state: IGlobalState) => state.settings.zebraRows);

  // Raw items received from backend, just filtered
  const filteredItems = useMemo(() => {
    if (isODataSupportedByEndpoint) {
      return data.items;
    }

    // Frontend filtering (Disabled if odata is in use)
    let filteredDataItems = data.items;
    for (const parsedFilter of parsedFilters) {
      if (isDefinedAndNotEmpty(parsedFilter)) {
        filteredDataItems = filterItemsByClientFilter(filteredDataItems, data.schema, parsedFilter);
      }
    }
    return filteredDataItems;
  }, [data.items, data.schema, isODataSupportedByEndpoint, parsedFilters]);

  const scrollablePaneContext = useContext(ScrollablePaneContext);

  const [contextCallout, setContextCallout] = useState(initialContextCallout);
  const dismissContextCallout = useCallback(() => setContextCallout(initialContextCallout), []);

  const onItemRightClick = useCallback(
    (item: IDataItem, target: string, e: React.MouseEvent) => {
      if (hideActions) {
        return;
      }

      const actionsHeight = actionsRef.current?.getButtonsHeight() || 0;

      setContextCallout({
        target: `#table-item--${target}`,
        isOpened: true,
        top: e.clientY + actionsHeight >= window.innerHeight ? e.clientY - actionsHeight : e.clientY,
        left: e.clientX,
      });
    },
    [hideActions]
  );

  const handleItemsSelect = useCallback(
    (selectedItems: IDataItem[], tableKey: string = 'default', unmount: boolean = false) => {
      selectedItemsPerTable.current = {
        ...selectedItemsPerTable.current,
        [tableKey]: selectedItems,
      };

      const newSelectedItems = Object.values(selectedItemsPerTable.current).flat();
      setSelectedItems(newSelectedItems);

      if (!unmount) {
        onItemsSelect?.(
          newSelectedItems,
          forceKeySelection?.filter((forcedKey) => selection.current.getItemIndex(forcedKey) < 0)
        );
      }
    },
    [forceKeySelection, onItemsSelect]
  );

  // Items reload
  const reloadEmitter = useMemo(() => generateReloadEventEmitter(), []);
  const selectionEmitter = useMemo(() => generateSelectionEventEmitter(), []);

  const reloadCurrentItems = useCallback(async () => {
    try {
      await loadItems?.(
        isODataSupportedByEndpoint
          ? {
              skip: 0,
              orderBy: order && [[order.columnKey, order.isAscending ? 'asc' : 'desc']],
              top: data.items.length + 1,
              filter: odataFilter,
            }
          : {},
        { overwriteExistingData: true, throwOnCancel: true }
      );
    } catch (e) {
      if (!isCancelError(e)) {
        throw e;
      }
    }

    reloadEmitter.emit('reload');
  }, [reloadEmitter, isODataSupportedByEndpoint, data, order, odataFilter, loadItems]);

  // Clear selection
  const clearTableSelection = useCallback(() => {
    selection.current.setAllSelected(false);
  }, []);

  // Actions handling
  const handleOnEdit = useCallback(
    async (item: IDataItem) => {
      if (onEdit && contentOptions.activeItem) {
        const updatedData = await onEdit(item, contentOptions.activeItem);
        reloadCurrentItems();
        setContentOptions({ ...contentOptions, activeItem: updatedData as IDataItem });
      }
    },
    [onEdit, contentOptions, reloadCurrentItems]
  );
  const handleOnAdd = useCallback(
    async (item: IDataItem) => {
      if (onAdd) {
        const savedData = contentOptions.drawerOptions.customAddHandler
          ? await contentOptions.drawerOptions.customAddHandler(onAdd, item, reloadCurrentItems)
          : await onAdd(item);

        // Automatically reload content only if case of default add handler
        if (!contentOptions.drawerOptions.customAddHandler) {
          reloadCurrentItems();
        }

        setContentOptions({
          ...contentOptions,
          activeItem: savedData as IDataItem,
          drawerOptions: { ...contentOptions.drawerOptions, type: 'edit' },
        });
      }
    },
    [onAdd, reloadCurrentItems, contentOptions]
  );
  const handleOnCopy = useCallback(
    async (item: IDataItem): Promise<IDataItem | undefined | void> => {
      if (onCopy) {
        const clonedItem = await onCopy(item);
        const unwantedParts = ['div', '/div'];
        let parts: string[] = t('common.clonedMessage', {
          name: clonedItem?.name,
          newIdentifier: clonedItem?.identifier,
        })
          .split(/[<>]/g)
          .filter((item: string) => !unwantedParts.includes(item));

        if (parts.length > 3) {
          parts = [parts.slice(0, 3).join(''), ...parts.slice(3)];
        }

        const url =
          data.page.path && clonedItem?.identifier
            ? urlJoin(data.page.path.replace('/:id', ''), `?filter=${encodeURIComponent(JSON.stringify({ identifier: clonedItem?.identifier }))}`)
            : '';
        const message = (
          <>
            {parts[0]}
            <Link className={styles.cloneLink} href={url} target="_blank" rel="noreferrer">
              {parts[1]}
            </Link>
            {parts[2]}
          </>
        );
        await reloadCurrentItems();
        setInfoMessages([message]);
        return clonedItem;
      }
      return;
    },
    [data.page.path, onCopy, reloadCurrentItems, t]
  );
  const handleOnDelete = useCallback(
    async (items: IDataItem[]) => {
      if (onDelete) {
        await onDelete(items);
        await reloadCurrentItems();
      }
    },
    [onDelete, reloadCurrentItems]
  );

  const handleOnSubmitAs = useCallback(
    async (item: IDataItem) => {
      if (onCopy && onEdit) {
        const clonedEntity = await onCopy(item);
        if (clonedEntity) {
          const clonedItem = cloneDeepWithMetadata(item);
          clonedItem.identifier = clonedEntity?.identifier;
          clonedItem._eTag = clonedEntity?._eTag;
          if (clonedEntity) {
            await onEdit(clonedItem, clonedEntity);
            clearTableSelection();
            await reloadCurrentItems();
          }
        }
      }
    },
    [clearTableSelection, onCopy, onEdit, reloadCurrentItems]
  );

  const onCardClick = useCallback(
    (item: IDataItem, forceTargetPagePath?: string) => {
      handleItemOpen(item, true, forceTargetPagePath);
    },
    [handleItemOpen]
  );

  const handleDownload = useDownload(data.page.dataEndpoint?.identifier);

  const onAddClick = useCallback(
    (
      underlyingItem?: IDataItem,
      customAddHandler?: typeof contentOptions.drawerOptions['customAddHandler'],
      customSchema?: IJSONSchema,
      customPrefill?: IDataItem
    ) => {
      setErrors([]);

      const prefill = customPrefill ? cloneDeepWithMetadata(customPrefill) : {};

      if (!underlyingItem || !parentPropertyJsonPath) {
        setContentOptions({
          activeItem: null,
          isDrawerOpened: true,
          isVersionsOpened: false,
          isLocalizationsOpened: false,
          drawerOptions: { type: 'add', prefill, customAddHandler, customSchema },
        });
        return;
      }

      const originalItem = (underlyingItem.__originalItem as IDataItem) || underlyingItem;
      if (!originalItem.identifier && !originalItem.__identifier) {
        setContentOptions({
          activeItem: null,
          isDrawerOpened: true,
          isVersionsOpened: false,
          isLocalizationsOpened: false,
          drawerOptions: { type: 'add', prefill, customAddHandler, customSchema },
        });
        return;
      }

      const parentIdentifier = originalItem.identifier || originalItem.__identifier;
      if (parentIdentifier) {
        setParentForItem(prefill, parentPropertyJsonPath, parentIdentifier, data.schema);
      }

      setContentOptions({
        activeItem: null,
        isDrawerOpened: true,
        isVersionsOpened: false,
        isLocalizationsOpened: false,
        drawerOptions: { type: 'add', prefill: { ...prefill, ...customPrefill }, customAddHandler, customSchema },
      });
    },
    [contentOptions, parentPropertyJsonPath, data.schema]
  );

  const selectedItemsCount = hideSelectedLabel || (isWidget && widgetCardView) ? null : selectedItems.length;

  const modifyActionHandler = useCallback((actionItems: IDataItem[]) => {
    if (!actionItems[0]) {
      return;
    }
    setErrors([]);
    // items[0] in case where there is no identifier in item
    setContentOptions({
      activeItem: actionItems[0],
      isDrawerOpened: true,
      isVersionsOpened: false,
      isLocalizationsOpened: false,
      drawerOptions: {
        type: 'edit',
      },
    });
  }, []);

  const onCpaPageActionClick = useCpaPageActionClickHandler(data, clearTableSelection, reloadCurrentItems, setInfoMessages, setErrors);
  const customActionsBound = useCustomActions(data, selectedItems, onCpaPageActionClick, customActions);

  const customTopBarActions = useMemo(() => {
    return customActionsBound?.filter((action) => !action.hideInCommandBar);
  }, [customActionsBound]);

  const customFormActions = useMemo(() => {
    return customActionsBound?.filter((action) => action.showInForm);
  }, [customActionsBound]);

  const onActionClick = useActionHandler({
    data,
    selectedItems,
    dismissContextCallout,
    setErrors,
    reloadCurrentItems,
    downloadableFilePropertyJsonPaths,
    setContentOptions,
    copyDialogRef,
    mergeIntoDialogRef,
    compareDialogRef,
    changeBaseLanguageDialogRef,
    customActionsBound,
    setRelatedPayload,
    onEdit,
    onDelete,
    handleOnDelete,
    onAddClick,
    handleOnCopy,
    clearTableSelection,
    loadItems,
    odataFilter,
    formSchemas,
  });

  const relatedContent = useMemo(() => {
    if (!relatedPayload) {
      return undefined;
    }
    const [relatedPayloadPage, dataQuery] = relatedPayload;
    if (relatedPayloadPage.customTemplate?.identifier) {
      const _Component = ScreenTypesConfiguration[relatedPayloadPage.customTemplate.identifier];
      if (_Component) {
        return <_Component page={relatedPayloadPage} externalDataQuery={dataQuery} closeDrawer={resetRelatedPayload} />;
      }
    }

    return (
      <GenericScreen
        page={relatedPayloadPage}
        externalDataQuery={dataQuery}
        isWidget={true}
        withoutAnimation={true}
        genericComponentProps={genericComponentProps}
      />
    );
  }, [relatedPayload, resetRelatedPayload]);

  // We need this ref in order to wait for a new data.items.length
  const renderRef = useRef<Function | undefined>(undefined);
  renderRef.current?.();
  renderRef.current = undefined;

  const loadByScroll = useCallback(() => {
    // Items lazy loading for pages with isODataSupportedByEndpoint
    if (
      isODataSupportedByEndpoint &&
      data.schema &&
      !data.schema.cp_disableLazyLoading &&
      !data.isFetching &&
      !isLazyLoading.current &&
      !isAllDataLoaded &&
      !contentOptions.isDrawerOpened
    ) {
      isLazyLoading.current = true;
      loadItems?.({
        skip: data.items.length,
        orderBy: order && [[order.columnKey, order.isAscending ? 'asc' : 'desc']],
        top: pageSize,
        filter: odataFilter,
      }).finally(() => {
        renderRef.current = () => {
          isLazyLoading.current = false;
        };
      });
    }
  }, [
    contentOptions.isDrawerOpened,
    data.items.length,
    data.isFetching,
    data.schema,
    isAllDataLoaded,
    isODataSupportedByEndpoint,
    loadItems,
    odataFilter,
    order,
    pageSize,
  ]);

  const lastFilterCancelToken = useRef<CancelTokenSource | null>(null);
  useEffect(() => {
    // Refetch items with filter if isODataSupportedByEndpoint
    if (isODataSupportedByEndpoint && data.schema && !contentOptions.isDrawerOpened && firstEntered.current) {
      lastFilterCancelToken.current?.cancel();
      lastFilterCancelToken.current = createCancelToken();

      loadItems?.(
        {
          skip: 0,
          orderBy: order && [[order.columnKey, order.isAscending ? 'asc' : 'desc']],
          top: pageSize,
          filter: odataFilter,
        },
        { overwriteExistingData: true, cancelToken: lastFilterCancelToken.current }
      );
    }
    firstEntered.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [odataFilter]);

  useEffect(() => {
    const scrollEventEmitter = scrollablePaneContext?.scrollEventEmitter?.current;
    if (!scrollEventEmitter) {
      return;
    }

    const bodyScrollEventHandler = loadByScroll;
    const fullBodyScrollEventHandler = tableRef.current?.resetHeaderLeftScroll;

    if (!isWidget) {
      scrollEventEmitter.on('onBodyScroll', bodyScrollEventHandler);
      if (fullBodyScrollEventHandler) {
        scrollEventEmitter.on('onFullBodyScroll', fullBodyScrollEventHandler);
      }
    }
    return (): void => {
      scrollEventEmitter.off('onBodyScroll', bodyScrollEventHandler);
      if (fullBodyScrollEventHandler) {
        scrollEventEmitter.off('onFullBodyScroll', fullBodyScrollEventHandler);
      }
    };
  }, [loadByScroll, isWidget, scrollablePaneContext?.scrollEventEmitter]);

  const onOrderChange: ITableProps['onOrderChange'] = useCallback(
    async (value) => {
      if (!isAllDataLoaded && isODataSupportedByEndpoint) {
        // Refetch loaded items using OData
        await loadItems?.(
          {
            skip: 0,
            orderBy: value && [[value.columnKey, value.isAscending ? 'asc' : 'desc']],
            top: data.items.length,
            filter: odataFilter,
          },
          { overwriteExistingData: true }
        );
      }
      updatePageSetting({ pageKey: data.page.identifier, setting: 'columnsSorting', value: value });
      setOrder(value);
    },
    [isAllDataLoaded, isODataSupportedByEndpoint, updatePageSetting, data.page.identifier, data.items.length, loadItems, odataFilter]
  );

  const onDrawerClose = useCallback(() => {
    setContentOptions({
      activeItem: null,
      isDrawerOpened: false,
      isVersionsOpened: false,
      isLocalizationsOpened: false,
      drawerOptions: { type: 'add' },
    });
  }, []);

  const onVersionsDrawerClose = useCallback(() => {
    setContentOptions({
      activeItem: null,
      isDrawerOpened: false,
      isVersionsOpened: false,
      isLocalizationsOpened: false,
      drawerOptions: { type: 'add' },
    });
  }, []);

  const onLocalizationsDrawerClose = useCallback(() => {
    setContentOptions({
      activeItem: null,
      isDrawerOpened: false,
      isVersionsOpened: false,
      isLocalizationsOpened: false,
      drawerOptions: { type: 'add' },
    });
  }, []);

  const onVersionsUpdate = useCallback(async () => {
    clearTableSelection();
    await reloadCurrentItems();
    onVersionsDrawerClose();
  }, [reloadCurrentItems, onVersionsDrawerClose, clearTableSelection]);

  const onLocalizationsUpdate = useCallback(async () => {
    clearTableSelection();
    await reloadCurrentItems();
    // onLocalizationsDrawerClose();
  }, [reloadCurrentItems, clearTableSelection]);

  useImperativeHandle(
    ref,
    () => ({
      setSearchText: (value: string, withoutLoading?: boolean): void => {
        firstEntered.current = !withoutLoading;
        setFilterValue(value);
        forceSetDebouncedFilterValue(value);
      },
      filterValue: debouncedFilterValue,
      tableRef: tableRef,
    }),
    [debouncedFilterValue, forceSetDebouncedFilterValue]
  );

  const widgetScrollablePaneRef = useRef<IScrollablePane & { contentContainer: HTMLElement | null }>(null);
  const onWidgetScroll = useCallback(() => {
    const scrollablePane = widgetScrollablePaneRef.current?.contentContainer;
    if (!scrollablePane) {
      return;
    }

    const scrollPositionFromBottom = Math.floor(scrollablePane.scrollHeight - scrollablePane.clientHeight) - Math.floor(scrollablePane.scrollTop);

    if (scrollPositionFromBottom < 50) {
      loadByScroll();
    }
  }, [loadByScroll]);

  const previousOdataFilter = usePrevious(odataFilter);
  useEffect(() => {
    if ((!previousOdataFilter && !odataFilter) || isWidget || !wrapperRef.current || previousOdataFilter === odataFilter) {
      return;
    }

    const viewportPosition = wrapperRef.current.getBoundingClientRect();
    if (viewportPosition.top < 35) {
      wrapperRef.current.scrollIntoView(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [odataFilter]);

  const query = useQuery();

  const itemId = useItemIdParam();

  // It is needed because 'selection' is a static ref object
  // and without ref handler it will always call onItemsSelect from the init render
  const handleItemsSelectRef = useRef(handleItemsSelect);
  useEffect(() => {
    handleItemsSelectRef.current = handleItemsSelect;
  }, [handleItemsSelect]);

  const selection = useRef(
    new Selection<IDataItem>({
      items: [],
      onSelectionChanged: (): void => {
        handleItemsSelectRef.current(selection.current.getSelection(), tableKey);
      },
      getKey: (item, index): string | number => {
        return (item.identifier || item.__identifier || index || JSON.stringify(item)).toString();
      },
    })
  );

  const preparedFilteredItems = useMemo<IDataItem[]>(() => {
    if (!data.schema) {
      return [];
    }

    const preparedItems = filteredItems.map((item) => {
      const clonedItem = cloneDeepWithMetadata(item);
      cleanUpInternalProperties(clonedItem, data.schema || {});

      Object.defineProperty(clonedItem, '__originalItem', {
        enumerable: false,
        value: item,
      });

      return clonedItem;
    });

    selection.current.setItems(preparedItems, false);

    if (forceKeySelection) {
      selection.current.setAllSelected(false);
      for (const key of forceKeySelection) {
        selection.current.setKeySelected(key, true, false);
      }
    }

    return preparedItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.schema, filteredItems]);

  const [height, setHeight] = useState(0);

  const { ref: resizeRef } = useResizeDetector({
    onResize: (width, height) => {
      // Check if element has overflow
      if (resizeRef.current?.scrollWidth && resizeRef.current.scrollWidth > (width || 0)) {
        // Scrollbar height 10px compensation
        setHeight(height ? height + 10 : 0);
      } else {
        setHeight(height || 0);
      }
    },
  });

  const scrollablePaneStyles = useMemo(() => {
    let minHeight: string | number = 0;
    // debugger;
    if (widgetHeight !== undefined) {
      minHeight = preparedFilteredItems.length === 0 && !data.isFetching ? 0 : widgetHeight;
    } else if (data.page?.customRowTemplate?.identifier && CustomRowTemplates[data.page.customRowTemplate.identifier]?.options?.table?.rowSize) {
      const itemHeight = CustomRowTemplates[data.page.customRowTemplate.identifier]!.options!.table!.rowSize!.itemHeight;
      const templateHeight = CustomRowTemplates[data.page.customRowTemplate.identifier]!.options!.table!.rowSize!.templateHeight;
      const totalItemsHeight = itemHeight * preparedFilteredItems.length;
      minHeight = Math.min(totalItemsHeight, templateHeight);
    } else if (height) {
      minHeight = `min(${height}px, ${widgetMaxHeight ?? '270px'})`;
    }
    return {
      root: {
        position: 'relative' as const,
        minHeight,
      },
      stickyAbove: { zIndex: 5000 },
      contentContainer: { maxWidth: '100%' },
    };
  }, [data.isFetching, data.page.customRowTemplate?.identifier, preparedFilteredItems.length, height, widgetHeight, widgetMaxHeight]);

  useEffect(() => {
    if (data.isFetching) {
      return;
    }

    if (isActionExecuted.current || isWidget || !routeMatch.path) {
      return;
    }
    isActionExecuted.current = true;

    // Opening view drawer if there is no action provided but with filter or :id provided
    // Single item view is chosen on higher level (GenericScreen)
    if (!initialAction && itemId) {
      const targets = data.items.filter((el) => el.identifier === itemId || el.urlSlug === itemId);
      if (targets.length === 1) {
        setContentOptions({
          activeItem: targets[0],
          isDrawerOpened: true,
          isVersionsOpened: false,
          isLocalizationsOpened: false,
          drawerOptions: {
            readonly: true,
            type: 'edit',
          },
        });
      }
    }
    if (initialAction) {
      const matchedCustomAction = data.page.actions?.find((customAction) => customAction.identifier === initialAction);

      if (matchedCustomAction) {
        let targetItems: IDataItem[] = [];
        if (itemId) {
          const matchedDataItems = data.items.filter((el) => el.identifier === itemId || el.urlSlug === itemId);
          if (matchedDataItems.length === 1) {
            targetItems.push(matchedDataItems[0]);
          }
        } else {
          const filter = new QueryFilterManager().parseQuery(query[filterQueryKeyName]);
          targetItems.push(...(_.filter(data.items, filter) as IDataItem[]));
        }

        switch (true) {
          case matchedCustomAction.selection === ActionSelection.ONE && targetItems.length >= 1:
            targetItems = targetItems.slice(0, 1);
            break;
          case matchedCustomAction.selection === ActionSelection.MANY && targetItems.length >= 1:
            break;
          case matchedCustomAction.selection === ActionSelection.NONE:
            targetItems = [];
            break;
          case matchedCustomAction.selection === ActionSelection.ANY:
            break;
          default:
            // Return if no options matched
            return;
        }

        for (const targetItem of targetItems) {
          selection.current.setKeySelected(selection.current.getKey(targetItem), true, false);
        }
        onCpaPageActionClick(matchedCustomAction as IAction)(targetItems);
        return;
      }

      switch (initialAction.toLowerCase()) {
        case 'add': {
          if (!data.page.allowCreate) {
            break;
          }

          const wizardOptions: IWizardOptions = { isWizardMode: false };

          if (query.mode && query.mode.startsWith('wizard')) {
            wizardOptions.isWizardMode = true;
            const steps = query.steps ? query.steps.split(',') : undefined;
            const formattedSteps = steps ? steps.map((step) => +step) : undefined;
            const fields = query.fields ? query.fields.split(',') : undefined;
            wizardOptions.steps = formattedSteps && formattedSteps.every((step) => !Number.isNaN(step)) ? formattedSteps : undefined;
            wizardOptions.requiredOnly = query.requiredOnly === 'true';
            wizardOptions.fields = fields;
            wizardOptions.isFullScreen = query.mode !== 'wizardDrawer';
          }

          if (query.sharedItem) {
            // Fetch shared item and set preset
            fetchSharedItem(query.sharedItem, t)
              .then((sharedItem) => {
                setContentOptions((options) => ({
                  ...options,
                  isDrawerOpened: true,
                  presetOptions: {
                    preset: sharedItem.itemData as IDataItem,
                    wizardOptions: wizardOptions,
                  },
                  drawerOptions: {
                    ...options.drawerOptions,
                    type: 'add',
                  },
                }));
              })
              .catch((err) => {
                setErrors([err.message]);
              });
          } else {
            // Use raw preset from query
            const preset = query.preset ? JSON.parse(query.preset) : null;
            setContentOptions((options) => ({ ...options, isDrawerOpened: true, presetOptions: { preset, wizardOptions } }));
          }
          break;
        }
        case 'compare_versions': {
          if (!data.items.length || (!itemId && !query[filterQueryKeyName])) {
            break;
          }
          if (!query.versionA || !query.versionB) {
            break;
          }
          try {
            let target: IDataItem | undefined;
            if (itemId) {
              const targets = data.items.filter((el) => el.identifier === itemId || el.urlSlug === itemId);
              if (targets.length === 1) {
                target = targets[0];
              }
            } else {
              const filter = new QueryFilterManager().parseQuery(query[filterQueryKeyName]);
              target = _.find(data.items, filter) as IDataItem;
            }
            if (!target) {
              break;
            }
            selection.current.setKeySelected(selection.current.getKey(target), true, false);
            setContentOptions({
              activeItem: target,
              isDrawerOpened: false,
              isVersionsOpened: true,
              isLocalizationsOpened: false,
              drawerOptions: {
                type: 'add',
              },
              versionsOptions: {
                comparedVersions: [query.versionA, query.versionB],
              },
            });
            break;
          } catch {
            break;
          }
        }
        case 'view':
        case 'edit': {
          if (!data.items.length || (!itemId && !query[filterQueryKeyName]) || !data.page.allowModify) {
            break;
          }
          try {
            let target: IDataItem | undefined;
            if (itemId) {
              const targets = data.items.filter((el) => el.identifier === itemId || el.urlSlug === itemId);
              if (targets.length === 1) {
                target = targets[0];
              }
            } else {
              const filter = new QueryFilterManager().parseQuery(query[filterQueryKeyName]);
              target = _.find(data.items, filter) as IDataItem;
            }

            if (!target) {
              break;
            }
            const wizardOptions: IWizardOptions = { isWizardMode: false };

            if (query.mode && query.mode.startsWith('wizard')) {
              wizardOptions.isWizardMode = true;
              const steps = query.steps ? query.steps.split(',') : undefined;
              const formattedSteps = steps ? steps.map((step) => +step) : undefined;
              const fields = query.fields ? query.fields.split(',') : undefined;
              wizardOptions.steps = formattedSteps && formattedSteps.every((step) => !Number.isNaN(step)) ? formattedSteps : undefined;
              wizardOptions.requiredOnly = query.requiredOnly === 'true';
              wizardOptions.fields = fields;
              wizardOptions.isFullScreen = query.mode !== 'wizardDrawer';
            }
            selection.current.setKeySelected(selection.current.getKey(target), true, false);
            const isReadonly = initialAction.toLowerCase() === 'view';
            setContentOptions({
              activeItem: target,
              isDrawerOpened: true,
              isVersionsOpened: false,
              isLocalizationsOpened: false,
              drawerOptions: {
                readonly: isReadonly,
                type: 'edit',
                wizardOptions: wizardOptions,
              },
            });
          } catch {
            break;
          }
        }
      }
    }

    // eslint-disable-next-line
  }, [data.schema, data.isFetching, filteredItems, location]);

  const withScrollablePane = (el: JSX.Element): JSX.Element => {
    return isWidget ? (
      <ScrollablePane
        componentRef={widgetScrollablePaneRef}
        className={classNames(styles.scrollablePane)}
        styles={scrollablePaneStyles}
        onScroll={onWidgetScroll}
      >
        <div ref={resizeRef}>{el}</div>
      </ScrollablePane>
    ) : (
      el
    );
  };

  const dataCallbackContext = useContext(DataCallbackContext);
  useEffect(() => {
    if (data.isFetching) {
      return;
    }
    dataCallbackContext?.onFilteredDataUpdated?.(data.page, preparedFilteredItems);

    // eslint-disable-next-line
  }, [preparedFilteredItems, data.isFetching]);

  const onDeselectAll = useCallback(() => {
    selectionEmitter.emit('deselectAll');
  }, [selectionEmitter]);

  const getTopBarContent = useCallback(
    (isSticky: boolean) => (
      <TopBarContent
        isSticky={isSticky}
        isChart={!!chart}
        data={data}
        hideFilter={hideFilter}
        filterValue={filterValue}
        hideActions={hideActions}
        availableActions={availableActions}
        onActionClick={onActionClick}
        selectedItemsCount={selectedItemsCount}
        customActionsBound={customTopBarActions}
        setFilterValue={setFilterValue}
        onClearSelection={onDeselectAll}
        isODataSupportedByEndpoint={isODataSupportedByEndpoint}
        defaultFilterExpanded={defaultFilterExpanded}
      />
    ),
    [
      defaultFilterExpanded,
      onDeselectAll,
      availableActions,
      customTopBarActions,
      data,
      filterValue,
      hideActions,
      hideFilter,
      onActionClick,
      selectedItemsCount,
      chart,
      isODataSupportedByEndpoint,
    ]
  );

  const handleStickyChange = useCallback((isSticky: boolean, placeholderHeight: number) => {
    if (isSticky) {
      setTopbarContentHeight(placeholderHeight + STICKY_OFFSET);
    } else {
      setTopbarContentHeight(0);
    }
  }, []);

  const stickyTopBar = useMemo(() => {
    const widthAnchor = pageCardView ? cardViewRef : tableRef.current?.containerRef || emptyRef;

    let previousWidth = 0;
    return disableStickyActions || scrollablePaneContext?.scrollOverFooter ? (
      getTopBarContent(false)
    ) : isWidget ? null : (
      <Sticky
        stickyClassName={classNames({
          [styles.sticky]: !isWidget,
          [styles.movedRight]: !isWidget && !!pageSettings?.displayChart,
        })}
        stickyOffset={STICKY_OFFSET}
        onStickyChange={handleStickyChange}
        stickyPosition={StickyPositionType.Header}
        isScrollSynced={true}
        stickyBackgroundColor={'transparent'}
        scrollablePaneContext={scrollablePaneContext}
      >
        {(isSticky) => (
          <ReactResizeDetector handleHeight={false} handleWidth={true} targetRef={widthAnchor}>
            {({ width, targetRef }: { width: number; targetRef: React.RefObject<HTMLDivElement> }): JSX.Element => {
              if (width) {
                previousWidth = width;
              }

              const backgroundColor = darkMode ? theme?.palette.neutralSecondaryAlt : theme?.palette.white;
              const transparentBackground = darkMode ? theme?.palette.white : '#f5f6f6';
              return (
                <div
                  ref={targetRef}
                  style={{
                    width: width || previousWidth || undefined,
                    backgroundColor: !isSticky ? 'transparent' : data.page.transparentWidget ? transparentBackground : backgroundColor,
                    paddingTop: 0,
                  }}
                >
                  <MessageBars className={styles.messagesWrapper} messages={errors} messageBarType={MessageBarType.error} />
                  <MessageBars className={styles.messagesWrapper} messages={infoMessages} messageBarType={MessageBarType.info} />
                  {getTopBarContent(isSticky)}
                </div>
              );
            }}
          </ReactResizeDetector>
        )}
      </Sticky>
    );
  }, [
    handleStickyChange,
    data.page.transparentWidget,
    scrollablePaneContext,
    disableStickyActions,
    errors,
    getTopBarContent,
    infoMessages,
    isWidget,
    pageCardView,
    pageSettings?.displayChart,
    theme,
    darkMode,
  ]);

  const contextMenuContent = useMemo(
    () => (
      <Actions
        availableActions={availableContextActions}
        isInsideContextMenu={true}
        onActionClick={onActionClick}
        selectedItemsCount={selectedItemsCount}
        customActions={customTopBarActions}
        onClearSelection={onDeselectAll}
        ref={actionsRef}
      />
    ),
    [onDeselectAll, availableContextActions, onActionClick, selectedItemsCount, customTopBarActions]
  );

  const handleDragDropItemEdit = useCallback<Required<ITableDragDropParams>['onDragDropItemEdit']>(
    async (updatedItem, originalItem) => {
      if (onEdit) {
        await onEdit(updatedItem, originalItem);
        clearTableSelection();
        reloadCurrentItems();
      }
    },
    [clearTableSelection, onEdit, reloadCurrentItems]
  );

  const handleMoveTo = useCallback(
    async (item: IDataItem) => {
      try {
        await copyItemToPage(item, data.page, t);
        reloadCurrentItems();
      } catch (error) {
        setErrors([error.message]);
      }
    },
    [data.page, reloadCurrentItems, t]
  );

  const [isDragging, setDragging] = useState<boolean>(false);

  const tableDragDropHandlers = useTableDragDrop({
    areaKey: data.page.cpTypeUrl || data.page.identifier!,
    t,
    parentPropertyJsonPath,
    disableDragDrop,
    onDragDropItemEdit: onEdit && handleDragDropItemEdit,
    onMoveTo: handleMoveTo,
    readonly: !data.page.allowModify,
    schema: data.schema,
    isWidget,
    customRowTemplate: data.page.customRowTemplate?.identifier,
    onDragChange: setDragging,
  });

  const dataOperations = useMemo(() => {
    return {
      onEditRow: onEdit,
      onAddRow: onAdd,
      onDeleteRow: onDelete,
    };
  }, [onEdit, onAdd, onDelete]);

  const handleItemClick = useCallback(
    (item: IDataItem, selection: Selection<IObjectWithKey & IDataItem>) => {
      return onItemClick?.(item, selection, data.schema);
    },
    [data.schema, onItemClick]
  );

  const columnsOrder = useCpaPageUserConfigurationValue(data.page.identifier, 'columnsOrders');
  const sortOrder = useCpaPageUserConfigurationValue(data.page.identifier, 'columnsSorting');
  const columnsWidth = useCpaPageUserConfigurationValue(data.page.identifier, 'columnsWidth');

  const columnsWidthDeserialized = useMemo(() => {
    if (!columnsWidth) return null;
    try {
      return JSON.parse(columnsWidth as string);
    } catch (e) {
      return null;
    }
  }, [columnsWidth]);

  const handleColumnsReorder = useCallback(
    (columns: string[]) => {
      updatePageSetting({ pageKey: data.page.identifier, setting: 'columnsOrders', value: columns });
    },
    [data.page.identifier, updatePageSetting]
  );

  const handleColumnsResize = useCallback(
    (columns: { [key: string]: { width?: number } }) => {
      updatePageSetting({ pageKey: data.page.identifier, setting: 'columnsWidth', value: JSON.stringify(columns) });
    },
    [data.page.identifier, updatePageSetting]
  );

  const table = data.items?.length > 0 && (
    <TableContext.Provider value={tableContextValue}>
      <Table
        selection={selection.current}
        ref={tableRef}
        t={t}
        tableKey={tableKey}
        schema={data.schema as IJSONSchema}
        items={preparedFilteredItems}
        locale={i18n.language}
        onItemsSelect={handleItemsSelect}
        onItemOpen={handleItemOpen}
        onItemEdit={modifyActionHandler}
        onItemClick={onItemClick ? handleItemClick : undefined}
        colorizedRows={powerUser ? colorizedRows : true}
        spanColumn={data.page.spanColumn}
        onDownload={handleDownload}
        isFetching={data.isFetching}
        disableMarqueeSelection={
          data.isFetching ||
          contentOptions.isDrawerOpened ||
          (Number.isInteger(selectionMode) && [SelectionMode.none, SelectionMode.single].includes(selectionMode!))
        }
        disableDoubleClick={data.isFetching || contentOptions.isDrawerOpened || disableDoubleClick}
        groupPropertyJsonPath={data.page.groupPropertyJsonPath}
        totalItems={data.totalItems}
        pageSize={pageSize}
        onOrderChange={onOrderChange}
        hiddenInTable={hiddenInTable}
        hideHeader={
          hideHeader ||
          !!(
            data.page.customRowTemplate?.identifier && CustomRowTemplates[data.page.customRowTemplate.identifier]?.options?.table?.hideHeader === true
          )
        }
        multilineFields={multilineFields}
        order={sortOrder as { columnKey: string; isAscending: boolean }}
        disableStickyHeader={disableStickyHeader}
        isWidget={isWidget}
        darkMode={darkMode}
        selectionMode={selectionMode}
        disabledManagedColumnsWidth={
          disabledManagedColumnsWidth ||
          !!(
            data.page.customRowTemplate?.identifier &&
            CustomRowTemplates[data.page.customRowTemplate.identifier]?.options?.table?.disabledManagedColumnsWidth === true
          )
        }
        disableVirtualization={
          !!(
            data.page.customRowTemplate?.identifier &&
            CustomRowTemplates[data.page.customRowTemplate.identifier]?.options?.table?.disableVirtualization === true
          )
        }
        pageSettings={pageSettings}
        disableSorting={isAllDataLoaded ? false : isSortedOnServerSide}
        page={data.page}
        parentPropertyJsonPath={parentPropertyJsonPath}
        loadItems={loadItems}
        reloadEmitter={reloadEmitter}
        selectionEmitter={selectionEmitter}
        onItemRightClick={onItemRightClick}
        onAddClick={disableAddButtonInTable || !data.page.allowCreate ? undefined : onAddClick}
        dragDropEvents={tableDragDropHandlers}
        parsedFilters={parsedFilters}
        showItemLinksInTable={showItemLinksInTable}
        downloadableFilePropertyJsonPaths={downloadableFilePropertyJsonPaths}
        dataOperations={dataOperations}
        isODataSupportedByEndpoint={isODataSupportedByEndpoint}
        cached={isCached}
        scrollablePaneContext={scrollablePaneContext}
        headerPointerEventsDisabled={isDragging}
        powerUser={powerUser}
        isMobileDevice={isMobileDevice}
        scrollOverFooter={!!scrollablePaneContext?.scrollOverFooter}
        topbarContentHeight={topbarContentHeight}
        handleColumnsReorder={handleColumnsReorder}
        handleColumnsResize={handleColumnsResize}
        columnsOrder={columnsOrder as string[] | null}
        columnsWidth={columnsWidthDeserialized}
        {...(tableProps || {})}
      />
    </TableContext.Provider>
  );

  const renderScrollablePane = (!chart && isWidget && !widgetCardView) || !isWidget;

  const onMergeIntoDialogSubmit = useCallback(
    async (from: IDataItem, to: IDataItem, properties: string[]) => {
      if (!from.identifier || !to.identifier) {
        return;
      }

      try {
        await mergeItem(from.identifier, to.identifier, data.page, properties);
        mergeIntoDialogRef.current?.closeDialog();
        clearTableSelection();
        await reloadCurrentItems();
      } catch (error) {
        mergeIntoDialogRef.current?.setError(error.message);
      }
    },
    [clearTableSelection, data.page, reloadCurrentItems]
  );

  const isReadonly: boolean = useMemo(() => {
    if (contentOptions.presetOptions) {
      return false;
    }

    if (contentOptions.drawerOptions.readonly) {
      return true;
    }

    return !data.page.allowModify && (!data.page.allowCreate || !!contentOptions.activeItem);
  }, [data.page, contentOptions.presetOptions, contentOptions.drawerOptions.readonly, contentOptions.activeItem]);

  const itemInfoData = useMemo(() => {
    return {
      ...data,
      schema: filterSchema((contentOptions.drawerOptions.customSchema ?? data.schema ?? {}) as IJSONSchema, [], { filterVoidProperties: true }),
    };
  }, [data, contentOptions.drawerOptions.customSchema]);

  const activeItemForItemInfo = useMemo<IDataItem>(() => {
    if (contentOptions.presetOptions) {
      return contentOptions.presetOptions.preset;
    } else if (!data.schema || !contentOptions.activeItem) {
      return {};
    } else {
      return cloneDeepWithMetadata(contentOptions.activeItem);
    }
  }, [contentOptions.activeItem, data.schema, contentOptions.presetOptions]);

  const wizardOptions = useMemo(() => {
    if (contentOptions.presetOptions && !contentOptions.presetOptions.wizardOptions?.isWizardMode && !powerUser) {
      return SIMPLE_VIEW_WIZARD_OPTIONS;
    } else if (contentOptions.presetOptions) {
      return contentOptions.presetOptions.wizardOptions;
    } else if (
      !contentOptions.drawerOptions.wizardOptions?.isWizardMode &&
      !powerUser &&
      contentOptions.drawerOptions.type !== 'edit' &&
      contentOptions.isDrawerOpened
    ) {
      return SIMPLE_VIEW_WIZARD_OPTIONS;
    } else {
      return contentOptions.drawerOptions.wizardOptions;
    }
  }, [contentOptions.presetOptions, contentOptions.drawerOptions, contentOptions.isDrawerOpened, powerUser]);

  const prefill = useMemo(() => {
    return _.merge({}, preFillItems, contentOptions.drawerOptions.prefill);
  }, [preFillItems, contentOptions.drawerOptions.prefill]);

  const onCardRightClick = useCallback(
    (item: IDataItem, position: { x: number; y: number; targetId: string }) => {
      if (hideActions) {
        return;
      }

      const actionsHeight = actionsRef.current?.getButtonsHeight() || 0;

      setContextCallout({
        target: position.targetId,
        isOpened: true,
        top: position.y + actionsHeight >= window.innerHeight ? position.y - actionsHeight : position.y,
        left: position.x,
      });
    },
    [hideActions]
  );

  const calloutStyles = useMemo(
    () => ({
      root: {
        left: `${contextCallout.left}px !important`,
        top: `${contextCallout.top}px !important`,
        width: 'max-content !important',
        height: 'max-content !important',
        opacity: 'unset !important',
        filter: 'unset !important',
        pointerEvents: 'inherit !important',
        position: 'absolute' as const,
      },
    }),
    [contextCallout.left, contextCallout.top]
  );

  return data.schema ? (
    <div ref={wrapperRef}>
      <div>
        {isWidget ? getTopBarContent(false) : null}
        {isWidget && !!widgetCardView ? (
          <WidgetCardView
            data={data}
            items={filteredItems}
            onCardClick={onCardClick}
            pageCardView={pageCardView}
            isODataSupportedByEndpoint={isODataSupportedByEndpoint}
          />
        ) : null}
        {chart}
        {renderScrollablePane &&
          withScrollablePane(
            <>
              {stickyTopBar}
              <div>
                {isWidget && !widgetCardView ? table : null}
                {!isWidget && pageCardView ? (
                  <PageCardView
                    containerRef={cardViewRef}
                    data={data}
                    items={filteredItems}
                    pageSize={pageSize}
                    selectedItems={selectedItems}
                    selection={selection.current}
                    selectionEmitter={selectionEmitter}
                    onCardRightClick={onCardRightClick}
                    onCardClick={onCardClick}
                    order={order}
                    isODataSupportedByEndpoint={isODataSupportedByEndpoint}
                  />
                ) : null}
                {!isWidget && !pageCardView ? table : null}
              </div>
            </>
          )}
      </div>
      {preparedFilteredItems.length === 0 && !data.isFetching && (
        <EmptySpace darkMode={darkMode} iconName="Completed" text={t('common.noItems')} elementRef={emptyRef} />
      )}

      {/* ItemInfo for adding and editing item without preset or adding with preset */}
      <ItemInfo
        isOpened={contentOptions.isDrawerOpened}
        onClose={onDrawerClose}
        data={itemInfoData}
        readonly={isReadonly}
        onActionClick={onActionClick}
        customActions={customFormActions}
        item={activeItemForItemInfo}
        type={contentOptions.drawerOptions.type}
        onAdd={handleOnAdd}
        onEdit={handleOnEdit}
        onSubmitAs={handleOnSubmitAs}
        prefill={prefill}
        wizardOptions={wizardOptions}
      />

      <Callout
        hidden={!contextCallout.isOpened}
        target={contextCallout.target}
        isBeakVisible={false}
        styles={calloutStyles}
        role="alertdialog"
        onDismiss={dismissContextCallout}
      >
        {contextMenuContent}
      </Callout>

      {contentOptions.isVersionsOpened && (
        <VersionsDrawer
          isOpen={true}
          pageSchema={data.schema}
          item={contentOptions.activeItem}
          onClose={onVersionsDrawerClose}
          page={data.page}
          onUpdate={onVersionsUpdate}
          comparedVersions={contentOptions.versionsOptions?.comparedVersions}
        />
      )}

      {contentOptions.isLocalizationsOpened && (
        <LocalizationsDrawer
          isOpen={true}
          pageSchema={data.schema}
          item={contentOptions.activeItem}
          onClose={onLocalizationsDrawerClose}
          page={data.page}
          onUpdate={onLocalizationsUpdate}
        />
      )}

      <Drawer isOpen={!!relatedPayload} onClose={resetRelatedPayload}>
        {relatedContent}
      </Drawer>

      <CopyDialog ref={copyDialogRef} />
      <MergeIntoDialog ref={mergeIntoDialogRef} onSubmit={onMergeIntoDialogSubmit} page={data.page} />
      <CompareDialog ref={compareDialogRef} page={data.page} />
      <ChangeBaseLanguageDialog ref={changeBaseLanguageDialogRef} page={data.page} />
    </div>
  ) : null;
});

export default ScrollingContent;
