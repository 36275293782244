import { IJSONSchema, Schemas, isDefined } from '@cp/base-types';
import { cloneDeepWithMetadata, resolveSubjectUri, setViewToSubjectUri } from '@cp/base-utils';
import { axiosDictionary, getEndpoint } from '@cpa/base-core/api';
import { PathContext } from '@cpa/base-core/constants';
import { useChartColors, useLanguage } from '@cpa/base-core/hooks';
import { useFormField } from '@cpa/base-core/hooks/form';
import { IGlobalState } from '@cpa/base-core/store';
import { DialogType, Icon, IconButton, ITextFieldStyles, SearchBox, Spinner, StickyPositionType } from '@fluentui/react';
import { usePrevious } from '@fluentui/react-hooks';
import RjsfForm, { Registry } from '@rjsf/core';
import axios from 'axios';
import classNames from 'classnames';
import * as _ from 'lodash';
import Tree from 'rc-tree';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import urlJoin from 'url-join';
import { NodeDragEventParams } from 'rc-tree/es/contextTypes';
import { EventDataNode, Key } from 'rc-tree/es/interface';
import { showDialog } from '@cpa/base-core/helpers';
import { GlobalDialogType } from '@cpa/base-core/types';

import HoverTooltip from '../../../../../HoverTooltip/HoverTooltip';
import { Sticky } from '../../../../../ScrollingContent/components/Sticky/Sticky';

import styles from './TreeView.module.scss';
import FormDialog from './components/FormDialog/FormDialog';
import {
  Node,
  SORT_ORDER_ANNOTATION_KEY,
  createFieldIndex,
  extendSchema,
  findUnknownParentNodeKey,
  getLevelFromPath,
  getPathAtLevel,
  getUpdatedTreeOrder,
  isParentNode,
  isPropertyNode,
  isSameLevelNodes,
  matchPropertyTypes,
  parseSchema,
  updateTree,
} from './helpers';

interface ITreeViewProps {
  onChange: (selectedPaths: Schemas.CpType['assignments'], formDataModifier?: (formData: object) => void) => void;
  registry: Registry & {
    formRef?: RjsfForm<unknown>;
  };
  assignments: Schemas.CpType['assignments'];
  formSchema: IJSONSchema;
  onStepQuestionChange: (question: string, index: number) => void;
  handleGroupRemoved: (formData: object, index: number) => void;
}

const INITIAL_SELECTED_KEYS: string[] = [];
const NO_ACTION_HANDLER = (): null => null;
const SEARCH_BOX_PROPS = { iconName: 'Filter' };
const wizardGroupAnnotation = 'cp:wizardGroup';

const TreeView: React.FC<ITreeViewProps> = ({ onChange, registry, assignments, formSchema, onStepQuestionChange, handleGroupRemoved }) => {
  const prefixMap = useSelector((store: IGlobalState) => store.app.prefixMap);
  const colors = useChartColors();
  const darkMode = useSelector((state: IGlobalState) => state.settings.darkMode);

  const isDragging = useRef<boolean>(false);

  const selectedClass = useFormField<Schemas.CpClass>('class');
  const [schema, setSchema] = useState<IJSONSchema | null>(null);
  const [editingItem, setEditingItem] = useState<Extract<Schemas.CpType['assignments'], {}>[0] | null>(null);
  const [filterValue, setFilterValue] = useState<string | undefined>(undefined);
  const [isPropertyPathLocked, setPropertyPathLocked] = useState(true);
  const [dragging, setDragging] = useState<boolean>(false);
  const [wizardStepsMode, setWizardStepsMode] = useState<boolean>(false);
  const prevWizardStepsMode = usePrevious(wizardStepsMode);
  const dataLanguage = useLanguage();

  const [tree, setTree] = useState<Node[] | null>(null);
  const [checked, setChecked] = useState<string[]>(
    assignments?.filter((assignment) => assignment.deactivated !== true).map((index) => index.propertyJsonPath!) || []
  );
  const [expanded, setExpanded] = useState<string[]>([]);
  const [loaded, setLoaded] = useState<string[]>([]);

  const [t, i18n] = useTranslation();

  const uniqAssignments = useMemo(() => {
    return _.uniqBy(assignments, (item) => item.propertyJsonPath);
  }, [assignments]);

  useEffect(() => {
    setChecked(assignments?.filter((assignment) => assignment.deactivated !== true).map((index) => index.propertyJsonPath!) || []);
  }, [assignments]);

  useEffect(() => {
    if (!schema) return;

    const assignmentsCopy = cloneDeepWithMetadata(uniqAssignments);
    const updatedAssignments = matchPropertyTypes(assignmentsCopy, schema, prefixMap);
    onChange(updatedAssignments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema, prefixMap]);

  const handleFieldIndexDelete = (propertyJsonPath: string): void => {
    if (!assignments) return;
    const assignmentsCopy = cloneDeepWithMetadata(assignments);
    const updatedAssignments = assignmentsCopy.filter((assignment) => assignment.propertyJsonPath !== propertyJsonPath);
    const treeCopy = cloneDeepWithMetadata(tree);
    const unknownParentNodeKey = findUnknownParentNodeKey(treeCopy || [], propertyJsonPath);
    const updatedTree = parseSchema(
      schema as IJSONSchema,
      prefixMap,
      updatedAssignments,
      nodeTitleRender,
      0,
      undefined,
      unknownParentNodeKey || undefined,
      !unknownParentNodeKey.length
    );
    const mergedTree = updateTree(treeCopy || [], updatedTree, updatedAssignments, nodeTitleRender, prefixMap, true, false, !unknownParentNodeKey);
    setTree(mergedTree);
    onChange(updatedAssignments);
  };

  const handleWizardGroupDelete = (propertyJsonPath: string, event?: React.MouseEvent): void => {
    if (!assignments) return;
    event?.stopPropagation();
    event?.preventDefault();
    const assignmentsCopy = cloneDeepWithMetadata(assignments);
    const assignment = assignmentsCopy.find((item) => item.propertyJsonPath === propertyJsonPath);
    if (assignment) {
      assignment.annotations =
        assignment.annotations?.filter((annotation) => annotation.annotationPropertyType?.identifier !== wizardGroupAnnotation) || [];
    }
    onChange(assignmentsCopy);
    const updatedTree = parseSchema(schema as IJSONSchema, prefixMap, assignmentsCopy, nodeTitleRender, 0, undefined, undefined);
    const treeCopy = cloneDeepWithMetadata(tree);
    const mergedTree = updateTree(treeCopy || [], updatedTree, assignmentsCopy, nodeTitleRender, prefixMap, false, false);
    setTree(mergedTree);
  };

  const handleFieldIndexDeleteRef = useRef<typeof handleFieldIndexDelete>(handleFieldIndexDelete);
  handleFieldIndexDeleteRef.current = handleFieldIndexDelete;

  const handleWizardGroupDeleteRef = useRef<typeof handleWizardGroupDelete>(handleWizardGroupDelete);
  handleWizardGroupDeleteRef.current = handleWizardGroupDelete;

  const nodeTitleRender = useCallback(
    (
      assignments: Schemas.CpType['assignments'],
      title: string,
      selectionCount: number,
      key: string,
      isClickable: boolean = true,
      removeOnly: boolean = false
    ): JSX.Element => {
      const matchingAssignment = assignments?.find((assignment) => assignment.propertyJsonPath === key);
      const isDeactivated = selectionCount && (!matchingAssignment || matchingAssignment.deactivated === true);
      const groupAnnotation = matchingAssignment?.annotations?.find(
        (item) => item?.annotationPropertyType?.identifier === wizardGroupAnnotation
      )?.value;
      if (removeOnly) {
        return (
          <span
            className={classNames({
              [styles.nodeTitle]: true,
              [styles.removeOnly]: true,
            })}
          >
            {title}
            <Icon
              iconName={'Edit'}
              className={styles.editIcon}
              onClick={(e) => {
                e.stopPropagation();
                let foundItem = assignments?.find((assignment) => assignment.propertyJsonPath === key);
                if (!foundItem) {
                  foundItem = createFieldIndex(key);
                }
                setEditingItem(foundItem);
                setPropertyPathLocked(false);
              }}
            />
            <Icon
              iconName={'Delete'}
              className={styles.editIcon}
              onClick={(e) => {
                e.stopPropagation();
                handleFieldIndexDeleteRef.current(key);
              }}
            />
          </span>
        );
      }
      return (
        <span
          className={classNames({
            [styles.nodeTitle]: true,
            [styles.unclickable]: !isClickable,
            [styles.deactivated]: isDeactivated,
          })}
        >
          <span>{title}</span>
          {!wizardStepsMode ? (
            <>
              <span className={styles.selectionCount}>{`(${selectionCount})`}</span>
              <Icon
                iconName={'Edit'}
                className={styles.editIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  let foundItem = assignments?.find((assignment) => assignment.propertyJsonPath === key);
                  if (!foundItem) {
                    foundItem = createFieldIndex(key);
                  }
                  setEditingItem(foundItem);
                }}
              />
            </>
          ) : null}
          {wizardStepsMode && groupAnnotation ? (
            <div
              className={classNames(styles.groupBadge, { [styles.groupBadgeLight]: !darkMode })}
              style={{ backgroundColor: colors[+groupAnnotation] }}
            >
              <span>{groupAnnotation}</span>
              <Icon className={styles.removeFromGroup} iconName={'delete'} onClick={(e) => handleWizardGroupDeleteRef.current(key, e)}></Icon>
            </div>
          ) : null}
        </span>
      );
    },
    [darkMode, colors, wizardStepsMode]
  );

  useEffect(() => {
    if (!selectedClass?.identifier) return;
    const metaServiceEndpoint = getEndpoint(axiosDictionary.appMetaService);
    const resolvedIdentifier = resolveSubjectUri(selectedClass.identifier, prefixMap);
    axios
      .get(
        urlJoin(
          metaServiceEndpoint.url,
          `ontology/schemajson?subjectUri=${encodeURIComponent(setViewToSubjectUri(resolvedIdentifier, '__TREE_VIEW'))}&refDepth=1&derefDepth=0`
        ),
        {
          headers: {
            'Accept-Language': i18n.language,
            'Accept': 'application/json',
          },
        }
      )
      .then((response) => {
        setSchema(response.data);
        setTree(parseSchema(response.data as IJSONSchema, prefixMap, uniqAssignments, nodeTitleRender, 0));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefixMap, selectedClass?.identifier]);

  const handleCheck = useCallback(
    (updatedChecked: Key[] | { checked: Key[]; halfChecked: Key[] }, event: { node: Node; checked: boolean }): void => {
      if (!assignments) return;
      const assignmentsCopy = cloneDeepWithMetadata(uniqAssignments);
      const matchedAssignment = assignmentsCopy.find((assignment) => assignment.propertyJsonPath === event.node.key);
      if (matchedAssignment) {
        matchedAssignment.deactivated = !event.checked;
      }
      if (!matchedAssignment) {
        assignmentsCopy.push({
          propertyJsonPath: event.node.key,
          deactivated: !event.checked,
        });
      }
      onChange(assignmentsCopy);
      const newChecked = event.checked ? [...checked, event.node.key] : checked.filter((key) => key !== event.node.key);
      const treeCopy = [...tree!];
      const updatedTree = parseSchema(schema as IJSONSchema, prefixMap, assignmentsCopy, nodeTitleRender, 0, undefined, event.node.key);
      const mergedTree = updateTree(treeCopy, updatedTree, assignmentsCopy, nodeTitleRender, prefixMap, false);
      setTree(mergedTree);
      setChecked(newChecked as string[]);
    },
    [assignments, uniqAssignments, onChange, checked, tree, schema, prefixMap, nodeTitleRender]
  );

  const handleExpand = useCallback((expanded: Key[]): void => {
    if (isDragging.current) return;
    setExpanded(expanded as string[]);
  }, []);

  const handleLoadData = async (node: EventDataNode<Node>): Promise<void> => {
    const data = node.data;
    if (!data || !data.ref) return;
    const refUrl = new URL(data.ref);
    refUrl.searchParams.set('refDepth', '1');
    if (refUrl.searchParams.has('subjectUri')) {
      refUrl.searchParams.set('subjectUri', setViewToSubjectUri(refUrl.searchParams.get('subjectUri')!, '__TREE_VIEW'));
    }
    const response = await axios.get(refUrl.toString(), {
      headers: {
        'Accept-Language': i18n.language,
        'Accept': 'application/json',
      },
    });
    const updatedSchema = extendSchema(schema!, response.data, data.schemaPath!);
    const updatedTree = parseSchema(updatedSchema, prefixMap, uniqAssignments, nodeTitleRender, 0, undefined, node.key);
    const treeCopy = cloneDeepWithMetadata(tree);
    const mergedTree = updateTree(treeCopy || [], updatedTree, uniqAssignments, nodeTitleRender, prefixMap);
    setLoaded([...loaded, node.key as string]);
    setSchema(updatedSchema);
    setTree(mergedTree);
  };

  const checkAllowDrop = useCallback(
    ({ dragNode, dropNode, dropPosition }: { dragNode: Node; dropNode: Node; dropPosition: number }): boolean => {
      if (wizardStepsMode) return false;
      const isDragNodeProperty = isPropertyNode(dragNode);
      const isDropNodeProperty = isPropertyNode(dropNode);
      if (isDragNodeProperty && !isDropNodeProperty && dropPosition === 0) {
        return isParentNode(dragNode, dropNode);
      }
      return isDragNodeProperty && isDropNodeProperty && isSameLevelNodes(dragNode, dropNode) && dropPosition !== 0;
    },
    [wizardStepsMode]
  );

  const handleDragStart = useCallback(
    (info: NodeDragEventParams<Node>): void => {
      isDragging.current = true;
      if (wizardStepsMode) {
        info.event.dataTransfer.setData('node', (info?.node?.key as string) || '');
        setDragging(true);
      }
    },
    [wizardStepsMode]
  );

  const handleDragEnd = useCallback((): void => {
    isDragging.current = false;
    if (wizardStepsMode) {
      setDragging(false);
    }
  }, [wizardStepsMode]);

  const handleDrop = useCallback(
    ({ node, dragNode, dropPosition, dropToGap }: { node: Node; dragNode: Node; dropPosition: number; dropToGap: boolean }): void => {
      if (!tree || !assignments || wizardStepsMode) return;
      const assignmentsCopy = cloneDeepWithMetadata(assignments);
      let dragFieldIndex = assignmentsCopy.find((assignment) => assignment.propertyJsonPath === dragNode.key);
      let dropFieldIndex = assignmentsCopy.find((assignment) => assignment.propertyJsonPath === node.key);
      if (dropPosition === -1) {
        if (!dropFieldIndex) {
          dropFieldIndex = createFieldIndex(node.key);
          assignmentsCopy.push(dropFieldIndex);
        }
        if (!dragFieldIndex) {
          if (dragNode.selectedChildrenCount) {
            dragFieldIndex = createFieldIndex(dragNode.key, false);
          } else {
            dragFieldIndex = createFieldIndex(dragNode.key);
          }
          assignmentsCopy.push(dragFieldIndex);
        }
        const dropSortOrderAnnotation =
          dropFieldIndex?.annotations?.find((annotation) => annotation.annotationPropertyType?.identifier === SORT_ORDER_ANNOTATION_KEY)?.value || 0;
        const dragFieldIndexSortAnnotation = dragFieldIndex?.annotations?.find(
          (annotation) => annotation.annotationPropertyType?.identifier === SORT_ORDER_ANNOTATION_KEY
        );
        if (!dragFieldIndexSortAnnotation) {
          dragFieldIndex!.annotations!.push({
            annotationPropertyType: { identifier: SORT_ORDER_ANNOTATION_KEY },
            value: `${+dropSortOrderAnnotation - 1}`,
          });
        } else {
          dragFieldIndexSortAnnotation.value = `${+dropSortOrderAnnotation - 1}`;
        }
        const updatedTree = parseSchema(schema as IJSONSchema, prefixMap, assignmentsCopy, nodeTitleRender, 0, undefined, node.key);
        const treeCopy = cloneDeepWithMetadata(tree);
        const mergedTree = updateTree(treeCopy || [], updatedTree, assignmentsCopy, nodeTitleRender, prefixMap, false, true);
        setTree(mergedTree);
        onChange(assignmentsCopy);
      } else if (isParentNode(dragNode, node)) {
        if (!selectedClass) return;
        const firstChild = node?.children?.[0];
        if (!firstChild) return;
        const updatedOrder = getUpdatedTreeOrder(
          assignments,
          selectedClass,
          tree,
          dragNode as Node & { pos: string },
          firstChild as Node & { pos: string },
          '0'
        );
        if (!updatedOrder) return;
        const nodePathLevel = getLevelFromPath(firstChild.key);
        const updatedTree = parseSchema(
          schema as IJSONSchema,
          prefixMap,
          updatedOrder,
          nodeTitleRender,
          0,
          undefined,
          nodePathLevel === 0 ? undefined : getPathAtLevel(firstChild.key, Math.max(0, nodePathLevel - 1)),
          nodePathLevel === 0
        );
        const treeCopy = cloneDeepWithMetadata(tree);
        const mergedTree = updateTree(treeCopy || [], updatedTree, updatedOrder, nodeTitleRender, prefixMap, false, true);
        setTree(mergedTree);
        onChange(updatedOrder);
      } else {
        if (!selectedClass) return;
        const updatedOrder = getUpdatedTreeOrder(
          assignments,
          selectedClass,
          tree,
          dragNode as Node & { pos: string },
          node as Node & { pos: string },
          `${dropPosition}`
        );
        if (!updatedOrder) return;
        const nodePathLevel = getLevelFromPath(node.key);
        const updatedTree = parseSchema(
          schema as IJSONSchema,
          prefixMap,
          updatedOrder,
          nodeTitleRender,
          0,
          undefined,
          nodePathLevel === 0 ? undefined : getPathAtLevel(node.key, Math.max(0, nodePathLevel - 1)),
          nodePathLevel === 0
        );
        const treeCopy = cloneDeepWithMetadata(tree);
        const mergedTree = updateTree(treeCopy || [], updatedTree, updatedOrder, nodeTitleRender, prefixMap, false, true);
        setTree(mergedTree);
        onChange(updatedOrder);
      }
    },
    [wizardStepsMode, assignments, nodeTitleRender, onChange, prefixMap, schema, selectedClass, tree]
  );

  const handleSelect = useCallback(
    (selectedKeys: Key[]): void => {
      const expandedCopy = cloneDeepWithMetadata(expanded);
      if (expandedCopy.includes(selectedKeys[0] as string)) {
        setExpanded(expandedCopy.filter((item) => item !== selectedKeys[0]));
      } else {
        expandedCopy.push(selectedKeys[0] as string);
        setExpanded(expandedCopy);
      }
    },
    [expanded]
  );

  const onDialogSubmit = useCallback(
    (updatedItem: Extract<Schemas.CpType['assignments'], {}>[0]) => {
      if (!assignments) return;
      const assignmentsCopy = cloneDeepWithMetadata(assignments);
      const oldFieldIndex = assignmentsCopy.findIndex((assignment) => assignment.propertyJsonPath === updatedItem.propertyJsonPath);
      if (oldFieldIndex !== -1) {
        assignmentsCopy[oldFieldIndex] = updatedItem;
      } else {
        assignmentsCopy.push(updatedItem);
      }
      const isPropertyPathChanged = editingItem?.propertyJsonPath !== updatedItem.propertyJsonPath;
      const updatedAssignments = isPropertyPathChanged
        ? assignmentsCopy.filter((assignment) => assignment.propertyJsonPath !== editingItem?.propertyJsonPath)
        : assignmentsCopy;
      onChange(updatedAssignments);
      const updatedItemPathLevel = getLevelFromPath(updatedItem.propertyJsonPath || '');
      const updatedTree = parseSchema(
        schema as IJSONSchema,
        prefixMap,
        updatedAssignments,
        nodeTitleRender,
        0,
        undefined,
        updatedItemPathLevel === 0 ? undefined : updatedItem.propertyJsonPath,
        updatedItemPathLevel === 0
      );
      const treeCopy = cloneDeepWithMetadata(tree);
      const mergedTree = updateTree(
        treeCopy || [],
        updatedTree,
        updatedAssignments,
        nodeTitleRender,
        prefixMap,
        isPropertyPathChanged,
        true,
        updatedItemPathLevel === 0
      );
      setTree(mergedTree);
      setEditingItem(null);
      setPropertyPathLocked(true);
    },
    [assignments, editingItem?.propertyJsonPath, nodeTitleRender, onChange, prefixMap, schema, tree]
  );

  const onDialogDismiss = useCallback(() => {
    setEditingItem(null);
    setPropertyPathLocked(true);
  }, []);

  const onFilterChange = useCallback((_: React.ChangeEvent<HTMLInputElement>, newValue?: string): void => {
    setFilterValue(newValue);
  }, []);

  const treeWithFilterApplied = useMemo(() => {
    if (!filterValue || !tree) return tree;
    return tree?.filter((node) => filterValue && node.name.toLowerCase().includes(filterValue.toLowerCase()));
  }, [tree, filterValue]);

  const submitAssignments = useCallback(
    (assignments: Schemas.CpType['assignments'], formDataModifier?: (formData: object, options?: object) => void) => {
      onChange(assignments, formDataModifier);
      const updatedTree = parseSchema(schema as IJSONSchema, prefixMap, assignments, nodeTitleRender, 0, undefined, undefined);
      const treeCopy = cloneDeepWithMetadata(tree);
      const mergedTree = updateTree(treeCopy || [], updatedTree, assignments, nodeTitleRender, prefixMap, false, false);
      setTree(mergedTree);
    },
    [nodeTitleRender, onChange, prefixMap, schema, tree]
  );

  useEffect(() => {
    if (isDefined(prevWizardStepsMode) && prevWizardStepsMode !== wizardStepsMode) {
      // Rerender tree root in order to set title view
      const assignmentsCopy = cloneDeepWithMetadata(assignments);
      submitAssignments(assignmentsCopy);
      if (wizardStepsMode) {
        setExpanded([]);
      }
    }
  }, [assignments, prevWizardStepsMode, submitAssignments, wizardStepsMode]);

  const toggleWizardMode = useCallback(() => {
    setWizardStepsMode((mode) => !mode);
  }, []);

  const handleWizardDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>, groupIndex: number) => {
      event.preventDefault();

      const nodeKey = event.dataTransfer.getData('node');
      if (!assignments) return;
      const assignmentsCopy = cloneDeepWithMetadata(assignments);

      const assignmentIndex = assignmentsCopy.findIndex((item) => item.propertyJsonPath === nodeKey);
      if (assignmentIndex === -1) {
        assignmentsCopy.push({
          propertyJsonPath: nodeKey,
          annotations: [
            {
              annotationPropertyType: {
                identifier: wizardGroupAnnotation,
              },
              value: `${groupIndex}`,
            },
          ],
        });
        submitAssignments(assignmentsCopy);
        return;
      }
      const assignment = assignmentsCopy[assignmentIndex];
      if (assignment) {
        if (assignment.deactivated) {
          delete assignment.deactivated;
        }
        const groupAnnotationIndex = assignment.annotations?.findIndex(
          (annotation) => annotation.annotationPropertyType?.identifier === wizardGroupAnnotation
        );
        if (isDefined(groupAnnotationIndex) && groupAnnotationIndex !== -1) {
          const annotation = assignment.annotations?.[groupAnnotationIndex!];
          if (annotation) {
            annotation.value = `${groupIndex}`;
          }
          submitAssignments(assignmentsCopy);
          return;
        } else if (assignment.annotations) {
          assignment.annotations.push({
            annotationPropertyType: {
              identifier: wizardGroupAnnotation,
            },
            value: `${groupIndex}`,
          });
          submitAssignments(assignmentsCopy);
          return;
        } else {
          assignment.annotations = [
            {
              annotationPropertyType: {
                identifier: wizardGroupAnnotation,
              },
              value: `${groupIndex}`,
            },
          ];
          submitAssignments(assignmentsCopy);
          return;
        }
      } else {
        assignmentsCopy.push({
          propertyJsonPath: nodeKey,
          annotations: [
            {
              annotationPropertyType: {
                identifier: wizardGroupAnnotation,
              },
              value: `${groupIndex}`,
            },
          ],
        });
        submitAssignments(assignmentsCopy);
        return;
      }
    },
    [assignments, submitAssignments]
  );

  const wizardGroups = useMemo(() => {
    if (!wizardStepsMode) return [];
    if (!assignments) return [];
    const groups = assignments.reduce((acc, assignment) => {
      const wizardGroup = assignment.annotations?.find((item) => item.annotationPropertyType?.identifier === wizardGroupAnnotation)?.value;
      if (!wizardGroup) return acc;
      const wizardGroupIndex = +wizardGroup;
      if (!assignment.propertyJsonPath) return acc;
      if (acc[wizardGroupIndex]) {
        acc[wizardGroupIndex].push(assignment.propertyJsonPath);
      } else {
        acc[wizardGroupIndex] = [assignment.propertyJsonPath];
      }
      return acc;
    }, [] as string[][]);
    // remove empty values from array
    return Array.from(groups, (item) => item || []);
  }, [assignments, wizardStepsMode]);

  const handleRemoveGroup = useCallback(
    (index: number) => {
      const assignmentsCopy = cloneDeepWithMetadata(assignments);
      if (!assignmentsCopy) return;
      // Remove annotations from the index group
      const removedGroup = wizardGroups[index];
      for (const propertyJsonPath of removedGroup) {
        const assignment = assignmentsCopy.find((item) => item.propertyJsonPath === propertyJsonPath);
        if (assignment) {
          assignment.annotations =
            assignment.annotations?.filter((annotation) => annotation?.annotationPropertyType?.identifier !== wizardGroupAnnotation) || [];
        }
      }
      // Reduce group number in groups gt index
      for (const assignment of assignmentsCopy) {
        const matchingAnnotation = assignment.annotations?.find(
          (annotation) => annotation?.annotationPropertyType?.identifier === wizardGroupAnnotation
        );
        if (matchingAnnotation) {
          const groupValue = matchingAnnotation.value ? +matchingAnnotation.value : 0;
          if (groupValue > index) {
            matchingAnnotation.value = `${groupValue - 1}`;
          }
        }
      }
      submitAssignments(assignmentsCopy, (formData) => handleGroupRemoved(formData, index));
    },
    [assignments, wizardGroups, handleGroupRemoved, submitAssignments]
  );

  const setQuestionForGroup = useCallback(
    async (index: number) => {
      let currentValue = null;
      const currentAnnotations = cloneDeepWithMetadata(
        registry.formRef!.state as {
          formData: { annotations: { annotationLanguage: { identifier: string }; annotationPropertyType: { identifier: string }; value: string }[] };
        }
      ).formData.annotations;
      if (currentAnnotations) {
        const existingAnnotationIndex = currentAnnotations.findIndex((annotation) => {
          return (
            annotation.annotationPropertyType.identifier === 'cp:wizardStepQuestion' &&
            annotation.annotationLanguage.identifier === dataLanguage?.split('-')[0]
          );
        });
        if (existingAnnotationIndex !== -1) {
          currentValue = JSON.parse(currentAnnotations[existingAnnotationIndex].value)?.[index];
        }
      }
      const question: string | undefined = (await showDialog({
        message: t('common.stepDescription'),
        type: GlobalDialogType.INPUT,
        title: t('common.stepDescriptionTitle'),
        dialogContentProps: {
          type: DialogType.largeHeader,
        },
        dialogTypeOptions: {
          optional: true,
          initialValue: currentValue,
        },
        styles: {
          textField: {
            field: {
              height: 90,
              maxHeight: 300,
            },
          } as unknown as ITextFieldStyles,
        },
        primaryButtonText: t('localizationEditor.saveChanges'),
        secondaryButtonText: t('common.cancel'),
        width: '50vw',
      })) as string;
      if (typeof question === 'string') {
        onStepQuestionChange(question, index);
      }
    },
    [dataLanguage, onStepQuestionChange, registry.formRef, t]
  );

  const isGroupWithError = useCallback(
    (group: string[], index: number): string[] | null => {
      if (!group.length) return null;
      const currentAnnotations = cloneDeepWithMetadata(
        registry.formRef!.state as {
          formData: { annotations: { annotationLanguage: { identifier: string }; annotationPropertyType: { identifier: string }; value: string }[] };
        }
      ).formData.annotations;
      if (!currentAnnotations) return null;
      const existingAnnotationIndex = currentAnnotations.findIndex((annotation) => {
        return (
          annotation.annotationPropertyType.identifier === 'cp:wizardStepQuestion' &&
          annotation.annotationLanguage.identifier === dataLanguage?.split('-')[0]
        );
      });
      if (existingAnnotationIndex !== -1) {
        const questionValue = JSON.parse(currentAnnotations[existingAnnotationIndex].value)?.[index];
        if (!questionValue) {
          return null;
        }
      } else {
        return null;
      }
      const assignmentsCopy = cloneDeepWithMetadata(assignments) || [];
      const requiredFields = assignmentsCopy
        .filter((assignment) => {
          const annotations = assignment.annotations || [];
          const requiredAnnotation = annotations.find((annotation) => annotation?.annotationPropertyType?.identifier === 'cp:required');
          if (!requiredAnnotation) return false;
          return !!(requiredAnnotation && requiredAnnotation.value === 'true');
        })
        .map((annotation) => annotation.propertyJsonPath);
      if (!requiredFields) return null;
      return group.filter((field) => requiredFields.includes(field));
    },
    [assignments, dataLanguage, registry.formRef]
  );

  const getGroupPropertyTitle = useCallback(
    (field: string) => {
      if (field.includes('[')) {
        return field;
      }
      return schema?.properties?.[field]?.title;
    },
    [schema?.properties]
  );

  if (!selectedClass?.identifier) {
    return <span>{t('common.classNotSelected')}</span>;
  }

  if (!tree) return <Spinner />;

  return (
    <>
      <div className={styles.filterWrapper}>
        <SearchBox
          className={styles.filter}
          iconProps={SEARCH_BOX_PROPS}
          value={filterValue}
          onChange={onFilterChange}
          placeholder={t('common.filterProperties')}
        />
        <HoverTooltip content={t('common.wizardTreeMode')} key="wizardButton">
          <IconButton className={styles.wizardButton} onClick={toggleWizardMode} iconProps={{ iconName: 'customizetoolbar' }} />
        </HoverTooltip>
      </div>
      {wizardStepsMode ? (
        <Sticky stickyBackgroundColor="transparent" stickyPosition={StickyPositionType.Header}>
          {(isSticky) => (
            <div className={classNames(styles.wizardModeControls, { [styles.sticky]: isSticky, [styles.stickyLight]: isSticky && !darkMode })}>
              {wizardGroups.length ? (
                <div className={styles.groupWrapper}>
                  {wizardGroups.map((group, index) => {
                    const groupErrors = isGroupWithError(group, index);
                    return (
                      <HoverTooltip
                        content={group.map((field, index) => (
                          <p key={index}>{getGroupPropertyTitle(field)}</p>
                        ))}
                        key={index}
                      >
                        <div
                          className={classNames(styles.wizardGroup, { [styles.wizardGroupLight]: !darkMode })}
                          style={{ backgroundColor: colors[index] }}
                          onDragOver={(e) => {
                            e.dataTransfer.dropEffect = 'copy';
                            e.preventDefault();
                          }}
                          onDrop={(event) => handleWizardDrop(event as React.DragEvent<HTMLDivElement>, index)}
                        >
                          {t('common.stepGroup', { step: index, fieldsCount: group.length })}
                          <Icon className={styles.deleteGroup} iconName={'feedbackrequestsolid'} onClick={() => setQuestionForGroup(index)}></Icon>
                          <Icon className={styles.deleteGroup} iconName={'delete'} onClick={() => handleRemoveGroup(index)} />
                          {groupErrors?.length ? (
                            <HoverTooltip content={t('treeView.requiredPropertiesError', { properties: groupErrors.join(', ') })}>
                              <Icon className={styles.errorIcon} iconName={'error'} />
                            </HoverTooltip>
                          ) : null}
                        </div>
                      </HoverTooltip>
                    );
                  })}
                </div>
              ) : null}
              <div className={styles.groupWrapper}>
                <HoverTooltip content={t('common.dropToAdd')}>
                  <div
                    className={classNames(styles.addGroup, { [styles.wide]: dragging, [styles.addGroupLight]: !darkMode })}
                    onDragOver={(e) => {
                      e.dataTransfer.dropEffect = 'copy';
                      e.preventDefault();
                    }}
                    onDrop={(event) => handleWizardDrop(event as React.DragEvent<HTMLDivElement>, wizardGroups.length)}
                  >
                    <Icon iconName={'calculatorAddition'} />
                  </div>
                </HoverTooltip>
                {wizardGroups.length ? (
                  <HoverTooltip content={t('common.dropToRemove')}>
                    <div
                      className={classNames(styles.dropRemove, { [styles.wide]: dragging, [styles.dropRemoveLight]: !darkMode })}
                      onDragOver={(e) => {
                        e.dataTransfer.dropEffect = 'link';
                        e.preventDefault();
                      }}
                      onDrop={(event) => handleWizardGroupDeleteRef.current(event.dataTransfer.getData('node'))}
                    >
                      <Icon iconName={'delete'} />
                    </div>
                  </HoverTooltip>
                ) : null}
              </div>
            </div>
          )}
        </Sticky>
      ) : null}
      <Tree
        rootClassName={classNames({ [styles.wizardTree]: wizardStepsMode })}
        allowDrop={checkAllowDrop as (options: { dragNode: Node; dropNode: Node; dropPosition: number }) => boolean}
        treeData={treeWithFilterApplied as Node[] | undefined}
        checkable
        checkedKeys={checked}
        expandedKeys={expanded}
        selectedKeys={INITIAL_SELECTED_KEYS}
        onCheck={handleCheck}
        onExpand={handleExpand}
        loadData={handleLoadData}
        loadedKeys={loaded}
        draggable={wizardStepsMode ? true : isPropertyNode}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragEnter={NO_ACTION_HANDLER}
        onDrop={handleDrop}
        defaultExpandParent={false}
        onSelect={handleSelect}
      />
      {editingItem && (
        <PathContext.Provider value={''}>
          <FormDialog
            schema={formSchema.items as IJSONSchema}
            formData={editingItem}
            onSubmit={onDialogSubmit}
            onDismiss={onDialogDismiss}
            isPropertyPathLocked={isPropertyPathLocked}
          />
        </PathContext.Provider>
      )}
    </>
  );
};

export default TreeView;
