import urlJoin from 'url-join';
import { DataItemProperties, Schemas } from '@cp/base-types';
import { DataServiceModules } from '@cp/base-utils';

import notification from '../toast';
import { i18n } from '../../app';
import { IDataItem } from '../../types';
import { ensureCpShareLink } from '../../api';
import { getAppBasePath } from '../location';
import { Environment } from '../../app/environment';

import { copyToClipboardAsync } from './copy';
import { generateShortHash } from './crypto';

export async function generateCpShareLink(cpaIdentifier: string, page: Schemas.CpaPage, item: IDataItem): Promise<void> {
  if (!page.path) {
    return;
  }
  const path = page.path.replace('/:id', '');

  const textPromise = async () => {
    const itemIdentifier: string = (item.urlSlug as string) || (item.identifier as string);
    const shareIdentifier = `${itemIdentifier}_${await generateShortHash(
      [Environment.env.REACT_APP_ENVIRONMENT, cpaIdentifier, page.identifier].join(),
      10
    )}`;

    const shareLink = await ensureCpShareLink(shareIdentifier, {
      identifier: shareIdentifier,
      name: (item.name || itemIdentifier) as string,
      description: (item.shortDescription as string) || (item.description as string),
      url: urlJoin(`${window.location.origin}${getAppBasePath()}`, path, encodeURIComponent(itemIdentifier)),
      image: item.image as string,
    });
    const text = `${getRootInstanceDomain()}/${DataServiceModules.SHARE}/${encodeURIComponent(shareLink.identifier!)}${
      shareLink[DataItemProperties.CURRENT_VERSION] ? `?v=${shareLink[DataItemProperties.CURRENT_VERSION]}` : ''
    }`;
    return new Blob([text], { type: 'text/plain' });
  };

  copyToClipboardAsync(textPromise, () => {
    notification.success(i18n.t('common.copiedToClipboard'));
  });
}

export async function getCpShareLink(cpaIdentifier: string, page: Schemas.CpaPage, item: IDataItem): Promise<string | null> {
  if (!page.path) {
    return null;
  }
  const path = page.path.replace('/:id', '');
  const itemIdentifier: string = (item.urlSlug as string) || (item.identifier as string);
  const shareIdentifier = `${itemIdentifier}_${await generateShortHash(
    [Environment.env.REACT_APP_ENVIRONMENT, cpaIdentifier, page.identifier].join(),
    10
  )}`;

  const shareLink = await ensureCpShareLink(shareIdentifier, {
    identifier: shareIdentifier,
    name: (item.name || itemIdentifier) as string,
    description: (item.shortDescription as string) || (item.description as string),
    url: urlJoin(`${window.location.origin}${getAppBasePath()}`, path, encodeURIComponent(itemIdentifier)),
    image: item.image as string,
  });

  return `${getRootInstanceDomain()}/${DataServiceModules.SHARE}/${encodeURIComponent(shareLink.identifier!)}${
    shareLink[DataItemProperties.CURRENT_VERSION] ? `?v=${shareLink[DataItemProperties.CURRENT_VERSION]}` : ''
  }`;
}

function getRootInstanceDomain(): string {
  const dataServiceUrl = new URL(Environment.env.REACT_APP_ROOT_DATA_SERVICE_URL ?? window.location.href);
  const subdomains = dataServiceUrl.host.split('.');
  const higherLevelHost = (subdomains.length === 1 ? subdomains : subdomains.slice(1)).join('.');

  return `${dataServiceUrl.protocol}//${higherLevelHost}`;
}
