import {useCallback, useContext, useEffect, useMemo, useRef, useState, MutableRefObject} from 'react';
import * as _ from 'lodash';
import {IJSONSchema} from '@cp/base-types';
import {AjvError} from '@rjsf/core';
import {cloneDeepWithMetadata} from '@cp/base-utils';

import { IDataItem } from '../types';
import { FormContext } from '../constants';
import {
  addArrayItemIfRequired, addRepeatSteps, createNewArrayItemAtPath,
  getNextRepeatStepIndex,
  getPrevStepIndex,
  getRepeatStepIndexToTheRight,
  getStepsFromSchema, getWizardArrayMeta, GuidedStep, hideFields, isNewArrayItemsRequired, RepeatStep,
  Step,
  StepTypes,
  validateStep
} from '../helpers/wizard';
import {isDefined} from '../helpers';

export function useFormField<T>(propertyJsonPath: string): T | undefined {
  const formContext = useContext(FormContext);
  const [value, setValue] = useState<T | undefined>(() => {
    return _.get(formContext.currentFormData.current, propertyJsonPath) as T | undefined;
  });

  const handleFormChange = useCallback(
    (newFormData: IDataItem) => {
      setValue(_.get(newFormData, propertyJsonPath) as T | undefined);
    },
    [propertyJsonPath]
  );

  useEffect(() => {
    formContext.formEventEmitter?.on('change', handleFormChange);
    return () => {
      formContext.formEventEmitter?.off('change', handleFormChange);
    };
  }, [formContext.formEventEmitter, handleFormChange]);

  return value;
}

export function useFormTimestamp(isFormOpened: boolean): [timestamp: number | undefined, resetTimestamp: () => void] {
  const [timestamp, setTimestamp] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (isFormOpened) setTimestamp(Date.now());
    else setTimestamp(undefined);
  }, [isFormOpened]);

  const resetTimestamp = useCallback(() => {
    setTimestamp(Date.now());
  }, []);
  return [timestamp, resetTimestamp];
}

export function useWizard(schema: IJSONSchema, formSchema: IJSONSchema | null, currentFormData: MutableRefObject<IDataItem<unknown> | undefined>, isWizardMode?: boolean): [steppedSchema: IJSONSchema | null, steps: Step[], step: number, wizardArrayCursor: MutableRefObject<(number | undefined)[]>, stepsCount: number, visibleStepsCount: number, visibleStep: number, progress: number, question: { question: string; returnTo: number } | null, validationErrors: string[] | null, nextButtonDisabled: boolean, handleAddMore: () => void, handleNext: () => void, handleStepNext: () => void, handleStepBack: () => void] {
  const [visibleStep, setVisibleStep] = useState(0);
  const [step, setStep] = useState(0);
  const [stepErrors, setStepErrors] = useState<AjvError[] | null>(null);

  const [steps, setSteps] = useState<Step[]>([]);
  const [question, setQuestion] = useState<{ question: string; returnTo: number } | null>(null);
  const lastVisitedStep = useRef<number>(0);

  const wizardArrayCursor = useRef<(number | undefined)[]>([]);
  const resetCurrentArrayValuesAtIndex = useRef<number | undefined>(undefined);

  useEffect(() => {
    const stepsFromSchema = getStepsFromSchema(schema);
    setSteps(stepsFromSchema);
  }, [schema]);

  const visibleStepsCount = useMemo(() => {
    if (steps.length && schema.properties) {
      return steps.filter((step) => step.type !== StepTypes.Repeat).length;
    }
    return 1;
  }, [steps, schema.properties]);

  const stepsCount = useMemo(() => {
    if (steps.length && schema.properties) {
      return steps.length;
    }
    return 1;
  }, [steps, schema.properties]);

  const progress = useMemo(() => {
    return ((visibleStep + 1) / visibleStepsCount) * 100;
  }, [visibleStep, visibleStepsCount]);

  const validationErrors = useMemo(() => {
    if (!stepErrors?.length) return null;
    return stepErrors.map((error) => error.stack);
  }, [stepErrors]);

  const handleStepBack = useCallback(() => {
    const errors = validateStep(schema, steps, step, currentFormData.current);
    setStepErrors(null);
    if (errors?.errors.length) {
      setStepErrors(errors.errors);
      return;
    }
    if (isDefined(resetCurrentArrayValuesAtIndex.current) && resetCurrentArrayValuesAtIndex.current === (step - 1)) {
      wizardArrayCursor.current = [];
      resetCurrentArrayValuesAtIndex.current = undefined;
    }
    const prevStepIndex = getPrevStepIndex(steps, step);
    const prevRepeatStepIndex = getRepeatStepIndexToTheRight(steps, prevStepIndex);
    const prevRepeatStep = steps[prevRepeatStepIndex ? prevRepeatStepIndex : prevStepIndex] as RepeatStep;
    wizardArrayCursor.current = getWizardArrayMeta(steps, prevRepeatStep.path , prevStepIndex);
    setVisibleStep((step) => step - 1);
    setStep(prevStepIndex);
  }, [currentFormData, schema, step, steps]);

  const handleStepNext = useCallback(() => {
    const errors = validateStep(schema, steps, step, currentFormData.current);
    setStepErrors(null);
    if (errors?.errors.length) {
      setStepErrors(errors.errors);
      return;
    }
    let itemCreated = false;
    const nextStepIndex = step + 1;
    const nextStep = steps[nextStepIndex];
    // Check if new item is required for next step
    if (nextStepIndex <= lastVisitedStep.current && steps[nextStepIndex].type === StepTypes.Guided) {
    } else if (!itemCreated) {
      const nextRepeatStepIndex = getNextRepeatStepIndex(steps, nextStepIndex + 1);
      const nextRepeatStep: RepeatStep | undefined = nextRepeatStepIndex ? steps[nextRepeatStepIndex] as RepeatStep : undefined;
      if (nextRepeatStep) {
        wizardArrayCursor.current = getWizardArrayMeta(steps, nextRepeatStep.path, nextStepIndex!);
      }
      itemCreated = addArrayItemIfRequired(steps,nextStepIndex, schema, nextRepeatStep ? wizardArrayCursor.current : [], false, false, currentFormData.current);
    }
    if (
      resetCurrentArrayValuesAtIndex.current === nextStepIndex ||
      (nextStep.type === StepTypes.Repeat && (nextStep as RepeatStep).from === resetCurrentArrayValuesAtIndex.current! + 1)
    ) {
      const nextRepeatStepIndex = getNextRepeatStepIndex(steps, nextStepIndex + 1);
      const nextRepeatStep: RepeatStep | undefined = nextRepeatStepIndex ? steps[nextRepeatStepIndex] as RepeatStep : undefined;
      if (!nextRepeatStep || nextRepeatStep.path !== (nextStep as RepeatStep).path || (nextRepeatStep.path === (nextStep as RepeatStep).path && (nextStep as RepeatStep).deactivated && nextRepeatStep.deactivated)) {
        wizardArrayCursor.current = [];
        resetCurrentArrayValuesAtIndex.current = undefined;
        if (nextStep.type === StepTypes.Repeat && nextStep.deactivated) {
          const stepAfterDeactivated = steps[nextStepIndex + 1];
          if (stepAfterDeactivated.type === StepTypes.Guided && nextRepeatStep?.from === nextStepIndex + 1) {
            wizardArrayCursor.current = getWizardArrayMeta(steps, nextRepeatStep.path, nextStepIndex!);
            resetCurrentArrayValuesAtIndex.current = nextRepeatStepIndex!;
          }
          if (nextStepIndex + 1 <= lastVisitedStep.current && steps[nextStepIndex + 1].type === StepTypes.Guided) {
          } else if (!itemCreated) {
            itemCreated = addArrayItemIfRequired(steps, nextStepIndex + 1, schema, wizardArrayCursor.current, false, true, currentFormData.current);
          }
          setVisibleStep((step) => step + 1);
          setStep(nextStepIndex + 1);
        } else {
          setStep(nextStepIndex);
        }
      } else {
        wizardArrayCursor.current = getWizardArrayMeta(steps, nextRepeatStep.path, nextStepIndex!);
        resetCurrentArrayValuesAtIndex.current = nextRepeatStepIndex!;
        setVisibleStep((step) => step + 1);
        setStep((step) => step + 2);
      }
      return;
    }
    const nextRepeatStepIndex = getNextRepeatStepIndex(steps, nextStepIndex);
    if (nextRepeatStepIndex) {
      const nextRepeatStep = steps[nextRepeatStepIndex] as RepeatStep;
      wizardArrayCursor.current = getWizardArrayMeta(steps, nextRepeatStep.path, nextStepIndex);
      resetCurrentArrayValuesAtIndex.current = nextRepeatStepIndex;
      if (nextStepIndex <= lastVisitedStep.current && steps[nextStepIndex].type === StepTypes.Guided) {
      } else if (!itemCreated) {
        itemCreated = addArrayItemIfRequired(steps, nextStepIndex, schema, wizardArrayCursor.current, true, false, currentFormData.current);
      }
    }
    if (nextStep.type !== StepTypes.Repeat) {
      setVisibleStep((step) => step + 1);
    } else {
      if (nextStep.deactivated) {
        setVisibleStep((step) => step + 1);
        setStep((step) => step + 2);
      } else {
        setStep(nextStepIndex);
      }
      return;
    }
    setStep(nextStepIndex);
  }, [currentFormData, schema, step, steps]);

  const steppedSchema = useMemo(() => {
    const schemaCopy = cloneDeepWithMetadata(formSchema);
    if (!schemaCopy) return null;
    if (!isWizardMode || !steps?.length) return schemaCopy;
    const currentStep = steps[step];
    lastVisitedStep.current = Math.max(step, lastVisitedStep.current);
    if (currentStep.type === StepTypes.Repeat) {
      setQuestion({ question: currentStep.question!, returnTo: currentStep.from });
      return schemaCopy;
    } else {
      const exceptions = (currentStep as GuidedStep).fields;
      const newArrayItemsRequired = isNewArrayItemsRequired(steps, step);
      const createNewArrayItems: boolean = newArrayItemsRequired && step === lastVisitedStep.current && steps[step].type === StepTypes.Guided;
      return hideFields(schemaCopy, exceptions, false, createNewArrayItems);
    }
  }, [formSchema, step, steps, isWizardMode]);

  const handleAddMore = useCallback(() => {
    if (question?.returnTo) {
      const prevStepWizardArrayMeta = getWizardArrayMeta(steps, (steps[step] as RepeatStep).path, step);
      createNewArrayItemAtPath((steps[step] as RepeatStep).path, schema, currentFormData.current, prevStepWizardArrayMeta, false);
      const updatedSteps = addRepeatSteps(steps, step, lastVisitedStep, question?.returnTo);
      const nextRepeatStepIndex = getNextRepeatStepIndex(updatedSteps, step + 1);
      const nextRepeatStep: RepeatStep | undefined = nextRepeatStepIndex ? updatedSteps[nextRepeatStepIndex] as RepeatStep : undefined;
      wizardArrayCursor.current = getWizardArrayMeta(updatedSteps, nextRepeatStep!.path, step + 1);
      resetCurrentArrayValuesAtIndex.current = nextRepeatStepIndex!;
      setSteps(updatedSteps);
      setQuestion(null);
      setVisibleStep((step) => step + 1);
      setStep((step) => step + 1);
    }
  }, [currentFormData, question?.returnTo, schema, step, steps]);

  const handleNext = useCallback(() => {
    const nextStepIndex = steps[step + 1].type === StepTypes.Repeat ? step + 2 : step + 1;
    if (nextStepIndex <= lastVisitedStep.current && steps[nextStepIndex].type === StepTypes.Guided) {
    } else {
      addArrayItemIfRequired(steps, nextStepIndex, schema, [], false, false, currentFormData.current);
    }
    const nextRepeatStepIndex = getNextRepeatStepIndex(steps, nextStepIndex);
    const nextRepeatStep: RepeatStep | undefined = nextRepeatStepIndex ? steps[nextRepeatStepIndex] as RepeatStep : undefined;
    if (!nextRepeatStep) {
      wizardArrayCursor.current = [];
      resetCurrentArrayValuesAtIndex.current = undefined;
    } else {
      wizardArrayCursor.current = getWizardArrayMeta(steps, nextRepeatStep.path, nextStepIndex);
      resetCurrentArrayValuesAtIndex.current = nextRepeatStepIndex!;
    }
    wizardArrayCursor.current = getWizardArrayMeta(steps, '', nextStepIndex);
    setQuestion(null);
    const nextStep = steps[step + 1];
    if (nextStep.type === StepTypes.Repeat) {
      if (nextStep.deactivated) {
        setVisibleStep((step) => step + 1);
        setStep((step) => step + 2);
      } else {
        setStep((step) => step + 1);
      }
    } else {
      setVisibleStep((step) => step + 1);
      setStep((step) => step + 1);
    }
  }, [currentFormData, schema, step, steps]);

  const nextButtonDisabled = useMemo(() => {
    const nextStep = steps[step + 1];
    if (nextStep && nextStep.type === StepTypes.Repeat && nextStep.deactivated && step + 2 === stepsCount) {
      return true;
    }
    return step + 1 === stepsCount;
  }, [step, steps, stepsCount]);

  return [
    steppedSchema,
    steps,
    step,
    wizardArrayCursor,
    stepsCount,
    visibleStepsCount,
    visibleStep,
    progress,
    question,
    validationErrors,
    nextButtonDisabled,
    handleAddMore,
    handleNext,
    handleStepNext,
    handleStepBack
  ];
}
